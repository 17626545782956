import { DetailUnit, UnitsMapTypeRes } from '@/modules/maps/types/unitsMapType'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './index'

interface State {
  data: UnitsMapTypeRes
}

const initialState: State = {
  data: {
    data: [] as any,
    success: false,
  },
}

export const unitsSlice = createSlice({
  name: 'units',
  initialState,
  reducers: {
    setUnits: (state, action: PayloadAction<UnitsMapTypeRes>) => {
      state.data = action.payload
    },
    updateUnits: (state, action: PayloadAction<DetailUnit>) => {
      const index = state.data.data.data.findIndex(
        (unit) => unit.id === action.payload.id
      )
      state.data.data.data[index].name = action.payload.name
      state.data.data.data[index].address = action.payload.address
      state.data.data.data[index].connectionStatus =
        action.payload.connectionStatus
      state.data.data.data[index].countModules = action.payload.countModules
      state.data.data.data[index].signal = action.payload.signal
      state.data.data.data[index].type = action.payload.type
      state.data.data.data[index].voltage = action.payload.voltage
      state.data.data.data[index].volume = action.payload.volume ?? 0
      state.data.data.data[index].temperature = action.payload.temperature ?? 0
      state.data.data.data[index].lastPackageDate =
        action.payload.lastPackageDate ?? ''
      state.data.data.data[index].errorMessage =
        action.payload.errorMessage ?? ''
      state.data.data.data[index].state = action.payload.state ?? 1
      state.data.data.data[index].isGroup = true
      state.data.data.data[index].is220v = action.payload.is220v
    },
  },
})

export const { setUnits, updateUnits } = unitsSlice.actions

export const unitsSelector = (state: RootState) => state.units.data

export default unitsSlice.reducer
