import { TypeUnit } from '@/modules/devices-list/types/newUnitsType'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './index'

type ModulesFilterValues = { id: number; name: string }

interface State {
  searchValue: string
  isSearch: boolean
  filter: number[]
  deviceStateFilter: number[]
  modulesStateFilter: number[]
  modulesFilterValues: ModulesFilterValues[]
  favoriteId: number
  typeUnits: TypeUnit[]
  isFullScreen: boolean
  isFullScreenDetail: boolean
  isFScreen: boolean
  isFScreenPhoto: boolean
  sideBarWidth: number
}

const initialState: State = {
  searchValue: localStorage.getItem('searchValueDevices') ?? '',
  isSearch: false,
  filter: (localStorage.getItem('filterValueDevices') &&
    JSON.parse(localStorage.getItem('filterValueDevices') ?? '')) ?? [0],
  deviceStateFilter: [0],
  modulesStateFilter: [],
  modulesFilterValues: [],
  favoriteId: 0,
  typeUnits: [],
  isFullScreen: false,
  isFullScreenDetail: false,
  isFScreen: false,
  isFScreenPhoto: false,
  sideBarWidth: 214,
}

export const filterSystemSlice = createSlice({
  name: 'filterSystem',
  initialState,
  reducers: {
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload
    },
    setIsSearch: (state, action: PayloadAction<boolean>) => {
      state.isSearch = action.payload
    },
    setFilter: (state, action: PayloadAction<number[]>) => {
      state.filter = action.payload
    },
    setDeviceStateFilter: (state, action: PayloadAction<number[]>) => {
      state.deviceStateFilter = action.payload
    },
    setModulesStateFilter: (state, action: PayloadAction<number[]>) => {
      state.modulesStateFilter = action.payload
    },
    setModulesFilterValues: (
      state,
      action: PayloadAction<ModulesFilterValues[]>
    ) => {
      state.modulesFilterValues = action.payload
    },
    setFavoriteId: (state, action: PayloadAction<number>) => {
      state.favoriteId = action.payload
    },
    setTypeUnits: (state, action: PayloadAction<TypeUnit[]>) => {
      //state.typeUnits.unshift({ id: 0, name: 'Все' })
      state.typeUnits = action.payload
    },
    toggleFullScreen: (state, action: PayloadAction<boolean>) => {
      state.isFullScreen = action.payload
    },
    toggleFullScreenDetail: (state, action: PayloadAction<boolean>) => {
      state.isFullScreenDetail = action.payload
    },
    toggleFScreen: (state, action: PayloadAction<boolean | undefined>) => {
      if (action.payload !== undefined) {
        state.isFScreen = action.payload
      } else {
        state.isFScreen = !state.isFScreen
      }
    },
    toggleFScreenPhoto: (state, action: PayloadAction<boolean | undefined>) => {
      if (action.payload !== undefined) {
        state.isFScreenPhoto = action.payload
      } else {
        state.isFScreenPhoto = !state.isFScreenPhoto
      }
    },
    setSidebarWidth: (state, action: PayloadAction<number>) => {
      state.sideBarWidth = action.payload
    },
  },
})

export const {
  setSearchValue,
  setIsSearch,
  setFilter,
  setDeviceStateFilter,
  setModulesStateFilter,
  setModulesFilterValues,
  setFavoriteId,
  setTypeUnits,
  toggleFullScreen,
  toggleFullScreenDetail,
  toggleFScreen,
  toggleFScreenPhoto,
  setSidebarWidth,
} = filterSystemSlice.actions

export const filterSystemSelector = (state: RootState) => state.filterSystem
export const fScreenSelector = (state: RootState) =>
  state.filterSystem.isFScreen
export const fScreenSelectorPhoto = (state: RootState) =>
  state.filterSystem.isFScreenPhoto

export const deviceStateFilter = (state: RootState) =>
  state.filterSystem.deviceStateFilter
export const sidebarWithSelector = (state: RootState) =>
  state.filterSystem.sideBarWidth
export const modulesStateFilter = (state: RootState) =>
  state.filterSystem.modulesStateFilter
export const modulesFilterValues = (state: RootState) =>
  state.filterSystem.modulesFilterValues
export default filterSystemSlice.reducer
