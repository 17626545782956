import { useActions, useAppSelector } from '@/hooks'
import { useGetFavoritesSmallAquaQuery } from '@/modules/favorites-tank/services/favoritesService'
import { FavoritesSmall } from '@/modules/favorites/types/favorites'
import { isChangeModulesSelector } from '@/store/changeDialogSlise'
import { filterTanksSelector } from '@/store/filterTanksSlise'
import cn from '@/utils/cn'
import StarIcon from '@mui/icons-material/Star'
import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

const s = cn('favorites-list')
const FavoritesList = () => {
  const {
    isChangeModules,
    isChangeDescription,
    isChangeFeatures,
    isChangeUser,
    isChangeOrganization,
  } = useAppSelector(isChangeModulesSelector)
  const {
    setIsOpenChangeModulesDialog,
    setIsOpenChangeOrganizationDialog,
    setIsOpenChangeUserDialog,
    setIsOpenChangeDescriptionDialog,
    setIsOpenChangeFeaturesDialog,
  } = useActions()

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { data, isLoading } = useGetFavoritesSmallAquaQuery()
  const { setFavoriteIdTanks, setIsSearchTanks, setFilterTanks } = useActions()
  const { favoriteId, isSearch } = useAppSelector(filterTanksSelector)

  const titleNavigate = () => {
    if (isChangeModules) {
      setIsOpenChangeModulesDialog(true)
      return
    }
    if (isChangeDescription) {
      setIsOpenChangeDescriptionDialog(true)
      return
    }
    if (isChangeFeatures) {
      setIsOpenChangeFeaturesDialog(true)
      return
    }
    if (isChangeUser) {
      setIsOpenChangeUserDialog(true)
      return
    }
    if (isChangeOrganization) {
      setIsOpenChangeOrganizationDialog(true)
      return
    }
    navigate('/favorites')
  }

  const listNavigate = (item: FavoritesSmall) => {
    if (isChangeModules) {
      setIsOpenChangeModulesDialog(true)
      return
    }
    if (isChangeDescription) {
      setIsOpenChangeDescriptionDialog(true)
      return
    }
    if (isChangeFeatures) {
      setIsOpenChangeFeaturesDialog(true)
      return
    }
    if (isChangeUser) {
      setIsOpenChangeUserDialog(true)
      return
    }
    if (isChangeOrganization) {
      setIsOpenChangeOrganizationDialog(true)
      return
    }
    setIsSearchTanks(!isSearch)
    setFilterTanks([0])
    if (favoriteId === item.id) {
      setFavoriteIdTanks(0)
    } else {
      navigate(pathname === '/' ? '/' : '/devices')
      setFavoriteIdTanks(item.id)
    }
  }

  return (
    <>
      <Box width='100%'>
        <ListItem disablePadding>
          <ListItemButton
            className={s('btn', { active: pathname === '/favorites' })}
            onClick={titleNavigate}
          >
            <ListItemIcon>
              <StarIcon htmlColor='#e4dc42' />
            </ListItemIcon>
            <ListItemText primary='Избранное' />
          </ListItemButton>
        </ListItem>
      </Box>
      <Box
        flex={1}
        width='100%'
        maxHeight='100%'
        overflow='auto'
        className={s({ loading: isLoading })}
      >
        <CircularProgress
          className={isLoading ? s('show-loader') : s('hide-loader')}
        />
        <List sx={{ width: '100%', overflowY: 'auto' }}>
          {data?.data?.map((item, i) => (
            <ListItem
              key={i}
              disablePadding
              sx={{
                backgroundColor:
                  favoriteId === item.id ? '#37424d' : 'transparent',
                border: favoriteId === item.id ? '1px solid #0072BC' : 'unset',
              }}
            >
              <ListItemButton onClick={() => listNavigate(item)}>
                <ListItemText
                  inset
                  className={s({ active: favoriteId === item.id })}
                  primary={item.name}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <Divider />
    </>
  )
}

export default FavoritesList
