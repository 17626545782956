import { ChartOptions } from 'chart.js'

const SPEED = 0.1

type PropsChartOptions = {
  radioValue: string
  minV: number
  maxV: number
  maxTemp: number
  minTemp: number
  maxWater: number
  minWater: number
}
export const getChartOptions = ({
  radioValue,
  minV,
  maxV,
  maxTemp,
  minTemp,
  maxWater,
  minWater,
}: PropsChartOptions): ChartOptions<'line'> => {
  return {
    maintainAspectRatio: false,
    //animation: false,
    parsing: false,
    plugins: {
      legend: {
        display: true,
      },
      decimation: {
        enabled: radioValue !== '12',
        algorithm: 'lttb',
        samples: 50,
        threshold: 50,
      },
      zoom: {
        limits: {
          x: {
            //min: new Date(dayjs(fromDate).add(1, 'day').toISOString()),
            //max: new Date(dayjs(toDate).toISOString()),
          },
        },
        pan: {
          enabled: true,
          mode: 'x',
        },
        zoom: {
          wheel: {
            enabled: true,
            speed: SPEED,
          },
          drag: {
            //enabled: true
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            if (context.dataset.yAxisID === 'y2') {
              return `${context.dataset.label}: ${Number(Number(context.parsed.y) * 100)} %`
            }
            if (context.dataset.yAxisID === 'y3') {
              return `${context.dataset.label}: ${Number(context.parsed.y).toFixed(Number.isInteger(context.parsed.y) ? 0 : 1)}`
            }
            if (
              context.dataset.yAxisID === 'y4' ||
              context.dataset.yAxisID === 'y8'
            ) {
              return `${context.dataset.label}: ${Number(context.parsed.y).toFixed(Number.isInteger(context.parsed.y) ? 0 : 1)} °C`
            }
            if (context.dataset.yAxisID === 'y6') {
              return `${context.dataset.label}: ${Number(context.parsed.y).toFixed(Number.isInteger(context.parsed.y) ? 0 : 1)} мм/час`
            }
            if (context.dataset.yAxisID === 'y7') {
              return `${context.dataset.label}: ${Number(context.parsed.y).toFixed(Number.isInteger(context.parsed.y) ? 0 : 1)} м`
            }
            if (context.dataset.yAxisID === 'y9') {
              return `${context.dataset.label}: ${Number(context.parsed.y).toFixed(Number.isInteger(context.parsed.y) ? 0 : 1)}`
            }
            if (context.dataset.yAxisID === 'y10') {
              return `${context.dataset.label}: ${Number(context.parsed.y).toFixed(Number.isInteger(context.parsed.y) ? 0 : 1)} мм`
            }
            return `${context.dataset.label}: ${Number(context.parsed.y).toFixed(Number.isInteger(context.parsed.y) ? 0 : 1)} V`
          },
        },
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'minute',
          displayFormats: {
            minute: 'dd.MM HH:mm',
            hour: 'HH:mm',
            day: 'dd',
            month: 'mm',
          },
          tooltipFormat: 'dd.MM.yyyy HH:mm',
        },
        bounds: 'ticks',
        ticks: {
          stepSize: 15,
          maxTicksLimit: 50,
          source: 'auto',
          autoSkip: true,
        },
        offsetAfterAutoskip: true,
        //min: new Date(dayjs(fromDate).toISOString()),
        //max: new Date(dayjs(toDate).toISOString()),
      },
      y: {
        beginAtZero: true,
        display: radioValue === '1',
        min: +Number(minV).toFixed(1),
        max: +Number(maxV).toFixed(1),
        ticks: {
          stepSize: 1,
          callback: (value) => {
            return Number(value).toFixed(Number.isInteger(value) ? 0 : 1) + ' В'
          },
        },
      },
      y2: {
        position: 'left',
        display: radioValue === '2' || radioValue === '4',
        //max: 1.09,
        min: 0,
        ticks: {
          callback: (value) => {
            return Number(value) > 1 ? '' : Number(value) * 100 + '%'
          },
        },
      },
      y3: {
        position: 'left',
        display: radioValue === '12',
        max: 1,
        min: 0,
        ticks: {
          callback: (value) => {
            if (Number(value) === 0) {
              return 'Выкл'
            }
            if (Number(value) === 1) {
              return 'Вкл'
            }
            return ''
          },
        },
      },
      y4: {
        position: 'left',
        display: radioValue === '3',
        max: maxTemp ?? 80,
        min: minTemp ?? -40,
        ticks: {
          callback: (value) => {
            return Number(value) > 0
              ? '+' + Number(value) + '°C'
              : Number(value) + '°C'
          },
        },
      },
      y6: {
        position: 'left',
        display: radioValue === '10',
        max: maxWater ?? 20,
        min: minWater ?? 0,
        ticks: {
          callback: (value) => {
            return Number(value) + ' мм/час'
          },
        },
      },
      y7: {
        position: 'left',
        display: radioValue === '11',
        //max: 1000,
        min: 0,
        ticks: {
          callback: (value) => {
            return Number(value) + ' м'
          },
        },
      },
      y8: {
        position: 'left',
        display: radioValue === '5',
        max: maxTemp ?? 80,
        min: minTemp ?? -40,
        ticks: {
          callback: (value) => {
            return Number(value) > 0
              ? '+' + Number(value) + '°C'
              : Number(value) + '°C'
          },
        },
      },
      y9: {
        position: 'left',
        display: radioValue === '6',
        max: 1,
        min: 0,
        ticks: {
          callback: (value) => {
            return Number(value).toFixed(1)
          },
        },
      },
      y10: {
        position: 'left',
        display: radioValue === '7' || radioValue === '8' || radioValue === '9',
        max: maxWater ?? 20,
        min: minWater ?? 0,
        ticks: {
          callback: (value) => {
            return Number(value) + ' мм'
          },
        },
      },
    },
  }
}
