import { Box, CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import Header from '@/modules/user/components/Header/Header'
import UserTabs from '@/modules/user/components/UserTabs/UserTabs'
import { useParams } from 'react-router-dom'
import { useGetUserProfileQuery } from '@/modules/user/services/userService'
import cn from '@/utils/cn'

const s = cn('user')

const User = () => {
  const { id } = useParams()
  const isNewUser = id === 'new'

  const [loader, setLoader] = useState(false)

  const { isLoading: isLoadingUserData } = useGetUserProfileQuery(id ?? '0', {
    skip: isNewUser,
  })

  return (
    <>
      <CircularProgress
        className={
          isLoadingUserData || loader ? s('show-loader') : s('hide-loader')
        }
      />
      <Box className={s({ loading: isLoadingUserData || loader })}>
        <Header />
        <Box mt={{ xl: 3 }} />
        <UserTabs setLoader={setLoader} />
      </Box>
    </>
  )
}

export default User
