import { Navigate, Outlet } from 'react-router-dom'
import React from 'react'
import { UserTypes } from '@/types/typeUser'
import { useGetUserProfileQuery } from '@/modules/user/services/userService'

const PrivateRoutePermissionsUser = () => {
  const { data: currentUserData } = useGetUserProfileQuery('0')

  return currentUserData?.data?.typeId === UserTypes.user ? (
    <Navigate to='/' />
  ) : (
    <Outlet />
  )
}

export default PrivateRoutePermissionsUser
