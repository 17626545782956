import { FC } from 'react'
import { FormControlLabel, Radio } from '@mui/material'

type Props = {
  deviceId?: number
  selectedMeteo: number
}
type Item = {
  label: string
  value: string
  color: string
}

const GROUP1 = [
  {
    label: 'Напряжение',
    value: '1',
    color: '#0f8fff',
  },
  {
    label: 'Уровень сигнала',
    value: '2',
    color: '#e450ed',
  },
]

const GROUP2 = [
  {
    label: 'Выходы',
    value: '12',
    color: 'red',
  },
]
const GROUP3 = [
  {
    label: 'Температура',
    value: '3',
    color: 'red',
  },
  {
    label: 'Влажность',
    value: '4',
    color: '#000080',
  },
]

const GROUP4 = [
  {
    label: 'Количество',
    value: '10',
    color: '#00ff00',
  },
]
const GROUP5 = [
  {
    label: 'Видимость',
    value: '11',
    color: '#ff8000',
  },
]
const GROUP6 = [
  {
    label: 'Температура',
    value: '5',
    color: 'red',
  },
  {
    label: 'Коэф. сцепления',
    value: '6',
    color: '#000080',
  },
  {
    label: 'Вода',
    value: '7',
    color: '#000080',
  },
  {
    label: 'Снег',
    value: '9',
    color: '#000080',
  },
  {
    label: 'Лед',
    value: '8',
    color: '#000080',
  },
]

const ChartRadioGroupItem: FC<Props> = ({ deviceId, selectedMeteo }) => {
  let DATA: Item[] = []
  if (deviceId !== 4 || (deviceId === 4 && selectedMeteo === 0)) {
    DATA = GROUP1
  }
  if (deviceId === 1 || deviceId === 4) {
    /* empty */
  } else {
    DATA = [...DATA, ...GROUP2]
  }
  if (deviceId === 4 && selectedMeteo === 1) {
    DATA = [...DATA, ...GROUP3]
  }
  if (deviceId === 4 && selectedMeteo === 3) {
    DATA = [...DATA, ...GROUP4]
  }
  if (deviceId === 4 && selectedMeteo === 4) {
    DATA = [...DATA, ...GROUP5]
  }
  if (deviceId === 4 && selectedMeteo === 2) {
    DATA = [...DATA, ...GROUP6]
  }

  return (
    <>
      {DATA.map(({ label, value, color }) => (
        <FormControlLabel
          key={label}
          value={value}
          control={
            <Radio
              size='small'
              sx={{
                color: color,
                '&.Mui-checked': {
                  color: color,
                },
              }}
            />
          }
          label={label}
        />
      ))}
    </>
  )
}

export default ChartRadioGroupItem
