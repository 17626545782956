import { Autocomplete, TextField } from '@mui/material'
import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react'
import cn from '@/utils/cn'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

const s = cn('custom-autocomplete')

type Item = {
  name: string
  id: number
}

interface ICustomAutocomplete {
  options: Item[]
  filter: number[]
  setFilter: Dispatch<SetStateAction<number[]>>
  nameForStorage: string
  disabled?: boolean
  label?: string
}

type CustomAutocompleteProps = ICustomAutocomplete
const CustomAutocomplete: FC<CustomAutocompleteProps> = ({
  options,
  filter,
  setFilter,
  nameForStorage,
  disabled,
  label,
}) => {
  const ref = useRef(null)

  useEffect(() => {
    if (ref !== null && !disabled) {
      const el = ref.current as unknown as HTMLDivElement
      if (el !== null) {
        const chip = el.querySelectorAll('.MuiChip-label')
        const cross = el.querySelectorAll('.MuiChip-deleteIcon')
        const isAll = chip.length > 0 ? chip[0]?.innerHTML === 'Все' : null
        if (isAll === null) {
          return
        }
        if (isAll) {
          cross[0].setAttribute('style', 'display: none;')
        } else {
          cross[0].removeAttribute('style')
        }
      }
    }
  }, [filter, disabled])

  return (
    <Autocomplete
      ref={ref}
      className={s()}
      multiple
      color='primary'
      fullWidth
      disableCloseOnSelect
      disabled={disabled}
      size='small'
      options={options ?? []}
      value={options?.filter((item) => filter?.includes(item.id))}
      getOptionLabel={(option) => option.name}
      onChange={(e: any, newValue) => {
        const arr = newValue.map((item) => item.id)
        const index0 = arr.findIndex((item) => item === 0)
        if (index0 > -1 && arr.length > 1 && index0 === 0) {
          arr.splice(index0, 1)
        } else if (arr.includes(0)) {
          arr.splice(0, arr.length, 0)
        }
        if (arr.length === 0) {
          setFilter(() => [0])
          localStorage.setItem(nameForStorage, JSON.stringify([0]))
        } else {
          setFilter(() => arr)
          localStorage.setItem(nameForStorage, JSON.stringify(arr))
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          margin='none'
          variant='filled'
          label={label ? label : `Фильтр систем`}
        />
      )}
    />
  )
}

export default CustomAutocomplete
