import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './index'

export interface CounterState {
  isChangeModules: boolean
  openChangeModulesDialog: boolean
  saveFuncs: Function[]
  isChangeDescription: boolean
  openChangeDescriptionDialog: boolean
  isChangeFeatures: boolean
  openChangeFeaturesDialog: boolean

  isChangeUser: boolean
  openChangeUserDialog: boolean
  isChangeOrganization: boolean
  openChangeOrganizationDialog: boolean
}

const initialState: CounterState = {
  isChangeModules: false,
  openChangeModulesDialog: false,
  saveFuncs: [],
  isChangeDescription: false,
  openChangeDescriptionDialog: false,
  isChangeFeatures: false,
  openChangeFeaturesDialog: false,
  isChangeUser: false,
  openChangeUserDialog: false,
  isChangeOrganization: false,
  openChangeOrganizationDialog: false,
}

export const changeDialogSlice = createSlice({
  name: 'changeDialog',
  initialState,
  reducers: {
    setIsChangeModules: (state, action: PayloadAction<boolean>) => {
      state.isChangeModules = action.payload
    },
    setIsOpenChangeModulesDialog: (state, action: PayloadAction<boolean>) => {
      state.openChangeModulesDialog = action.payload
    },
    setSaveFuncs: (state, action: PayloadAction<Function[]>) => {
      action.payload.forEach((item) => {
        item()
      })
    },
    setIsChangeDescription: (state, action: PayloadAction<boolean>) => {
      state.isChangeDescription = action.payload
    },
    setIsOpenChangeDescriptionDialog: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.openChangeDescriptionDialog = action.payload
    },
    setIsChangeFeatures: (state, action: PayloadAction<boolean>) => {
      state.isChangeFeatures = action.payload
    },
    setIsOpenChangeFeaturesDialog: (state, action: PayloadAction<boolean>) => {
      state.openChangeFeaturesDialog = action.payload
    },
    setIsChangeUser: (state, action: PayloadAction<boolean>) => {
      state.isChangeUser = action.payload
    },
    setIsOpenChangeUserDialog: (state, action: PayloadAction<boolean>) => {
      state.openChangeUserDialog = action.payload
    },
    setIsChangeOrganization: (state, action: PayloadAction<boolean>) => {
      state.isChangeOrganization = action.payload
    },
    setIsOpenChangeOrganizationDialog: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.openChangeOrganizationDialog = action.payload
    },
  },
})

export const {
  setIsOpenChangeModulesDialog,
  setIsChangeModules,
  setSaveFuncs,
  setIsChangeDescription,
  setIsOpenChangeDescriptionDialog,
  setIsChangeFeatures,
  setIsOpenChangeFeaturesDialog,
  setIsChangeUser,
  setIsOpenChangeUserDialog,
  setIsChangeOrganization,
  setIsOpenChangeOrganizationDialog,
} = changeDialogSlice.actions

export const isChangeModulesSelector = (state: RootState) => state.changeDialog

export default changeDialogSlice.reducer
