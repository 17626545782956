import cn from '@/utils/cn'
import { useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useGetOrganizationProfileQuery } from '@/modules/organization/services/organizationService'
import { Box, CircularProgress } from '@mui/material'
import Header from './components/Header/Header'
import OrganizationTabs from '@/modules/organization/components/OrganizationTabs/OrganizationTabs'
import { useGetUserProfileQuery } from '@/modules/user/services/userService'
import { UserTypes } from '@/types/typeUser'

const s = cn('organization')

const Organization = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const isNewOrganization = id === 'new'

  const [loader, setLoader] = useState(false)

  const { isLoading: isLoadingOrganizationData } =
    useGetOrganizationProfileQuery(id ?? '0', {
      skip: isNewOrganization,
    })

  const { data: currentUserData } = useGetUserProfileQuery('0')
  const currentUserType = currentUserData?.data.typeId

  useEffect(() => {
    if (currentUserType === UserTypes.user) {
      navigate(-1)
    }
  }, [])

  return (
    <>
      <CircularProgress
        className={
          isLoadingOrganizationData || loader
            ? s('show-loader')
            : s('hide-loader')
        }
      />
      <Box className={s({ loading: isLoadingOrganizationData || loader })}>
        <Header />
        <Box mt={{ xl: 3 }} />
        <OrganizationTabs setLoader={setLoader} />
      </Box>
    </>
  )
}

export default Organization
