import cn from '@/utils/cn'
import { Box, CircularProgress } from '@mui/material'
import { useState } from 'react'
import Header from './components/Header'
import Sections from './components/Sections'

const s = cn('favorites')

const Favorites = () => {
  const [loader, setLoader] = useState(false)
  const [isLenght, setIsLenght] = useState(false)

  return (
    <>
      <CircularProgress
        className={loader ? s('show-loader') : s('hide-loader')}
      />
      <Box
        p={{ xss: 0, md: 2 }}
        height={{ xss: 'calc(100% - 64px)', md: '100%' }}
        sx={{ maxHeight: isLenght ? 'calc(100vh - 97px)' : '100%' }}
        display='flex'
        flexDirection='column'
        alignItems={{ xss: 'center', md: 'flex-start' }}
        gap={{ xss: 1, md: 2 }}
        className={s({ loading: loader })}
      >
        <Header />
        <Sections
          setLoader={setLoader}
          setIsLenght={setIsLenght}
        />
      </Box>
    </>
  )
}

export default Favorites
