import { createTheme, responsiveFontSizes } from '@mui/material'
import { ruRU } from '@mui/material/locale'

let theme = createTheme(
  {
    palette: {
      //mode: 'dark',
      primary: {
        main: 'rgba(0, 114, 188, .7)',
        contrastText: '#fff',
      },
      secondary: {
        main: '#ffffff',
        contrastText: '#000',
      },
      white: {
        main: '#ffffff',
        contrastText: '#fff',
      },
      green: {
        main: '#5ebc69',
        contrastText: '#fff',
      },
      transparent: {
        main: 'rgba(0, 0, 0, 0)',
        contrastText: 'rgba(0, 0, 0, 0)',
      },
      purple: {
        main: '#e450ed',
        contrastText: '#e450ed',
      },
      red: {
        main: '#d00',
        contrastText: '#fff',
      },
    },
    breakpoints: {
      values: {
        xss: 0,
        xs: 375,
        sm: 425,
        md: 768,
        mdx: 769,
        lg: 1024,
        xlg: 1200,
        xlgx: 1366,
        xl: 1440,
        xl3: 1536,
        xl4: 1680,
        xll: 1920,
        xxl: 2560,
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          size: 'small',
          variant: 'outlined',
          disableElevation: true,
        },
      },
    },
  },
  ruRU
)

theme = responsiveFontSizes(theme)

declare module '@mui/material/styles' {
  interface Palette {
    white: Palette['primary']
    green: Palette['primary']
    transparent: Palette['primary']
    purple: Palette['primary']
    red: Palette['primary']
  }

  interface PaletteOptions {
    white?: PaletteOptions['primary']
    green?: PaletteOptions['primary']
    transparent?: PaletteOptions['primary']
    purple?: PaletteOptions['primary']
    red?: PaletteOptions['primary']
  }

  interface CircularProgressOptions {
    white?: PaletteOptions['primary']
    green?: PaletteOptions['primary']
    transparent?: PaletteOptions['primary']
    purple?: PaletteOptions['primary']
    red?: PaletteOptions['primary']
  }

  interface BadgeOptions {
    white?: PaletteOptions['primary']
    purple?: PaletteOptions['primary']
    red?: PaletteOptions['primary']
  }

  interface BreakpointOverrides {
    xss: true
    xxl: true
    xlg: true
    xlgx: true
    xll: true
    xl3: true
    xl4: true
    mdx: true
  }
}

declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    white: true
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    white: true
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsColorOverrides {
    white: true
  }
}

declare module '@mui/material/Box' {
  interface BoxPropsColorOverrides {
    white: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true
    green: true
    red: true
  }
}

declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    green: true
  }
}

declare module '@mui/material/Badge' {
  interface BadgePropsColorOverrides {
    green: true
    transparent: true
    purple: true
    red: true
  }
}

declare module '@mui/material/Radio' {
  interface RadioPropsColorOverrides {
    purple: true
  }
}

export default theme
