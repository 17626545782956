import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import DescrSystem from '@/modules/device-module/components/description/DescrSystem'
import Notes from '@/modules/device-module/components/description/Notes'
import { useGetDescriptionQuery } from '@/modules/device-module/services/deviceService'
import { useParams } from 'react-router-dom'
import cn from '@/utils/cn'

const s = cn('device-module-descr')
const Description = () => {
  const { id } = useParams()

  const { isLoading } = useGetDescriptionQuery(Number(id))

  return (
    <>
      <CircularProgress
        className={isLoading ? s('show-loader') : s('hide-loader')}
      />
      <Box
        flex={1}
        display='flex'
        flexDirection={{ xss: 'column', lg: 'row' }}
        gap={{ xss: 0.5, lg: 2 }}
        p={2}
        pt={{ xss: 0 }}
        className={s({ loading: isLoading })}
      >
        <Notes />
        <DescrSystem />
      </Box>
    </>
  )
}

export default Description
