import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/store'
import { UserLoginState } from '@/modules/login/types'

const initialState: UserLoginState = {
  email: '',
  user: null,
  isLogin: false,
}

export const userLoginStoreSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload
    },
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload
    },
    setIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload
      if (action.payload) {
        localStorage.setItem('isLogin', `${action.payload}`)
      }
    },
  },
})

export const { setUserEmail, setUser, setIsLogin } = userLoginStoreSlice.actions

export const userEmailSelector = (state: RootState) =>
  state.userLoginStore.email
export const userLoginSelector = (state: RootState) =>
  state.userLoginStore.isLogin

export default userLoginStoreSlice.reducer
