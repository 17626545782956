import errorBlue from '@/assets/img/errorBlue.png'
import { useActions } from '@/hooks'
import { EventType } from '@/modules/events/types/eventsType'
import {
  useDeletedNotificationsMutation,
  useReadNotificationsMutation,
} from '@/modules/sidebar/services/notificationService'
import { Message } from '@/modules/sidebar/types/notifications'
import ClearIcon from '@mui/icons-material/Clear'
import {
  Box,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from '@mui/material'
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'

interface Props {
  message: Message
}

const NotificationsItem: FC<Props> = ({ message }) => {
  const navigate = useNavigate()

  const [hovered, setHovered] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const { markAsRead, deleteNotification } = useActions()

  const [readNotificationsService, { isLoading }] =
    useReadNotificationsMutation()
  const [deleteNotificationsService, { isLoading: isLoadingDelete }] =
    useDeletedNotificationsMutation()

  const getSecondaryAction = () => {
    if (loading || isLoading || isLoadingDelete) {
      return <CircularProgress size={20} />
    }
    if (hovered) {
      return (
        <IconButton
          edge='end'
          onClick={onDeleteNotification}
        >
          <ClearIcon htmlColor='red' />
        </IconButton>
      )
    }
    return null
  }

  const getInfoIcon = () => {
    if (loading || isLoading || isLoadingDelete) {
      return <CircularProgress size={20} />
    }
    if (hovered) {
      return (
        <IconButton
          edge='end'
          onClick={onDeleteNotification}
        >
          <ClearIcon htmlColor='red' />
        </IconButton>
      )
    }
    return null
  }

  const onReadNotification = async () => {
    try {
      await readNotificationsService({
        deviceId: message.deviceId,
        eventId: message.id,
      }).unwrap()
      markAsRead(message.id)
    } catch (e) {}
  }

  const onDeleteNotification = async () => {
    try {
      await deleteNotificationsService({
        deviceId: message.deviceId,
        eventId: message.id,
      }).unwrap()
      deleteNotification(message.id)
    } catch (e) {}
  }

  return (
    <ListItem
      sx={{ p: 0, maxWidth: 455 }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      secondaryAction={getSecondaryAction()}
    >
      <ListItemButton onClick={onReadNotification}>
        {message.type === EventType.info && (
          <ListItemIcon>
            <img
              src={errorBlue}
              alt='Info'
              width={30}
            />
          </ListItemIcon>
        )}
        <Box
          display='flex'
          flexDirection='column'
          fontWeight={message.new ? 'bold' : 'normal'}
        >
          <Box>
            <a
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                navigate(`/devices/${message.deviceId}`, {
                  state: { eventId: message.id, deviceId: message.deviceId },
                })
              }}
              style={{ marginRight: 10 }}
            >
              {message.moduleName}:
            </a>
            <span>{message.message}</span>
          </Box>
          <div style={{ color: 'gray', fontSize: 12 }}>
            {message.createdDate}
          </div>
        </Box>
      </ListItemButton>
    </ListItem>
  )
}

export default NotificationsItem
