import ConnectionStatusIcon from '@/components/icons/ConnectionStatusIcon'
import InfoIcon from '@/components/icons/InfoIcon'
import Is220Icon from '@/components/icons/Is220Icon'
import SignalIcon from '@/components/icons/SignalIcon'
import TankIcon from '@/components/icons/TankIkon'
import TempIcon from '@/components/icons/TempIcon'
import { useActions, useAppSelector } from '@/hooks'
import { Status } from '@/modules/device-module/types/deviceType'
import { filterTanksSelector } from '@/store/filterTanksSlise'
import cn from '@/utils/cn'
import { CircularProgress, Pagination, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useGetAquaUnitsListMutation } from '../../services/newTanksListService'
import { Unit, UnitSearchReq } from '../../types/newUnitsType'
import BottomPanel from './BottomPanel'

const s = cn('tanks-list')

interface IUsersTable {}

const TanksTable: FC<IUsersTable> = () => {
  const { filter, searchValue, isSearch, favoriteId } =
    useAppSelector(filterTanksSelector)
  const { setSearchValueTanks } = useActions()
  const navigate = useNavigate()

  const [getUnitsService, { isLoading: isLoadingSearch }] =
    useGetAquaUnitsListMutation()

  const [units, setUnits] = useState<Unit[]>([])
  const [page, setPage] = React.useState(
    Number(localStorage.getItem('pageTanks')) || 1
  )
  const [pages, setPages] = React.useState(1)
  const [count, setCount] = React.useState(0)
  const [filtersRes, setFilterRes] = React.useState([0])

  const [totalErrors, setTotalErrors] = React.useState(0)
  const [totalNotSmart, setTotalNotSmart] = React.useState(0)
  const [totalModulesCount, setTotalModulesCount] = React.useState(0)
  const [totalServiceable, setTotalServiceable] = React.useState(0)
  const [totalWarnings, setTotalWarnings] = React.useState(0)

  const handleChangePage = (event: unknown, newPage: number) => {
    localStorage.setItem('pageTanks', String(newPage))
    setPage(newPage)
  }

  const onGetUnits = async () => {
    try {
      const usersSearchData: UnitSearchReq = {
        value: !searchValue.trim().length ? '' : searchValue,
        page: page,
        deviceTypeFilter: filter,
        favoriteId: favoriteId,
      }
      const res = await getUnitsService(usersSearchData).unwrap()
      if (res.success) {
        setUnits(res.data)
        setPage(res.page || 1)
        setPages(res.pages || 1)
        setCount(res.count ?? 0)

        setTotalModulesCount(res.totalModulesCount ?? 0)
        setTotalErrors(res.totalErrors ?? 0)
        setTotalNotSmart(res.totalNotSmart ?? 0)
        setTotalServiceable(res.totalServiceable ?? 0)
        setTotalWarnings(res.totalWarnings ?? 0)
      }
      if (!searchValue.trim().length) {
        setSearchValueTanks('')
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    onGetUnits().then()
  }, [page, isSearch])

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      position='relative'
      className={s('container')}
    >
      <CircularProgress
        className={isLoadingSearch ? s('show-loader') : s('hide-loader')}
      />
      <Box
        width='100%'
        height='100%'
        display='flex'
        flexDirection='column'
        className={isLoadingSearch ? s('table-loading') : ''}
      >
        <Paper
          sx={{
            flex: 1,
            width: '100%',
            height: '79%',
            pb: { xss: 0, md: 2 },
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.83)',
          }}
          elevation={6}
        >
          <TableContainer
            sx={{
              height: '95%',
              overflowY: 'auto',
              backgroundColor: '#fff',
            }}
            className={s('tableContainer')}
          >
            <Table size='small'>
              <TableBody>
                {units.length === 0 ? (
                  <TableRow>
                    <TableCell
                      component='th'
                      scope='row'
                    >
                      <Typography textAlign='center'>
                        Ни одной записи не найдено
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {units.map((unit, index) => (
                      <TableRow
                        key={index}
                        hover
                        tabIndex={-1}
                        className={s()}
                      >
                        <TableCell
                          component='th'
                          scope='row'
                        >
                          <Box
                            display='flex'
                            alignItems='center'
                            gap={{ xss: 1, lg: 2 }}
                          >
                            <Link
                              to={`/devices/${unit.id}`}
                              className={s('link')}
                            >
                              <span
                                className={s('name')}
                                style={{ color: 'gray' }}
                              >
                                {unit.type} {unit.volume}м3
                              </span>
                              <span
                                className={s('address')}
                                style={{ color: '#0072BC' }}
                              >
                                {unit.address}
                              </span>
                            </Link>
                          </Box>
                        </TableCell>
                        <TableCell
                          align='right'
                          onClick={() => navigate(`/devices/${unit.id}`)}
                          sx={{
                            color: '#0072BC',
                            cursor: 'pointer',
                          }}
                        >
                          <Box
                            display='flex'
                            justifyContent='flex-end'
                            gap={2}
                            flexWrap={{ xss: 'wrap', md: 'nowrap' }}
                            flexDirection={{ xss: 'column', md: 'row' }}
                          >
                            <InfoIcon
                              tooltip={unit.errorMessage}
                              type={
                                unit.state === Status.error
                                  ? 'error'
                                  : unit.state === Status.warning
                                    ? 'warning'
                                    : 'ok'
                              }
                            />
                            <TankIcon
                              type={unit.formTypeId === 1 ? 'cyl' : 'rect'}
                            />
                            {unit.currentVolume === null ||
                            unit.volume === null ? (
                              <Box
                                display='flex'
                                alignItems='center'
                                gap={1}
                                width={170}
                              >
                                <Tooltip
                                  title='Рабочий объем воды не указан'
                                  placement='top'
                                >
                                  <div
                                    style={{
                                      width: '100%',
                                      height: '20px',
                                      backgroundColor: '#cce3f2',
                                      position: 'relative',
                                    }}
                                  >
                                    <span
                                      style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        //color: 'black',
                                        //fontWeight: 'bold',
                                      }}
                                    ></span>
                                  </div>
                                </Tooltip>
                                <span style={{ display: 'block', width: 55 }}>
                                  {Number(unit.currentVolume).toFixed(0)}м3
                                </span>
                              </Box>
                            ) : (
                              <Box
                                display='flex'
                                alignItems='center'
                                gap={1}
                                width={170}
                              >
                                <Tooltip
                                  title='Объем воды'
                                  placement='top'
                                >
                                  <div
                                    style={{
                                      width: '100%',
                                      height: '20px',
                                      backgroundColor: '#cce3f2',
                                      position: 'relative',
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: `${
                                          (unit.currentVolume * 100) /
                                            unit.volume >
                                          100
                                            ? 100
                                            : (unit.currentVolume * 100) /
                                              unit.volume
                                        }%`,
                                        height: '100%',
                                        backgroundColor: '#3dc4f4',
                                      }}
                                    />
                                    <span
                                      style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        //color: 'black',
                                        //fontWeight: 'bold',
                                      }}
                                    >
                                      {Number(
                                        (unit.currentVolume * 100) /
                                          unit.volume >
                                          100
                                          ? 100
                                          : (unit.currentVolume * 100) /
                                              unit.volume
                                      ).toFixed(0)}
                                      %
                                    </span>
                                  </div>
                                </Tooltip>
                                <span style={{ display: 'block', width: 55 }}>
                                  {Number(unit.currentVolume).toFixed(0)}м3
                                </span>
                              </Box>
                            )}
                            <TempIcon temperature={unit.temperature} />
                            <ConnectionStatusIcon
                              status={unit.connectionStatus}
                              lastDate={unit.lastPackageDate}
                              width={
                                units.filter(
                                  (unit) => unit.connectionStatus === 3
                                ).length === 0
                                  ? 97
                                  : 130
                              }
                            />
                            <SignalIcon signalNumber={unit.signal} />
                            <Is220Icon is220v={unit.is220v} />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={{ xss: 0, md: 2 }} />
          <Box
            display='flex'
            width='100%'
            justifyContent='flex-end'
          >
            {count <= 25 ? null : (
              <Pagination
                count={pages}
                page={page}
                onChange={handleChangePage}
                size='small'
                siblingCount={1}
                boundaryCount={2}
                color={'primary'}
              />
            )}
          </Box>
        </Paper>
        <BottomPanel
          totalErrors={totalErrors}
          totalNotSmart={totalNotSmart}
          totalModulesCount={totalModulesCount}
          totalServiceable={totalServiceable}
          totalWarnings={totalWarnings}
        />
      </Box>
    </Box>
  )
}

export default TanksTable
