import { useActions, useAppSelector } from '@/hooks'
import ColumnSlider from '@/modules/devices-list/components/DevicesTable/ColumnSlider'
import { fScreenSelector } from '@/store/filterSystemsSlise'
import cn from '@/utils/cn'
import CropFreeIcon from '@mui/icons-material/CropFree'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined'
import { Box, IconButton } from '@mui/material'
import { Popover } from 'antd'
import * as React from 'react'
import { Dispatch, FC, SetStateAction, useState } from 'react'

const s = cn('devices-list')

interface Props {
  columns: number
  setIsFullScreen: Dispatch<SetStateAction<boolean>>
  setColumns: Dispatch<SetStateAction<number>>
  isFullScreen: boolean
}

const HeaderFullScreen: FC<Props> = ({
  isFullScreen,
  setIsFullScreen,
  columns,
  setColumns,
}) => {
  const [isHover, setIsHover] = useState(false)

  const isFScreen = useAppSelector(fScreenSelector)
  const { toggleFScreen } = useActions()

  return (
    <Box
      height={50}
      position='sticky'
      top={0}
      zIndex={6}
      display={isFullScreen ? 'block' : 'none'}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Box
        height={isHover ? 50 : 0}
        position='relative'
        top={isHover ? 0 : -50}
        sx={{ backgroundColor: '#fff' }}
        className={s('fullScreen-header')}
        display='flex'
        justifyContent='flex-end'
      >
        <Popover
          id='popove-slider'
          placement='bottomRight'
          content={
            <ColumnSlider
              columns={columns}
              setColumns={setColumns}
            />
          }
          trigger='click'
        >
          <IconButton>
            <ViewQuiltOutlinedIcon />
          </IconButton>
        </Popover>
        <IconButton
          onClick={() => {
            setIsFullScreen(false)
            toggleFScreen(false)
          }}
        >
          {isFScreen ? <FullscreenExitIcon /> : <CropFreeIcon />}
        </IconButton>
      </Box>
    </Box>
  )
}

export default HeaderFullScreen
