import UnitRow from '@/modules/organization/components/Units/UnitRow'
import { useSearchUnitsOrganizationMutation } from '@/modules/organization/services/organizationService'
import cn from '@/utils/cn'
import {
  CircularProgress,
  Pagination,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import * as React from 'react'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { OrganizationInList } from '../../types/organizationType'

const s = cn('units')

interface IUnitsTable {
  isSearch: boolean
  searchValue: string
  filter: number[]
  setSearchValue: Dispatch<SetStateAction<string>>
  setLoader: Dispatch<SetStateAction<boolean>>
}

const UnitsTable: FC<IUnitsTable> = ({
  isSearch,
  searchValue,
  filter,
  setSearchValue,
  setLoader,
}) => {
  const { id } = useParams()

  const [units, setUnits] = useState<OrganizationInList[]>([])
  const [page, setPage] = React.useState(
    Number(localStorage.getItem('pageOrgUnits')) || 1
  )
  const [pages, setPages] = React.useState(1)
  const [count, setCount] = React.useState(0)

  const [getUnitsService, { isLoading: isLoadingSearch }] =
    useSearchUnitsOrganizationMutation()

  const handleChangePage = (event: unknown, newPage: number) => {
    localStorage.setItem('pageOrgUnits', String(newPage))
    setPage(newPage)
  }

  const onGetUnits = async () => {
    try {
      const unitsSearchData = {
        value: !searchValue.trim().length ? '' : searchValue,
        deviceTypeFilter: filter,
        page: page,
        organizationId: Number(id),
      }
      const res = await getUnitsService(unitsSearchData).unwrap()
      if (res.success) {
        setUnits(res.data)
        setPage(res.page || 1)
        setPages(res.pages || 1)
        setCount(res.count ?? 0)
      }
      if (!searchValue.trim().length) {
        setSearchValue('')
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    onGetUnits().then()
  }, [page, isSearch, filter])

  const isTablet = useMediaQuery('(max-width:870px)')
  const isIphone = navigator.userAgent.includes('iPhone')

  return (
    <>
      <CircularProgress
        className={isLoadingSearch ? 'show-loader' : 'hide-loader'}
      />
      <Paper
        sx={{
          width: '100%',
          mt: 3,
          pb: 2,
        }}
        elevation={6}
        className={s({ loading: isLoadingSearch })}
      >
        <TableContainer
          sx={{
            height: `calc(100vh - ${
              isIphone
                ? document.documentElement.clientHeight - 250
                : isTablet
                  ? 380
                  : 290
            }px)`,
            overflowY: 'auto',
          }}
          className={s('tableContainer')}
        >
          <Table size='small'>
            <TableBody>
              {units.length === 0 ? (
                <TableRow>
                  <TableCell
                    component='th'
                    scope='row'
                  >
                    <Typography textAlign='center'>
                      Ни одной записи не найдено
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                units.map((unit) => {
                  return (
                    <UnitRow
                      key={unit.deviceId}
                      unit={unit}
                    />
                  )
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={2} />
        <Box
          display='flex'
          width='100%'
          justifyContent='flex-end'
        >
          {count <= 25 ? null : (
            <Pagination
              count={pages}
              page={page}
              onChange={handleChangePage}
              size='small'
              siblingCount={1}
              boundaryCount={2}
              color={'primary'}
            />
          )}
        </Box>
      </Paper>
    </>
  )
}

export default UnitsTable
