import App from '@/app/App'
import { store } from '@/store'
import '@/styles/index.scss'
import theme from '@/ui/theme'
import '@/utils/errorIgnore'
import '@/utils/serviceWorker'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { ConfigProvider } from 'antd'
import locale from 'antd/es/locale/ru_RU'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

if (window.location.protocol === 'http:') {
  window.location.replace(import.meta.env.VITE_CLIENT_URL)
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer
        autoClose={1500}
        theme='colored'
      />
      <ConfigProvider locale={locale}>
        <App />
      </ConfigProvider>
    </ThemeProvider>
  </Provider>
)
