import cloudBlue from '@/assets/img/cloud-blue.png'
import cloudGray from '@/assets/img/cloud-gray.png'
import cloudRed from '@/assets/img/cloud-red.png'
import electrical from '@/assets/img/electricalBlue.png'
import battary100 from '@/assets/img/full-battery.png'
import signal100 from '@/assets/img/full-connection.png'

import battary0 from '@/assets/img/low-battery.png'
import battary50 from '@/assets/img/medium-battery.png'
import signal50 from '@/assets/img/medium-connection.png'

import signal0 from '@/assets/img/no-connection.png'
import proc from '@/assets/img/processor.png'
import sim from '@/assets/img/sim-card.png'

import time from '@/assets/img/time.png'
import { useAppSelector } from '@/hooks'
import { infoIconsSelector } from '@/modules/device-module/store/infoIconsSlice'

import { signal, voltage12, voltage24, VoltageEnum } from '@/utils'
import cn from '@/utils/cn'
import { Box } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import dayjs from 'dayjs'

const s = cn('device-module-info-icons')

const InfoIcons = () => {
  const data = useAppSelector(infoIconsSelector)

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={{ xss: 0.5, lg: 2 }}
      pt={1}
      className={s()}
    >
      <Box
        display='flex'
        alignItems='center'
        gap={2}
      >
        <Box
          display='flex'
          alignItems='center'
          gap={1}
          width={{ xss: 140, lg: 160 }}
        >
          <Tooltip
            title={'Время в системе'}
            placement='top'
          >
            <img
              src={time}
              alt='Время'
              width={25}
              height={25}
            />
          </Tooltip>
          <span style={{ color: '#fff' }}>
            {data?.deviceDate
              ? dayjs(data?.deviceDate).format('DD.MM.YYYY HH:mm')
              : '-'}
          </span>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          gap={0.5}
          width={95}
        >
          <Tooltip
            title={'Оператор связи'}
            placement='top'
          >
            <img
              src={sim}
              alt='Сим-карта'
              width={25}
              height={25}
            />
          </Tooltip>
          <span style={{ color: '#fff' }}>{data?.operator}</span>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          gap={0.5}
          width={75}
        >
          <Tooltip
            title={'Уровень GSM сигнала'}
            placement='top'
          >
            <img
              src={
                signal(data?.signal ?? 0) === 'low'
                  ? signal0
                  : signal(data?.signal ?? 0) === 'medium'
                    ? signal50
                    : signal(data?.signal ?? 0) === 'high'
                      ? signal100
                      : signal0
              }
              height={25}
              width={25}
              alt='Сигнал'
            />
          </Tooltip>
          <span style={{ color: '#fff' }}>{data?.signal ?? 0}%</span>
        </Box>
      </Box>
      <Box
        display='flex'
        alignItems='center'
        gap={2}
      >
        <Box
          display='flex'
          alignItems='center'
          gap={1}
          width={{ xss: 140, lg: 160 }}
        >
          <Tooltip
            title={'Состояние подключения'}
            placement='top'
          >
            <img
              src={
                data?.connectionStatus === 1
                  ? cloudBlue
                  : data?.connectionStatus === 2
                    ? cloudGray
                    : data?.connectionStatus === 3
                      ? cloudRed
                      : cloudRed
              }
              height={25}
              width={25}
              alt='Связь'
            />
          </Tooltip>
          <span style={{ color: '#fff' }}>
            {data?.connectionStatus === 1
              ? 'В сети'
              : data?.connectionStatus === 2
                ? 'Не в сети'
                : data?.connectionStatus === 3
                  ? data?.lastPackageDate
                    ? dayjs(data?.lastPackageDate).format('DD.MM.YYYY HH:mm')
                    : 'Нет соединения'
                  : '-'}
          </span>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          gap={0.5}
          width={95}
        >
          <Tooltip
            title={'Количество модулей в системе'}
            placement='top'
          >
            <img
              src={proc}
              height={25}
              width={25}
              alt='Системы'
            />
          </Tooltip>
          <span style={{ color: '#fff' }}>{data?.modulesCount} шт.</span>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          gap={0.5}
          width={75}
        >
          {data?.is220v ? (
            <>
              <Tooltip
                title={'Питание от сети'}
                placement='top'
              >
                <img
                  width={25}
                  height={25}
                  src={electrical}
                  alt='Вилка'
                />
              </Tooltip>
              <span style={{ color: '#fff' }}>
                {Number(data?.powerVoltage).toFixed(2)}B
              </span>
            </>
          ) : (
            <>
              <Tooltip
                title={'Напряжение питания'}
                placement='top'
              >
                <img
                  src={
                    (data?.powerVoltage ?? 0) <= VoltageEnum.min
                      ? voltage12(data?.powerVoltage ?? 0) === 'low'
                        ? battary0
                        : voltage12(data?.powerVoltage ?? 0) === 'medium'
                          ? battary50
                          : voltage12(data?.powerVoltage ?? 0) === 'high'
                            ? battary100
                            : battary0
                      : (data?.powerVoltage ?? 0) > VoltageEnum.min &&
                          (data?.powerVoltage ?? 0) <= VoltageEnum.max
                        ? voltage24(data?.powerVoltage ?? 0) === 'low'
                          ? battary0
                          : voltage24(data?.powerVoltage ?? 0) === 'medium'
                            ? battary50
                            : voltage24(data?.powerVoltage ?? 0) === 'high'
                              ? battary100
                              : battary0
                        : battary0
                  }
                  height={25}
                  width={25}
                  alt='Сигнал'
                />
              </Tooltip>
              <span style={{ color: '#fff' }}>
                {Number(data?.powerVoltage).toFixed(2)}B
              </span>
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default InfoIcons
