import { api } from '@/api'
import amount from '@/assets/img/hygrometer-40.png'
import cloudSpeed from '@/assets/img/icons8-download-from-cloud-40.png'
import radius from '@/assets/img/icons8-radius-40.png'
import windowIcon from '@/assets/img/icons8-window-40.png'
import ice from '@/assets/img/icy.png'
import vid from '@/assets/img/opera-glasses-30.png'
import press from '@/assets/img/pressure.png'
import snow from '@/assets/img/snow.png'
import temp from '@/assets/img/temp.png'

import vlazh from '@/assets/img/vlazh.png'
import water from '@/assets/img/water.png'
import wheel from '@/assets/img/wheel.png'
import windDir from '@/assets/img/windDir.png'
import windSpeed from '@/assets/img/windSpeed.png'
import { setInfoIcons } from '@/modules/device-module/store/infoIconsSlice'
import {
  addPhotoAfter,
  setPhotos,
} from '@/modules/device-module/store/photoSliderSlice'
import { setDevice } from '@/modules/device-module/store/stateSlice'
import {
  setCommands,
  setLastUpdatedDate,
  setTerminalHistoryNew,
  updateHistory,
} from '@/modules/device-module/store/terminalNewSlice'
import { setTerminalHistory } from '@/modules/device-module/store/terminalSlice'
import { DescriptionRes } from '@/modules/device-module/types/description'
import {
  AddToFavorites,
  DeleteNotes,
  DeviceDetail,
  DeviceRes,
  DeviceUpdate,
  DeviceUpdateNewNote,
  MeteoSave,
  PhotoItem,
} from '@/modules/device-module/types/deviceType'
import { DeviceEventsRes } from '@/modules/device-module/types/events'
import {
  TerminalHistory,
  TerminalHistoryRes,
  TerminalRes,
  TerminalUnit,
} from '@/modules/device-module/types/terminal'
import { TerminalResNew } from '@/modules/device-module/types/terminal-new'
import { ForecastTypesRes } from '@/modules/devices-list/types/forecastTypes'
import { ISimpleResponse } from '@/modules/login/types'
import { COLORS } from '@/utils'
import { degToCompass, TypeSynop } from '@/utils/meteo'
import dayjs from 'dayjs'
import _ from 'lodash'

const deviceService = api.injectEndpoints({
  endpoints: (build) => ({
    getDeviceDetail: build.query<DeviceDetail, number>({
      query: (id) => ({
        url: '/devices/getDetails',
        method: 'GET',
        params: { id },
      }),
      transformResponse: (res: DeviceRes) => {
        return res.data
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setInfoIcons(data))
          const newDate = _.cloneDeep(data)
          newDate.modulesMeteo = [
            {
              id: 1,
              type: data?.meteo?.fws.type ?? 1,
              deleted: false,
              moduleId: data?.meteo?.fws.moduleId ?? 1,
              displaySequence: data?.meteo?.fws.displaySequence ?? 1,
              name: data?.meteo?.fws.name
                ? data?.meteo?.fws.name
                : 'Датчик окружающей среды',
              errorMessage: data?.meteo?.fws.errorMessage ?? '',
              units: [
                {
                  name: 'Температура',
                  icon: temp,
                  value: data?.meteo?.fws.temperature ?? 0,
                  suffix: '°C',
                },
                {
                  name: 'Влажность',
                  icon: vlazh,
                  value: data?.meteo?.fws.humidity ?? 0,
                  suffix: '%',
                },
                {
                  name: 'Давление',
                  icon: press,
                  value: data?.meteo?.fws.pressureHg ?? 0,
                  suffix: 'мм.рт.ст.',
                },
                {
                  name: 'Направление ветра',
                  icon: windDir,
                  value: data?.meteo?.fws.directionWindAvg
                    ? degToCompass(data.meteo.fws.directionWindAvg)
                    : '-',
                },
                {
                  name: 'Скорость ветра',
                  icon: windSpeed,
                  value: `${data?.meteo?.fws.speedAvg ?? 0} м/с порывы до ${data?.meteo?.fws.speedMax ?? 0} м/с`,
                },
              ],
              info: {
                state: data?.meteo?.fws.state ?? 3,
                date: data?.meteo?.fws.lastPackageDate ?? '-',
                connectionStatus: data?.meteo?.fws.connectionStatus ?? 3,
                message: '',
              },
            },
            {
              id: 2,
              deleted: false,
              type: data?.meteo?.nir.type ?? 2,
              moduleId: data?.meteo?.nir.moduleId ?? 2,
              displaySequence: data?.meteo?.nir.displaySequence ?? 2,
              name: data?.meteo?.nir.name
                ? data?.meteo?.nir.name
                : 'Датчик дорожного покрытия',
              errorMessage: data?.meteo?.nir.errorMessage ?? '',
              units: [
                {
                  name: 'Дорога',
                  icon: temp,
                  value: data?.meteo?.nir.pavementTemperature ?? 0,
                  suffix: '°C',
                },
                {
                  name: 'Коэф. сцепления',
                  icon: wheel,
                  value: data?.meteo?.nir.wetAndSlippery ?? 0,
                },
                {
                  name: 'Вода',
                  icon: water,
                  value: data?.meteo?.nir.waterCumulative ?? 'нет',
                  suffix: 'мм',
                },
                {
                  name: 'Снег',
                  icon: snow,
                  value: data?.meteo?.nir.snowAccumulation ?? 'нет',
                  suffix: 'мм',
                },
                {
                  name: 'Лед',
                  icon: ice,
                  value: data?.meteo?.nir.iceAccumulation ?? 0,
                  suffix: 'мм',
                },
              ],
              info: {
                state: data?.meteo?.nir.state ?? 3,
                date: data?.meteo?.nir.lastPackageDate ?? '-',
                connectionStatus: data?.meteo?.nir.connectionStatus ?? 3,
                message: '',
              },
            },
            {
              id: 3,
              deleted: false,
              displaySequence: 3,
              type: data?.meteo?.dsm.type ?? 3,
              moduleId: data?.meteo?.dsm.moduleId ?? 3,
              name: data?.meteo?.dsm.name
                ? data?.meteo?.dsm.name
                : 'Датчик дорожного покрытия',
              errorMessage: data?.meteo?.dsm.errorMessage ?? '',
              units: [
                {
                  name: 'Тип осадков',
                  icon: windowIcon,
                  value:
                    TypeSynop[
                      data?.meteo?.dsm.precipitationId as keyof typeof TypeSynop
                    ] ?? 'нет данных',
                },
                {
                  name: 'Количество',
                  icon: amount,
                  value: data?.meteo?.dsm.intensity ?? 0,
                  suffix: 'мм/час',
                },
                {
                  name: 'Скорость падения',
                  icon: cloudSpeed,
                  value: data?.meteo?.dsm.fallVelocity ?? 0,
                  suffix: 'м/с',
                },
                {
                  name: 'Размер',
                  icon: radius,
                  value: data?.meteo?.dsm.diameter ?? 0,
                  suffix: 'мм',
                },
              ],
              info: {
                state: data?.meteo?.dsm.state ?? 3,
                date: data?.meteo?.dsm.lastPackageDate ?? '-',
                connectionStatus: data?.meteo?.dsm.connectionStatus ?? 3,
                message: '',
              },
            },
            {
              id: 4,
              deleted: false,
              type: data?.meteo?.vre.type ?? 4,
              moduleId: data?.meteo?.vre.moduleId ?? 4,
              displaySequence: data?.meteo?.vre.displaySequence ?? 4,
              name: data?.meteo?.vre.name
                ? data?.meteo?.vre.name
                : 'Датчик видимости',
              errorMessage: data?.meteo?.vre.errorMessage ?? '',
              units: [
                {
                  name: 'Видимость',
                  icon: vid,
                  value: data?.meteo?.vre.avgSlow ?? 0,
                  suffix: 'м',
                },
              ],
              info: {
                state: data?.meteo?.vre.state ?? 3,
                date: data?.meteo?.vre.lastPackageDate ?? '-',
                connectionStatus: data?.meteo?.vre.connectionStatus ?? 3,
                message: '',
              },
            },
          ]
          dispatch(setDevice(newDate))
          if (data.photos) {
            dispatch(setPhotos(data.photos))
          }
        } catch (e) {
          console.log(e)
        }
      },
      providesTags: ['Device'],
    }),
    getDeviceDetailInterval: build.query<DeviceDetail, number>({
      query: (id) => ({
        url: '/devices/getDetails',
        method: 'GET',
        params: { id },
      }),
      transformResponse: (res: DeviceRes) => {
        return res.data
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(setInfoIcons(data))
        } catch (e) {
          console.log(e)
        }
      },
      providesTags: ['Device'],
    }),
    deleteNotes: build.mutation<ISimpleResponse, DeleteNotes>({
      query: (body) => ({
        url: '/devices/deleteNotes',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Description'],
    }),
    addToFavorites: build.mutation<ISimpleResponse, AddToFavorites>({
      query: (body) => ({
        url: '/favorites/updateDevice',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Favorites', 'FavoritesSmall', 'Device'],
    }),
    getChartData: build.mutation<any, { data: any; isMeteo: boolean }>({
      query: ({ data }) => {
        return {
          url: '/devices/getChart',
          method: 'POST',
          body: data,
        }
      },
      transformResponse: (res: any, req, arg) => {
        res.data = JSON.parse(res.data)
        const powerVoltage = res.data.modules?.map(
          (item: any, index: number) => {
            return {
              data: item.powerVoltage?.map((el: { d: string; v: number }) => {
                return {
                  x: Date.parse(el.d),
                  y: el.v,
                }
              }),
              id: item.moduleId,
              label: 'Напряжение питания',
              borderColor: COLORS[index],
              tension: 0.4,
              pointRadius: 3,
              pointBackgroundColor: '#fff',
              yAxisID: 'y',
            }
          }
        )
        const activity = res.data.modules?.map((item: any, index: number) => {
          return {
            data: item.activity?.map((el: { d: string; s: number }) => {
              return {
                x: Date.parse(el.d),
                y: el.s,
              }
            }),
            id: item.moduleId,
            label: 'Напряжение питания',
            borderColor: COLORS[index],
            tension: 0,
            pointRadius: 3,
            pointBackgroundColor: '#fff',
            yAxisID: 'y3',
          }
        })
        const meteo = {
          data: res.data?.data?.map((el: { d: string; v: number }) => {
            return {
              x: Date.parse(el.d),
              y: el.v,
            }
          }),
          id: 4,
          tension: 0.4,
          pointRadius: 3,
          pointBackgroundColor: '#fff',
        }

        if (powerVoltage) {
          res.data.modulesVoltage = powerVoltage
        }
        if (activity) {
          res.data.modulesActivity = activity
        }
        if (meteo) {
          res.data.modulesMeteo = meteo
          if (arg.isMeteo) {
            delete res.data.data
          }
        }
        return res.data
      },
    }),
    getDescription: build.query<DescriptionRes, number>({
      query: (id) => ({
        url: '/devices/getDescription',
        method: 'GET',
        params: { id },
      }),
      providesTags: ['Description'],
      transformResponse: (res: DescriptionRes) => {
        res.data.notes.sort((a, b) => {
          return (
            new Date(a.createdDate).getTime() -
            new Date(b.createdDate).getTime()
          )
        })
        return res
      },
    }),
    updateDescription: build.mutation<
      { success: boolean },
      { deviceId: number; description: string }
    >({
      query: (body) => ({
        url: '/devices/updateDescription',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Description'],
    }),
    updateNotes: build.mutation<
      { success: boolean },
      { deviceId: number; message: string }
    >({
      query: (body) => ({
        url: '/devices/addNote',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Description'],
    }),
    updateNotesNew: build.mutation<{ success: boolean }, FormData>({
      query: (body) => ({
        url: '/devices/addNoteNew',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Description'],
    }),
    updateDevice: build.mutation<{ success: boolean }, DeviceUpdate>({
      query: (body) => ({
        url: '/devices/updateModules',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Device'],
    }),
    getDeviceEvents: build.query<
      DeviceEventsRes,
      {
        deviceId: number
        start: string
        end: string
        page: number
        eventTypeFilter: number[]
        filterModules: number[]
        loadFilterModules: boolean
      }
    >({
      query: (body) => ({
        url: '/events/getDeviceEvents',
        method: 'POST',
        body,
      }),
      transformResponse: (res: DeviceEventsRes) => {
        res.data.forEach((item) => {
          item.createdDate = dayjs(item.createdDate).format('DD.MM.YYYY HH:mm')
        })
        return res
      },
    }),
    getTerminalCommands: build.query<TerminalUnit[], number>({
      query: (deviceId) => ({
        url: '/terminal/getCommands',
        method: 'GET',
        params: { deviceId },
      }),
      transformResponse: (res: TerminalRes) => {
        return res.data
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setCommands(data as any))
        } catch (error) {
          console.log(error)
        }
      },
      providesTags: ['Commands'],
    }),
    addTerminalCommands: build.mutation<
      ISimpleResponse,
      { deviceId: number; text: string }
    >({
      query: (body) => ({
        url: '/terminal/addCommand',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Commands'],
    }),
    setTerminalCommands: build.mutation<
      ISimpleResponse,
      { deviceId: number; command: string }
    >({
      query: (body) => ({
        url: '/terminal/sendCommand',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['HistoryNew'],
    }),
    updateTerminalCommands: build.mutation<
      ISimpleResponse,
      { deviceId: number; commands: TerminalUnit[] }
    >({
      query: (body) => ({
        url: '/terminal/updateCommands',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['HistoryNew'],
    }),
    getTerminalHistory: build.query<
      TerminalHistory[],
      { deviceId: number; lastHistoryId: number }
    >({
      query: ({ deviceId, lastHistoryId }) => ({
        url: '/terminal/getHistory',
        method: 'POST',
        body: { deviceId, lastHistoryId },
      }),
      transformResponse: (res: TerminalHistoryRes) => {
        res.data.sort((a, b) =>
          new Date(a.createdDate) > new Date(b.createdDate) ? 1 : -1
        )
        res.data.forEach((item) => {
          item.createdDate = dayjs(item.createdDate).format('DD.MM.YY HH:mm')
        })
        return res.data
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setTerminalHistory(data))
        } catch (e) {
          console.log(e)
        }
      },
      providesTags: ['History'],
    }),
    updateMeteo: build.mutation<ISimpleResponse, MeteoSave>({
      query: (body) => ({
        url: '/devices/updateMeteoModules',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Device'],
    }),
    getPhotosDetail: build.query<DeviceDetail, number>({
      query: (id) => ({
        url: '/devices/getPhotoCameraDetails',
        method: 'POST',
        body: { id },
      }),
      transformResponse: (res: DeviceRes) => {
        return res.data
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data.photos) {
            dispatch(setPhotos(data.photos))
          }
        } catch (e) {
          console.log(e)
        }
      },
      providesTags: ['Device'],
    }),
    getPhotos: build.mutation<
      PhotoItem[],
      { deviceId: string; lastPhotoDate: string; isNew: boolean }
    >({
      query: ({ deviceId, lastPhotoDate, isNew }) => ({
        url: '/devices/getPhotoCameraGallery',
        method: 'POST',
        params: { deviceId, lastPhotoDate, isNew },
      }),
      transformResponse: (res: { data: PhotoItem[] }) => {
        return res.data
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (args.isNew) {
            //dispatch(addPhotoAfter(data))
          } else {
            //dispatch(addPhotoBefore(data))
          }
        } catch (e) {
          console.log(e)
        }
      },
    }),
    getPhotosInterval: build.query<
      PhotoItem[],
      { deviceId: string; lastPhotoDate: string; isNew: boolean }
    >({
      query: ({ deviceId, lastPhotoDate, isNew }) => ({
        url: '/devices/getPhotoCameraGallery',
        method: 'POST',
        params: { deviceId, lastPhotoDate, isNew },
      }),
      transformResponse: (res: { data: PhotoItem[] }) => {
        return res.data
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data.length > 0) {
            dispatch(addPhotoAfter(data))
          } else {
            //dispatch(addPhotoBefore(data))
          }
        } catch (e) {
          console.log(e)
        }
      },
    }),
    getForeCast: build.query<ForecastTypesRes, { deviceId: string }>({
      query: ({ deviceId }) => ({
        url: '/devices/weatherStation',
        method: 'GET',
        params: { deviceId },
      }),
    }),
    getTerminalInfo: build.query<
      TerminalResNew,
      { deviceId: number; lastUpdatedDate: string }
    >({
      query: ({ deviceId, lastUpdatedDate }) => ({
        url: '/terminal/get',
        method: 'POST',
        body: { deviceId, lastUpdatedDate },
      }),
      transformResponse: (res: TerminalResNew) => {
        res.data.history?.sort((a, b) =>
          new Date(a.createdDate) > new Date(b.createdDate) ? 1 : -1
        )
        res.data.history?.forEach((item) => {
          item.createdDate = dayjs(item.createdDate).format('DD.MM.YY HH:mm')
        })
        return res
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setLastUpdatedDate(data.data.lastUpdatedDate))
          if (data.data.commands?.length) {
            dispatch(setCommands(data.data.commands))
          }
          if (new Date(args.lastUpdatedDate).getFullYear() === 1900) {
            dispatch(setTerminalHistoryNew(data.data.history))
          }
          if (
            new Date(args.lastUpdatedDate).getFullYear() > 2000 &&
            data.data.history?.length > 0
          ) {
            dispatch(updateHistory(data.data.history))
          }
        } catch (e) {
          console.log(e)
        }
      },
      providesTags: ['HistoryNew'],
    }),

    getTerminalInfoMutation: build.mutation<
      TerminalResNew,
      { deviceId: number; lastUpdatedDate: string }
    >({
      query: ({ deviceId, lastUpdatedDate }) => ({
        url: '/terminal/get',
        method: 'POST',
        body: { deviceId, lastUpdatedDate },
      }),
      transformResponse: (res: TerminalResNew) => {
        res.data.history?.sort((a, b) =>
          new Date(a.createdDate) > new Date(b.createdDate) ? 1 : -1
        )
        res.data.history?.forEach((item) => {
          item.createdDate = dayjs(item.createdDate).format('DD.MM.YY HH:mm')
          item.message = item.message.replaceAll(/\n/g, '<br/>')
        })
        return res
      },
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          // @ts-ignore
          const history = await getState().terminalNew.history

          dispatch(setLastUpdatedDate(data.data.lastUpdatedDate))
          if (data.data.commands?.length) {
            dispatch(setCommands(data.data.commands))
          }
          if (
            new Date(args.lastUpdatedDate).getFullYear() === 1900 ||
            history?.length === 0 ||
            history === null
          ) {
            dispatch(setTerminalHistoryNew(data.data.history))
          }
          if (
            new Date(args.lastUpdatedDate).getFullYear() > 2000 &&
            data.data.history?.length > 0
          ) {
            dispatch(updateHistory(data.data.history))
          }
        } catch (e) {
          console.log(e)
        }
      },
    }),
    getTerminalInfoMutation2: build.mutation<
      TerminalResNew,
      { deviceId: number; lastUpdatedDate: string }
    >({
      query: ({ deviceId, lastUpdatedDate }) => ({
        url: '/terminal/get',
        method: 'POST',
        body: { deviceId, lastUpdatedDate },
      }),
      transformResponse: (res: TerminalResNew) => {
        res.data.history?.sort((a, b) =>
          new Date(a.createdDate) > new Date(b.createdDate) ? 1 : -1
        )
        res.data.history?.forEach((item) => {
          item.createdDate = dayjs(item.createdDate).format('DD.MM.YY HH:mm')
          item.message = item.message.replaceAll(/\n/g, '<br/>')
        })
        return res
      },
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          // @ts-ignore
          const history = await getState().terminalNew.history

          dispatch(setLastUpdatedDate(data.data.lastUpdatedDate))
          if (data.data.commands?.length) {
            dispatch(setCommands(data.data.commands))
          }
          if (
            new Date(args.lastUpdatedDate).getFullYear() === 1900 ||
            history?.length === 0 ||
            history === null
          ) {
            dispatch(setTerminalHistoryNew(data.data.history))
          }
          if (
            new Date(args.lastUpdatedDate).getFullYear() > 2000 &&
            data.data.history?.length > 0
          ) {
            dispatch(updateHistory(data.data.history))
          }
        } catch (e) {
          console.log(e)
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetDeviceDetailQuery,
  useUpdateDescriptionMutation,
  useGetDescriptionQuery,
  useAddToFavoritesMutation,
  useGetChartDataMutation,
  useUpdateNotesMutation,
  useUpdateNotesNewMutation,
  useUpdateDeviceMutation,
  useGetDeviceEventsQuery,
  useGetDeviceDetailIntervalQuery,
  useGetTerminalCommandsQuery,
  useAddTerminalCommandsMutation,
  useSetTerminalCommandsMutation,
  useGetTerminalHistoryQuery,
  useUpdateTerminalCommandsMutation,
  useUpdateMeteoMutation,
  useGetPhotosMutation,
  useGetPhotosDetailQuery,
  useGetForeCastQuery,
  useGetTerminalInfoQuery,
  useGetTerminalInfoMutationMutation,
  useGetTerminalInfoMutation2Mutation,
  useGetPhotosIntervalQuery,
  useDeleteNotesMutation,
} = deviceService
