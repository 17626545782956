import cn from '@/utils/cn'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ClearIcon from '@mui/icons-material/Clear'
import { DatePickerProps } from '@mui/lab'
import {
  ClickAwayListener,
  IconButton,
  InputAdornment,
  TextFieldProps,
  useMediaQuery,
} from '@mui/material'
import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import * as React from 'react'
import { FC, useRef, useState } from 'react'
import 'dayjs/locale/ru'
import { useController, UseControllerProps } from 'react-hook-form'

const s = cn('date-picker-user')

type IDatePicker = {}

type DatePickerUser = IDatePicker &
  DatePickerProps<TextFieldProps> &
  UseControllerProps<any>

const DatePickers: FC<DatePickerUser> = ({
  name,
  control,
  label,
  disableFuture,
  ...props
}) => {
  const is4K = useMediaQuery('(min-width:2560px)')
  const ref = useRef(null)

  const [open, setOpen] = useState(false)

  const {
    field: { onChange, value },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields, errors },
  } = useController({
    name,
    control,
  })

  const handleClickAway = () => {
    if (open) {
      setOpen(false)
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale='ru'
        >
          <DesktopDatePicker
            closeOnSelect
            onAccept={() => setOpen(false)}
            label={label}
            open={open}
            inputFormat='DD.MM.YYYY'
            value={
              value && typeof value === 'string'
                ? dayjs(
                    `${value.split('.')[2]}-${value.split('.')[1]}-${value.split('.')[0]}`
                  )
                : value
                  ? dayjs(value)
                  : null
            }
            onChange={(event: any) => {
              if (event === null) {
                onChange(null)
              } else {
                onChange(dayjs(event))
              }
            }}
            disableFuture={disableFuture}
            {...props}
            renderInput={(params: any) => {
              return (
                <TextField
                  {...params}
                  variant='filled'
                  fullWidth
                  onClick={() => setOpen((prevState) => !prevState)}
                  size={is4K ? undefined : 'small'}
                  margin='none'
                  autoComplete='off'
                  className={s()}
                  error={!!errors[name]}
                  helperText={errors[name]?.message as string}
                  {...props}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <>
                          {value && !props.disabled ? (
                            <IconButton
                              sx={{ mr: '3px' }}
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                onChange(null)
                              }}
                              //onMouseDown={handleMouseDownPassword}
                              edge='end'
                            >
                              <ClearIcon />
                            </IconButton>
                          ) : null}
                        </>
                        {!props.disabled && (
                          <IconButton
                            //onClick={handleClickShowPassword}
                            //onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            <CalendarMonthIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                />
              )
            }}
          />
        </LocalizationProvider>
      </div>
    </ClickAwayListener>
  )
}

export default DatePickers
