import { api } from '@/api'
import {
  Captcha,
  IChangePassRequest,
  ILoginField,
  IRecPassRequest,
  IRegisterRequest,
  ISimpleResponse,
} from '@/modules/login/types'

const login = api.injectEndpoints({
  endpoints: (build) => ({
    isAuth: build.query<ISimpleResponse, void>({
      query: () => ({
        url: '/auth/testauth',
        method: 'GET',
      }),
      extraOptions: {
        maxRetries: 3,
      },
    }),
    getCaptcha: build.query<Captcha, string>({
      query: () => ({
        url: '/auth/GetCaptcha',
        method: 'GET',
      }),
      extraOptions: {
        maxRetries: 3,
      },
    }),
    register: build.mutation<ISimpleResponse, IRegisterRequest>({
      query: (body) => ({
        url: '/auth/registration',
        method: 'POST',
        body,
      }),
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {},
    }),
    login: build.mutation<ISimpleResponse, ILoginField>({
      query: (body) => ({
        url: '/auth/login',
        method: 'POST',
        body,
      }),
    }),
    confirmEmail: build.mutation<ISimpleResponse, string>({
      query: (body) => ({
        url: `/auth/confirmationEmail?token=${body}`,
        method: 'POST',
      }),
    }),
    changePassword: build.mutation<ISimpleResponse, IChangePassRequest>({
      query: (body) => ({
        url: '/auth/changePassword',
        method: 'POST',
        body,
      }),
    }),
    changePasswordCheckToken: build.mutation<ISimpleResponse, string>({
      query: (token) => ({
        url: `/auth/checkChangePassword?token=${token}`,
        method: 'POST',
      }),
    }),
    recoveryPassword: build.mutation<ISimpleResponse, IRecPassRequest>({
      query: (body) => ({
        url: '/auth/recoveryPassword',
        method: 'POST',
        body,
      }),
    }),
    logout: build.mutation<ISimpleResponse, void>({
      query: () => ({
        url: '/auth/logOut',
        method: 'POST',
      }),
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        const { data } = await queryFulfilled
        if (data.success) {
          //dispatch(setUser(null))
          //dispatch(setIsLogin(false))
          //localStorage.clear()
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useLoginMutation,
  useRegisterMutation,
  useGetCaptchaQuery,
  useConfirmEmailMutation,
  useChangePasswordMutation,
  useRecoveryPasswordMutation,
  useLogoutMutation,
  useIsAuthQuery,
  useChangePasswordCheckTokenMutation,
} = login
