import UnitData from '@/modules/add-new-unit/components/UnitData/UnitData'
import { useGetNewUnitQuery } from '@/modules/add-new-unit/services/newUnitService'
import { useGetUserProfileQuery } from '@/modules/user/services/userService'
import { UserTypes } from '@/types/typeUser'
import cn from '@/utils/cn'
import { Box, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const s = cn('add-new-unit')
const AddNewUnit = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [loader, setLoader] = useState(false)

  const { data: currentUserData } = useGetUserProfileQuery('0')
  const currentUserType = currentUserData?.data.typeId
  const { data, isLoading } = useGetNewUnitQuery(
    { id: Number(id) },
    {
      skip: currentUserType !== UserTypes.superAdmin,
    }
  )

  useEffect(() => {
    if (
      currentUserType === UserTypes.user ||
      currentUserType === UserTypes.admin
    ) {
      navigate(-1)
    }
  }, [])

  useEffect(() => {
    if (data && !data.success) {
      navigate(-1)
    }
  }, [data])

  return (
    <>
      <CircularProgress
        className={loader || isLoading ? s('show-loader') : s('hide-loader')}
      />
      <Box
        className={s({ loading: isLoading || loader })}
        height='100%'
      >
        <UnitData setLoader={setLoader} />
      </Box>
    </>
  )
}

export default AddNewUnit
