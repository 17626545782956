import { api, rtkQueryErrorLogger } from '@/api'
import infoIconsSliceReducer from '@/modules/device-module/store/infoIconsSlice'
import photoSliderReducer from '@/modules/device-module/store/photoSliderSlice'
import stateDeviceReducer from '@/modules/device-module/store/stateSlice'
import terminalNewReducer from '@/modules/device-module/store/terminalNewSlice'
import terminalReducer from '@/modules/device-module/store/terminalSlice'
import favoritesReducer from '@/modules/favorites/store/favoritesSlise'
import userLoginStoreReducer from '@/modules/login/store/userLoginStore'
import messagesSliceReducer from '@/modules/sidebar/store/messagesSlice'
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import changeDialog from './changeDialogSlise'
import counterReducer from './counterSlise'
import filterSystemReducer from './filterSystemsSlise'
import filterTanksReducer from './filterTanksSlise'
import unitsReducer from './unitsSlise'
import logger from 'redux-logger'

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    userLoginStore: userLoginStoreReducer,
    favorites: favoritesReducer,
    counter: counterReducer,
    filterSystem: filterSystemReducer,
    units: unitsReducer,
    stateDevice: stateDeviceReducer,
    filterTanks: filterTanksReducer,
    photoSlider: photoSliderReducer,
    terminal: terminalReducer,
    terminalNew: terminalNewReducer,
    changeDialog,
    messagesSlice: messagesSliceReducer,
    infoIcons: infoIconsSliceReducer,
  },
  middleware: (gDM) =>
    gDM({ serializableCheck: false })
      .concat(api.middleware)
      .concat(rtkQueryErrorLogger),
  // .concat(logger as any),
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
