import { useAppSelector } from '@/hooks'
import {
  useGetTerminalInfoMutation2Mutation,
  useGetTerminalInfoMutationMutation,
} from '@/modules/device-module/services/deviceService'
import {
  terminalLastUpdatedDateSelector,
  triggerLoadHistorySelector,
} from '@/modules/device-module/store/terminalNewSlice'
import cn from '@/utils/cn'
import { Box, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Commands from './Commands'
import Notes from './Notes'

const s = cn('device-module-terminal')

let timer: NodeJS.Timeout | null = null
const Terminal = () => {
  const { id } = useParams()

  const [command, setCommand] = useState('')

  const lastUpdatedDate = useAppSelector(terminalLastUpdatedDateSelector)
  const triggerLoadHistory = useAppSelector(triggerLoadHistorySelector)

  const [getTerminalInfo] = useGetTerminalInfoMutationMutation()
  const [getTerminalInfo2, { isLoading }] =
    useGetTerminalInfoMutation2Mutation()

  useEffect(() => {
    getTerminalInfo2({ deviceId: Number(id), lastUpdatedDate: lastUpdatedDate })
  }, [triggerLoadHistory])

  useEffect(() => {
    timer = setInterval(() => {
      getTerminalInfo({
        deviceId: Number(id),
        lastUpdatedDate: lastUpdatedDate,
      })
    }, 3000)
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [lastUpdatedDate])

  return (
    <>
      <CircularProgress
        className={isLoading ? s('show-loader') : s('hide-loader')}
      />
      <Box
        flex={1}
        display='flex'
        flexDirection={{ xss: 'column', lg: 'row' }}
        gap={{ xss: 0.5, lg: 2 }}
        p={2}
        pt={{ xss: 0 }}
        className={s({ loading: isLoading })}
      >
        <Commands setValue={setCommand} />
        <Notes
          value={command}
          setValue={setCommand}
        />
      </Box>
    </>
  )
}

export default Terminal
