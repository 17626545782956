import { api } from '@/api'
import {
  NewUnitSearchReq,
  NewUnitSearchRes,
} from '@/modules/new-units/types/newUnitsType'
import dayjs from 'dayjs'

const newUnitsListService = api.injectEndpoints({
  endpoints: (build) => ({
    getNewUnitsList: build.mutation<NewUnitSearchRes, NewUnitSearchReq>({
      query: ({ value, page }) => ({
        url: '/devices/getAllNewDevices',
        method: 'GET',
        params: { value, page },
      }),
      transformResponse: (res: NewUnitSearchRes) => {
        if (res.success) {
          res.data.forEach((unit) => {
            unit.createDate = dayjs(new Date(unit.createDate)).format(
              'DD.MM.YYYY HH:mm:ss'
            )
          })
        }
        return res
      },
    }),
  }),
  overrideExisting: false,
})

export const { useGetNewUnitsListMutation } = newUnitsListService
