import tank0 from '@/assets/img/0.png'
import tank100 from '@/assets/img/100.png'
import tank20 from '@/assets/img/20.png'
import tank40 from '@/assets/img/40.png'
import tank60 from '@/assets/img/60.png'
import tank80 from '@/assets/img/80.png'
import rect0 from '@/assets/img/r0.png'
import rect100 from '@/assets/img/r100.png'
import rect20 from '@/assets/img/r20.png'
import rect40 from '@/assets/img/r40.png'
import rect60 from '@/assets/img/r60.png'
import rect80 from '@/assets/img/r80.png'

import Box from '@mui/material/Box'
import * as React from 'react'

const GridTankIcon = ({
  currentVolume,
  volume,
  isRect,
}: {
  currentVolume: number
  volume: number
  isRect: boolean
}) => {
  return (
    <Box
      width={250}
      height={250}
      display='flex'
      justifyContent='center'
      alignItems='center'
      position='relative'
    >
      <img
        src={
          currentVolume === null || volume === null
            ? isRect
              ? rect0
              : tank0
            : parseInt(Number((currentVolume * 100) / volume).toFixed(0)) > 100
              ? isRect
                ? rect100
                : tank100
              : parseInt(Number((currentVolume * 100) / volume).toFixed(0)) ===
                  0
                ? isRect
                  ? rect0
                  : tank0
                : parseInt(Number((currentVolume * 100) / volume).toFixed(0)) >
                      0 &&
                    parseInt(
                      Number((currentVolume * 100) / volume).toFixed(0)
                    ) <= 20
                  ? isRect
                    ? rect20
                    : tank20
                  : parseInt(
                        Number((currentVolume * 100) / volume).toFixed(0)
                      ) > 20 &&
                      parseInt(
                        Number((currentVolume * 100) / volume).toFixed(0)
                      ) <= 40
                    ? isRect
                      ? rect40
                      : tank40
                    : parseInt(
                          Number((currentVolume * 100) / volume).toFixed(0)
                        ) > 40 &&
                        parseInt(
                          Number((currentVolume * 100) / volume).toFixed(0)
                        ) <= 60
                      ? isRect
                        ? rect60
                        : tank60
                      : parseInt(
                            Number((currentVolume * 100) / volume).toFixed(0)
                          ) > 60 &&
                          parseInt(
                            Number((currentVolume * 100) / volume).toFixed(0)
                          ) <= 80
                        ? isRect
                          ? rect80
                          : tank80
                        : parseInt(
                              Number((currentVolume * 100) / volume).toFixed(0)
                            ) > 80 &&
                            parseInt(
                              Number((currentVolume * 100) / volume).toFixed(0)
                            ) <= 100
                          ? isRect
                            ? rect100
                            : tank100
                          : isRect
                            ? rect0
                            : tank0
        }
        alt='Резервуар'
      />
      {currentVolume === null || volume === null ? null : (
        <span
          style={{
            color: '#6479aa',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: 24,
            fontWeight: 'bold',
          }}
        >
          {Number(
            (currentVolume * 100) / volume > 100
              ? 100
              : (currentVolume * 100) / volume
          ).toFixed(0)}
          %
        </span>
      )}
    </Box>
  )
}

export default GridTankIcon
