import signal100 from '@/assets/img/full-connection.png'
import signal50 from '@/assets/img/medium-connection.png'
import signal0 from '@/assets/img/no-connection.png'
import { signal } from '@/utils'
import Box, { BoxProps } from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import * as React from 'react'
import { FC } from 'react'

type PropsIcon = {
  signalNumber: number
}

type Props = BoxProps & PropsIcon

const SignalIcon: FC<Props> = ({ signalNumber, width = 70, ...props }) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      gap={1}
      width={width}
      {...props}
    >
      {signal(signalNumber) === 'low' ? (
        <>
          <Tooltip
            title='Уровень сигнала'
            placement='top'
          >
            <img
              width={25}
              height={25}
              src={signal0}
              alt='Соединение'
            />
          </Tooltip>
          <span>{signalNumber}%</span>
        </>
      ) : signal(signalNumber) === 'medium' ? (
        <>
          <Tooltip
            title='Уровень сигнала'
            placement='top'
          >
            <img
              width={25}
              height={25}
              src={signal50}
              alt='Соединение'
            />
          </Tooltip>
          <span>{signalNumber}%</span>
        </>
      ) : signal(signalNumber) === 'high' ? (
        <>
          <Tooltip
            title='Уровень сигнала'
            placement='top'
          >
            <img
              width={25}
              height={25}
              src={signal100}
              alt='Соединение'
            />
          </Tooltip>
          <span>{signalNumber}%</span>
        </>
      ) : null}
    </Box>
  )
}

export default SignalIcon
