import iconDevicesState from '@/assets/img/icons/iconDevicesState.png'
import DevicesStatusContentPopover from '@/modules/devices-list/components/DevicesTable/DevicesStatusContentPopover'
import cn from '@/utils/cn'
import Box from '@mui/material/Box'
import { Popover } from 'antd'
import * as React from 'react'
import { FC } from 'react'

const s = cn('bottom-panel-location')

export type TotalSmartDetailsType = {
  totalSmartTurnCountErrors: number
  totalSmartTurnCountSuccess: number
  totalSmartLampCountErrors: number
  totalSmartLampCountSuccess: number
}

interface Props {
  total: number
  totalDetails: TotalSmartDetailsType | undefined
}

const DevicesStatus: FC<Props> = ({ total, totalDetails }) => {
  return (
    <>
      <Popover
        className={s()}
        content={<DevicesStatusContentPopover totalDetails={totalDetails} />}
        trigger='hover'
      >
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          sx={{ cursor: 'pointer' }}
          gap={1}
          flexWrap='wrap'
        >
          <img
            width={30}
            height={30}
            src={iconDevicesState}
            alt='Статус устройств'
          />
          <span style={{ textAlign: 'center' }}>{total} шт.</span>
        </Box>
      </Popover>
    </>
  )
}

export default DevicesStatus
