import greenLocation from '@/assets/img/location-green.png'
import redLocation from '@/assets/img/location-red.png'
import yellowLocation from '@/assets/img/location-yellow.png'
import tsoodd from '@/assets/img/rhombus.png'
import { Dialog, notifyError, notifySuccess } from '@/components'
import { useAddNewPhotoMutation } from '@/modules/add-new-photo/services/newUnitService'
import { AddNewUnitRes } from '@/modules/add-new-photo/types/newUnitType'
import { useGetNewUnitQuery } from '@/modules/add-new-unit/services/newUnitService'
import { useGetPhotosDetailQuery } from '@/modules/device-module/services/deviceService'
import { UnitSearchReq } from '@/modules/devices-list/types/newUnitsType'
import { useGetUnitsHomeMapMutation } from '@/modules/maps/services/homeMapService'
import Input from '@/modules/organization/components/Input/Input'
import { useGetUserProfileQuery } from '@/modules/user/services/userService'
import { useGetUnitsTypesListQuery } from '@/services/unitsListService'
import { UserTypes } from '@/types/typeUser'
import cn from '@/utils/cn'
import { useChangeSet } from '@/utils/useChangeSet'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { LoadingButton } from '@mui/lab'
import { Box, CircularProgress, Typography } from '@mui/material'
import {
  Map,
  Placemark,
  SearchControl,
  TypeSelector,
  ZoomControl,
} from '@pbe/react-yandex-maps'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import DeleteDeviceButton from '@/modules/device-module/components/deleteDeviceButton/deleteDeviceButton'

const s = cn('device-module-feature')

function ipv4(message = 'Адрес имеет некорректный формат') {
  // @ts-ignore
  return this.matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
    message,
    excludeEmptyString: true,
  }).test('ip', message, (value: any) => {
    return value === undefined || value.trim() === ''
      ? true
      : value.split('.').find((i: string) => parseInt(i, 10) > 255) ===
          undefined
  })
}

Yup.addMethod(Yup.string, 'ipv4', ipv4)
// @ts-ignore-start
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Имя не может быть пустым')
    .trim('Имя не может быть пустым')
    .max(30, 'Поле должно содержать не более 30 символов'),
  address: Yup.string()
    .required('Адрес не может быть пустым')
    .trim('Адрес не может быть пустым')
    .max(100, 'Поле должно содержать не более 100 символов'),
  path: Yup.string()
    .required('Путь не может быть пустым')
    .trim('Путь не может быть пустым')
    .max(200, 'Поле должно содержать не более 200 символов'),
})

// @ts-ignore-end

export interface IDataFields {
  name: string
  address: string
  path: string
}

interface IUnitsFields {
  name: string
  address: string
  path: string
}

const FeaturesPhoto = () => {
  const defaultPoint = [47.06587193746529, 39.435380396518724]

  const { id } = useParams()

  const { data } = useGetPhotosDetailQuery(Number(id))
  const { data: unitsTypesData, isLoading: isLoadingUnitsTypesList } =
    useGetUnitsTypesListQuery(
      { isNew: true, isAll: false, isSmart: data?.smart },
      { skip: !data }
    )

  const { data: currentUserData } = useGetUserProfileQuery('0')
  const currentUserType = currentUserData?.data.typeId

  const { data: properties, refetch } = useGetNewUnitQuery({ id: Number(id) })

  const [getDataUnits, { data: dataUnits }] = useGetUnitsHomeMapMutation()
  const [updateProperties] = useAddNewPhotoMutation()

  const [openDialogSave, setOpenDialogSave] = useState(false)
  const [loader, setLoader] = useState(false)
  const [newCoords, setNewCoords] = useState<number[]>(defaultPoint)
  const [center, setCenter] = useState<number[]>([47.2313, 39.7233])
  const refMark = useRef<any>(null)

  const onGetUnits = async () => {
    try {
      const usersSearchData: Omit<UnitSearchReq, 'page'> = {
        value: '',
        deviceTypeFilter: [0],
        deviceStateFilter: [0],
        favoriteId: 0,
      }
      await getDataUnits(usersSearchData).unwrap()
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    onGetUnits().then()
  }, [])

  const defaultValues: IDataFields = {
    name: '',
    address: '',
    path: '',
  }

  const { handleSubmit, control, reset, trigger, formState, watch, getValues } =
    useForm<IDataFields>({
      defaultValues,
      resolver: yupResolver(validationSchema),
      criteriaMode: 'all',
      reValidateMode: 'onChange',
    })

  useEffect(() => {
    reset({
      name: properties?.data.name,
      address: properties?.data.address,
      path: properties?.data.path,
    })
    setNewCoords(() => [
      properties?.data.latitude ?? 47.2313,
      properties?.data.longitude ?? 39.7233,
    ])
    setCenter(() => [
      properties?.data.latitude ?? 47.2313,
      properties?.data.longitude ?? 39.7233,
    ])
  }, [properties])

  const onSaveBtnClick = () => {
    trigger().then(() => {
      if (!getValues('name') || !getValues('address') || !getValues('path')) {
        return
      }
      if (
        !formState.errors.name &&
        !formState.errors.address &&
        !formState.errors.path
      ) {
        setOpenDialogSave(true)
      }
    })
  }

  const onSubmit = async (dataForm: IUnitsFields) => {
    try {
      setOpenDialogSave(false)
      setLoader(true)
      const newUnit: AddNewUnitRes = {
        id: Number(id),
        name: dataForm.name,
        address: dataForm.address,
        path: dataForm.path,
        coordinates: JSON.stringify(newCoords),
      }
      const res = await updateProperties(newUnit).unwrap()
      if (res.success) {
        notifySuccess('Данные успешно сохранены')
        refetch()
      }
    } catch (e) {
    } finally {
      setLoader(false)
    }
  }

  useChangeSet(() => {
    if (watch('name').length) {
      trigger('name')
    }
    if (watch('address').length) {
      trigger('address')
    }
    if (watch('path').length) {
      trigger('path')
    }
  }, [watch('name'), watch('address'), watch('path')])

  return (
    <>
      <CircularProgress
        className={loader ? s('show-loader') : s('hide-loader')}
      />
      <Box
        flex={1}
        display='flex'
        flexDirection={{ xss: 'column', md: 'row' }}
        className={s('aqua', { loading: loader })}
      >
        <Dialog
          message='Сохранить внесенные изменения?'
          open={openDialogSave}
          handleClose={() => setOpenDialogSave(false)}
          handleAgree={() => {
            if (Object.keys(formState.errors)?.length >= 1) {
              notifyError('Исправте ошибки в форме')
            } else {
              handleSubmit(onSubmit)()
            }
          }}
        />
        {/*Блок карты*/}
        <Box
          flex={1}
          display='flex'
          position='relative'
          top={{ lg: 30, xlg: -49 }}
          height='calc(100% + 51px)'
        >
          <Box
            border='1px solid #0072BC'
            className={s('map-container')}
            flex={1}
          >
            <Map
              state={{
                center: center,
                zoom: 9,
                controls: [],
              }}
              options={{
                yandexMapDisablePoiInteractivity: true,
              }}
              modules={['control.ZoomControl']}
              width='100%'
              height='100%'
              onClick={(event: any) => {
                const coords = event.get('coords')
                setNewCoords(() => coords)
              }}
            >
              <SearchControl options={{ float: 'left', noPlacemark: true }} />
              <ZoomControl
                options={{
                  position: {
                    right: 10,
                    left: 'auto',
                    top: (document.documentElement.scrollHeight - 500) / 2,
                    bottom: 'auto',
                  },
                  // @ts-ignore
                  float: 'right',
                }}
              />
              <TypeSelector
                options={{
                  // @ts-ignore
                  panoramasItemMode: 'off',
                }}
              />
              {dataUnits?.data?.data?.map((unit, i) => {
                return (
                  <Placemark
                    key={unit.id}
                    defaultGeometry={unit.coordinates}
                    options={{
                      iconLayout: 'default#image',
                      iconImageSize: [30, 30],
                      iconImageHref:
                        unit.smart || unit.typeId === 7 || unit.typeId === 8
                          ? unit.state === 1
                            ? greenLocation
                            : unit.state === 2
                              ? yellowLocation
                              : redLocation
                          : tsoodd,
                    }}
                  />
                )
              })}
              <Placemark
                className={'123456789'}
                instanceRef={refMark}
                onDragEnd={() => {
                  const coords = refMark.current.geometry._coordinates
                  setNewCoords(() => coords)
                }}
                geometry={newCoords}
                options={{
                  iconImageSize: [50, 60],
                  iconLayout: 'default#image',
                  draggable: currentUserType === UserTypes.superAdmin,
                  preset: 'islands#greenIcon',
                }}
                properties={{
                  iconContent: '+',
                }}
              />
            </Map>
          </Box>
        </Box>
        {/*Блок информации*/}
        <form
          style={{ flex: 1 }}
          onSubmit={(e) => e.preventDefault()}
        >
          <Box
            display='flex'
            flex={1}
            flexDirection={{ xss: 'column', md: 'column' }}
            pb={{ xss: 0.5, lg: 1 }}
          >
            {/*Информация о системе*/}
            <Box
              display='flex'
              flexDirection={{ xss: 'column', xlg: 'row' }}
            >
              <Box
                flex={1}
                px={1}
                mt={2}
              >
                <Typography
                  variant='h6'
                  fontSize={18}
                  color='primary'
                >
                  Информация о системе
                </Typography>
                <Box
                  display='flex'
                  flexDirection='column'
                  gap={2}
                >
                  <Input
                    name='name'
                    type='text'
                    label='Имя'
                    control={control}
                  />
                  <Input
                    multiline
                    minRows={1}
                    maxRows={5}
                    name='address'
                    type='text'
                    label='Адрес установки'
                    control={control}
                  />
                  <Input
                    name='path'
                    type='text'
                    label='Путь к каталогу изображений'
                    control={control}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='flex-end'
              gap={2}
              height={{ xss: 40, lg: 60 }}
              mr={{ xss: 0.5, lg: 2 }}
            >
              {currentUserType !== UserTypes.superAdmin ? null : (
                <>
                  {id && <DeleteDeviceButton deviceId={id} />}
                  <LoadingButton
                    loading={false}
                    variant='outlined'
                    onClick={onSaveBtnClick}
                    disabled={
                      watch('name')?.trim()?.length === 0 ||
                      watch('address')?.trim()?.length === 0 ||
                      watch('path')?.trim()?.length === 0 ||
                      newCoords === defaultPoint
                    }
                  >
                    <span>Сохранить</span>
                  </LoadingButton>
                </>
              )}
            </Box>
          </Box>
        </form>
      </Box>
    </>
  )
}

export default FeaturesPhoto
