import { api } from '@/api'
import { setFavorites } from '@/modules/favorites/store/favoritesSlise'
import {
  FavoritesRes,
  FavoritesSmallRes,
} from '@/modules/favorites/types/favorites'
import { ISimpleResponse } from '@/modules/login/types'

const favoritesService = api.injectEndpoints({
  endpoints: (build) => ({
    getFavoritesWithDevices: build.query<FavoritesRes, void>({
      query: () => ({
        url: '/favorites/getWithDevices',
        method: 'GET',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setFavorites(data.data))
        } catch (error) {}
      },
      providesTags: ['Favorites'],
    }),
    getFavoritesSmall: build.query<FavoritesSmallRes, void>({
      query: () => ({
        url: '/favorites/get',
        method: 'GET',
      }),
      providesTags: ['FavoritesSmall'],
    }),
    addFavorites: build.mutation<ISimpleResponse, FormData>({
      query: (body) => ({
        url: '/favorites/update',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['FavoritesSmall', 'Favorites'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetFavoritesWithDevicesQuery,
  useGetFavoritesSmallQuery,
  useAddFavoritesMutation,
} = favoritesService
