import video from '@/assets/video/v1.mp4'
import { useActions, useAppSelector } from '@/hooks'
import VideoItem from '@/modules/devices-list/components/DevicesTable/VideoItem'
import HeaderFullScreen from '@/modules/devices-list/components/Header/HeaderFullScreen'
import { Unit } from '@/modules/devices-list/types/newUnitsType'
import {
  filterSystemSelector,
  fScreenSelector,
} from '@/store/filterSystemsSlise'
import cn from '@/utils/cn'
import { DocumentFullScreen } from '@chiragrupani/fullscreen-react'
import { Box } from '@mui/material'
import * as React from 'react'
import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import PhotoItem from './PhotoItem'

const s = cn('devices-list')

interface Props {
  columns: number
  setIsFullScreen: Dispatch<SetStateAction<boolean>>
  setColumns: Dispatch<SetStateAction<number>>
  isFullScreen: boolean
  units: Unit[]
}

const GridView: FC<Props> = ({
  columns,
  units,
  isFullScreen,
  setIsFullScreen,
  setColumns,
}) => {
  const { isFullScreen: isFullScreenGlobal } =
    useAppSelector(filterSystemSelector)

  const isFScreen = useAppSelector(fScreenSelector)
  const { toggleFScreen } = useActions()

  useEffect(() => {
    return () => {
      setIsFullScreen(false)
      toggleFScreen(false)
    }
  }, [])

  return (
    <Box
      overflow='auto'
      height='100%'
      position={isFullScreenGlobal ? 'unset' : 'relative'}
      className={s('grid-container')}
      // display='flex'
      // flexDirection='column'
      // flexWrap='wrap'
      bgcolor={isFullScreen ? '#fff' : 'unset'}
      pt={{ xss: 'unset', xl3: isFScreen ? 0 : 2, xll: isFScreen ? 0 : 2 }}
    >
      <DocumentFullScreen
        isFullScreen={isFScreen}
        onChange={toggleFScreen}
      >
        <HeaderFullScreen
          setIsFullScreen={setIsFullScreen}
          columns={columns}
          setColumns={setColumns}
          isFullScreen={isFullScreen}
        />
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: `repeat(${columns}, .5fr)`,
            justifyItems: columns > 1 ? 'center' : 'unset',
            gap: '10px',
            paddingLeft: 10,
            paddingRight: isFScreen ? 10 : 0,
            paddingTop: isFScreen ? 5 : 0,
            position: isFullScreenGlobal ? 'unset' : 'relative',
            top: isFullScreen ? -50 : 0,
          }}
        >
          {units.map((item) => {
            if (item.typeId === 8) {
              return (
                <VideoItem
                  key={item.id}
                  unit={item}
                  video={video}
                  isHovered={false}
                  deviseId={245}
                />
              )
            }
            if (item.typeId === 7) {
              return (
                <PhotoItem
                  key={item.id}
                  unit={item}
                  isHovered={false}
                  deviseId={245}
                />
              )
            }
            return null
          })}
        </div>
      </DocumentFullScreen>
    </Box>
  )
}

export default GridView
