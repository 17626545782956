import cloudGreen from '@/assets/img/cloud-blue.png'
import cloudGray from '@/assets/img/cloud-gray.png'
import cloudRed from '@/assets/img/cloud-red.png'
import Box, { BoxProps } from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import dayjs from 'dayjs'
import * as React from 'react'
import { FC } from 'react'

type PropsIcon = {
  status: number
  lastDate?: string
  marginTop?: number
}

type Props = BoxProps & PropsIcon

const ConnectionStatusIcon: FC<Props> = ({
  status,
  lastDate,
  width = 142,
  marginTop,
  ...props
}) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      gap={1}
      width={width}
      {...props}
    >
      {status === 1 ? (
        <>
          <Tooltip
            title='Статус подключения'
            placement='top'
          >
            <img
              style={{ marginTop }}
              width={25}
              height={25}
              src={cloudGreen}
              alt='В сети'
            />
          </Tooltip>
          <span>В сети</span>
        </>
      ) : status === 2 ? (
        <>
          <Tooltip
            title='Статус подключения'
            placement='top'
          >
            <img
              style={{ marginTop }}
              width={25}
              height={25}
              src={cloudGray}
              alt='Не в сети'
            />
          </Tooltip>
          <span>Не в сети</span>
        </>
      ) : status === 3 ? (
        <>
          <Tooltip
            title='Статус подключения'
            placement='top'
          >
            <img
              style={{ marginTop }}
              width={25}
              height={25}
              src={cloudRed}
              alt='Нет соединения'
            />
          </Tooltip>
          <span>
            {dayjs(lastDate ?? new Date().toISOString()).format(
              'DD.MM.YY HH:mm'
            )}
          </span>
        </>
      ) : null}
    </Box>
  )
}

export default ConnectionStatusIcon
