import React, { FC } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'

interface IDialog {
  open: boolean
  handleClose: () => void
  handleAgree: () => void
  message?: string
  title?: string
  loading?: boolean
}

const DialogQuestion: FC<IDialog> = ({
  open,
  handleClose,
  message,
  handleAgree,
  title,
  loading,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      {title ? <DialogTitle>{title}</DialogTitle> : null}
      {message ? (
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
      ) : null}
      <DialogActions>
        <Button onClick={handleClose}>Нет</Button>
        <LoadingButton
          onClick={handleAgree}
          autoFocus
          loading={loading}
          variant='outlined'
        >
          Да
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default DialogQuestion
