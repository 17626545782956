import errorRed from '@/assets/img/error-red.png'
import error from '@/assets/img/icons-error.png'
import ok from '@/assets/img/ok-green.png'
import { useAppSelector } from '@/hooks'
import { infoIconsSelector } from '@/modules/device-module/store/infoIconsSlice'
import { Status } from '@/modules/device-module/types/deviceType'
import cn from '@/utils/cn'
import { Box } from '@mui/material'

const s = cn('device-module-info-icons')

const WarningMessage = () => {
  const data = useAppSelector(infoIconsSelector)

  return (
    <Box
      display='flex'
      alignItems='center'
      width={{ xss: 300, sm: 365, md: 365, lg: 365 }}
      gap={{ xss: 0.5, lg: 2 }}
      className={s()}
    >
      {data?.state === Status.ok ? null : (
        <img
          src={
            data?.state === Status.error
              ? errorRed
              : data?.state === Status.warning
                ? error
                : ok
          }
          alt='Ошибка'
          width={30}
          height={30}
        />
      )}
      <span style={{ color: '#fff' }}>
        {data?.state === Status.error
          ? (data.errorMessage ?? 'Система неисправна')
          : data?.state === Status.warning
            ? (data.errorMessage ?? 'Ошибки в работе системы')
            : ''}
      </span>
    </Box>
  )
}

export default WarningMessage
