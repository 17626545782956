export interface Event {
  id: number
  groupId: EventType
  userName: string
  createDate: string
  message: string
}

export interface EventsSearchRes {
  count: number
  data: Event[]
  page: number
  pages: number
  success: boolean
}

export interface EventsSearchReq {
  value: string
  page: number
  eventTypeFilter: number[]
}

export enum EventType {
  information = 1,
  system,
  warnings,
  info,
  extra = 101,
}
