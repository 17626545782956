import { useGetUserProfileQuery } from '@/modules/user/services/userService'
import { useSearchUsersMutation } from '@/modules/UsersList/services/search'
import { SearchTypesReq, Users } from '@/modules/UsersList/types/searchTypes'
import { UserTypes } from '@/types/typeUser'
import cn from '@/utils/cn'
import AddIcon from '@mui/icons-material/Add'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import {
  CircularProgress,
  Fab,
  Link as MiuLink,
  Pagination,
  Typography,
  useMediaQuery,
} from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import * as React from 'react'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const s = cn('users')

interface IUsersTable {
  showAllUsers: boolean
  isSearch: boolean
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
}

const UsersTable: FC<IUsersTable> = ({
  showAllUsers,
  isSearch,
  searchValue,
  setSearchValue,
}) => {
  const navigate = useNavigate()

  const isIphone = navigator.userAgent.includes('iPhone')

  const [getUsersService, { isLoading: isLoadingSearch }] =
    useSearchUsersMutation()
  const { data: currentUserData } = useGetUserProfileQuery('0', {
    refetchOnMountOrArgChange: true,
  })
  const currentUserType = currentUserData?.data.typeId

  const [users, setUsers] = useState<Users[]>([])
  const [page, setPage] = React.useState(
    Number(localStorage.getItem('page')) || 1
  )
  const [pages, setPages] = React.useState(1)
  const [count, setCount] = React.useState(0)

  const handleChangePage = (event: unknown, newPage: number) => {
    localStorage.setItem('page', String(newPage))
    setPage(newPage)
  }

  const onGetUsers = async () => {
    try {
      const usersSearchData: SearchTypesReq = {
        value: !searchValue.trim().length ? '' : searchValue,
        showDeleted: showAllUsers,
        page: page,
        newSearch: true,
      }
      const res = await getUsersService(usersSearchData).unwrap()
      if (res.success) {
        setUsers(res.data)
        setPage(res.page || 1)
        setPages(res.pages || 1)
        setCount(res.count ?? 0)
      }
      if (!searchValue.trim().length) {
        setSearchValue('')
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    onGetUsers().then()
  }, [page, showAllUsers, isSearch])

  const isTablet = useMediaQuery('(max-width:870px)')

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      position='relative'
    >
      <CircularProgress
        className={isLoadingSearch ? s('show-loader') : s('hide-loader')}
      />
      <Box
        width='100%'
        className={isLoadingSearch ? s('table-loading') : ''}
      >
        <Paper
          sx={{
            width: '100%',
            mt: 3,
            pb: 2,
          }}
          elevation={6}
        >
          <TableContainer
            sx={{
              height: `calc(100vh - ${
                currentUserType === UserTypes.user
                  ? 210
                  : isIphone
                    ? document.documentElement.clientHeight - 250
                    : isTablet
                      ? 370
                      : 270
              }px)`,
              overflowY: 'auto',
            }}
            className={s('tableContainer')}
          >
            <Table size='small'>
              <TableBody>
                {users.length === 0 ? (
                  <TableRow>
                    <TableCell
                      component='th'
                      scope='row'
                    >
                      <Typography textAlign='center'>
                        Ни одной записи не найдено
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  users.map((user) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={user.id}
                        className={s({ deleted: user.deleted })}
                      >
                        <TableCell padding='checkbox'>
                          {user.deleted ? (
                            <RemoveCircleIcon htmlColor='#a9a9a9' />
                          ) : (
                            <CheckCircleIcon htmlColor='#0072BC' />
                          )}
                        </TableCell>
                        <TableCell
                          component='th'
                          scope='row'
                        >
                          <Link
                            to={`/users/${user.id}`}
                            className={s('link', { deleted: user.deleted })}
                          >
                            {user.fullName || '---'}
                            <span>{user.type}</span>
                          </Link>
                        </TableCell>
                        <TableCell
                          align='right'
                          onClick={() => navigate(`/users/${user.id}`)}
                          sx={{
                            color: user.deleted
                              ? '#a9a9a9'
                              : 'rgb(0 0 0 / 87%)',
                            cursor: 'pointer',
                          }}
                        >
                          {user.organization}
                        </TableCell>
                      </TableRow>
                    )
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={2} />
          <Box
            display='flex'
            width='100%'
            justifyContent='flex-end'
          >
            {count <= 25 ? (
              <Box height={26} />
            ) : (
              <Pagination
                count={pages}
                page={page}
                onChange={handleChangePage}
                size='small'
                siblingCount={1}
                boundaryCount={2}
                color={'primary'}
              />
            )}
          </Box>
        </Paper>
        <Box mt={2} />
        {currentUserType === UserTypes.user ? null : (
          <Box
            display='flex'
            alignItems='center'
            gap={2}
            pb={isIphone ? 4 : 2}
          >
            <Fab
              style={{ zIndex: 1 }}
              onClick={() => navigate('/users/new')}
              color='green'
              aria-label='add'
            >
              <AddIcon />
            </Fab>
            <MiuLink
              component='button'
              variant='body1'
              onClick={() => navigate('/users/new')}
            >
              Добавить пользователя
            </MiuLink>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default UsersTable
