import { api } from '@/api'
import {
  SearchTypeRes,
  SearchTypesReq,
} from '@/modules/UsersList/types/searchTypes'

const search = api.injectEndpoints({
  endpoints: (build) => ({
    searchUsers: build.mutation<SearchTypeRes, SearchTypesReq>({
      query: (body) => ({
        url: '/users/search',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {},
    }),
  }),
  overrideExisting: false,
})

export const { useSearchUsersMutation } = search
