import drop from '@/assets/img/drop.png'
import Box, { BoxProps } from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import * as React from 'react'
import { FC } from 'react'

type PropsIcon = {
  volume: number
}

type Props = BoxProps & PropsIcon

const VolumeIcon: FC<Props> = ({ volume, width = 100, ...props }) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      gap={1}
      width={width}
      {...props}
    >
      <Tooltip
        title='Объем воды'
        placement='top'
      >
        <img
          width={25}
          height={25}
          src={drop}
          alt='Объем'
        />
      </Tooltip>
      <span>{Number(volume).toFixed(0)}м3</span>
    </Box>
  )
}

export default VolumeIcon
