import { Dialog, notifyError, notifySuccess } from '@/components'
import AvatarDialog from '@/modules/user/components/AvatarDialog/AvatarDialog'
import DatePickers from '@/modules/user/components/DatePicker/DatePicker'
import Input from '@/modules/user/components/Input/Input'
import PhoneInput from '@/modules/user/components/Input/PhoneInput'
import NotificationComponent from '@/modules/user/components/Notification/NotificationComponent'
import UserSelect from '@/modules/user/components/UserSelect/UserSelect'
import {
  useChangeUserStatusMutation,
  useDeleteUserAvatarMutation,
  useGetOrganisationsQuery,
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
} from '@/modules/user/services/userService'
import { stringToNumber } from '@/modules/user/utils/phoneToNumber'
import { UserTypes } from '@/types/typeUser'
import cn from '@/utils/cn'
import { dataURItoBlob } from '@/utils/dataURIToBlob'
import { useChangeSet } from '@/utils/useChangeSet'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import {
  Avatar,
  Box,
  FormControlLabel,
  Grid,
  Link,
  Stack,
  Switch,
} from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import ChangePassDialog from './ChangePassDialog'

const s = cn('user')

interface IUserData {
  firstName: string
  surName: string
  patronymic: string
  email: string
  phoneNumber: string
  position: string
  emailNotification: boolean
  webPushNotification: boolean
  birthDate: string | Dayjs
  organizationId: number | ''
  typeId: number | ''
}

const groups = [
  { name: 'Пользователь', id: 3 },
  { name: 'Администратор', id: 2 },
  { name: 'Супер Админ', id: 1 },
]

const validationSchema = Yup.object().shape({
  firstName: Yup.string().max(
    30,
    'Поле должно содержать не более 30-ти символов'
  ),
  surName: Yup.string().max(
    30,
    'Поле должно содержать не более 30-ти символов'
  ),
  patronymic: Yup.string().max(
    30,
    'Поле должно содержать не более 30-ти символов'
  ),
  position: Yup.string().max(
    40,
    'Поле должно содержать не более 40-ка символов'
  ),
  phoneNumber: Yup.string()
    .transform((value, origValue) => {
      return origValue === '+_(___)___-__-__'
        ? '00000000000'
        : stringToNumber(origValue || '00000000000')
    })
    .min(11, 'Некорректный Телефон. Формат +7(999)999-99-99'),
  email: Yup.string()
    .required('Логин не может быть пустым')
    .email('Некорректный Логин. Формат Email')
    .min(2, 'Длина Логина должна быть менее 2-ч символов')
    .max(50, 'Длина Логина должна быть не более 50-ти символов'),
  birthDate: Yup.date()
    .transform((value, origValue) => {
      const isEmptyString =
        dayjs(origValue).toDate().toDateString() ===
        dayjs('3333-12-31').toDate().toDateString()
      return origValue && typeof origValue === 'string'
        ? dayjs(
            `${origValue.split('.')[2]}-${origValue.split('.')[1]}-${origValue.split('.')[0]}`
          ).toDate()
        : origValue
          ? dayjs(origValue).toDate()
          : new Date()
    })
    .typeError('Формат даты должен быть DD.MM.YYYY'),
})

interface IUserDataProps {
  setLoader: Dispatch<SetStateAction<boolean>>
}

const UserData: FC<IUserDataProps> = ({ setLoader }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const isNewUser = id === 'new'

  const { data: userData, isError } = useGetUserProfileQuery(id ?? '0', {
    skip: isNewUser,
    refetchOnMountOrArgChange: true,
  })
  const {
    data: currentUserData,
    refetch: refetchCurrentUser,
    isLoading: isLoadingCurrentUser,
  } = useGetUserProfileQuery('0')

  const { data: organisationsData } = useGetOrganisationsQuery('', {
    skip:
      (isLoadingCurrentUser ||
        (currentUserData &&
          (currentUserData?.data.typeId === UserTypes.user ||
            currentUserData?.data.typeId === UserTypes.admin))) ??
      false,
    refetchOnMountOrArgChange: true,
  })

  const [changeStatusService, { isLoading: isLoadingChangeStatus }] =
    useChangeUserStatusMutation()
  const [updateUserProfileService, { isLoading: isLoadingUpdateUser }] =
    useUpdateUserProfileMutation()
  const [deleteUserAvatarService] = useDeleteUserAvatarMutation()

  const [openDialogAvatar, setOpenDialogAvatar] = useState(false)
  const [openDialogAvatarDelete, setOpenDialogAvatarDelete] = useState(false)
  const [openDialogDeleteUser, setOpenDialogDeleteUser] = useState(false)
  const [openDialogActivateUser, setOpenDialogActivateUser] = useState(false)
  const [openDialogSaveUser, setOpenDialogSaveUser] = useState(false)
  const [openDialogChangePass, setOpenDialogChangePass] = useState(false)

  const [zoom, setZoom] = useState(1)
  const [img, setImg] = useState<string | File>('')
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [editorImg, setEditorImg] = useState<AvatarEditor | null>(null)
  const [canvas, setCanvas] = useState<string | undefined>(undefined)

  const isCurrentUser = Number(id) === currentUserData?.data?.id
  const currentUserType = currentUserData?.data.typeId

  const defaultValues: IUserData = {
    firstName: '',
    surName: '',
    patronymic: '',
    email: '',
    phoneNumber: '',
    position: '',
    organizationId: '',
    birthDate: '',
    typeId: '',
    emailNotification: false,
    webPushNotification: false,
  }

  const {
    handleSubmit,
    control,
    reset,
    trigger,
    formState,
    watch,
    setError,
    getValues,
    setValue,
  } = useForm<IUserData>({
    defaultValues,
    resolver: yupResolver(validationSchema),
    criteriaMode: 'all',
    reValidateMode: 'onSubmit',
  })

  useEffect(() => {
    if (userData?.success === false) {
      navigate(-1)
    }
    if (isNewUser) {
      reset({
        firstName: '',
        surName: '',
        patronymic: '',
        position: '',
        organizationId:
          isNewUser && currentUserData?.data?.typeId === UserTypes.admin
            ? currentUserData?.data?.organizationId
            : 0,
        email: '',
        phoneNumber: '',
        birthDate: '',
        emailNotification: false,
        webPushNotification: false,
        typeId: 3,
      })
    } else {
      reset({
        firstName: userData?.data?.firstName,
        surName: userData?.data?.surName,
        patronymic: userData?.data?.patronymic,
        position: userData?.data?.position,
        organizationId: userData?.data?.organizationId,
        email: userData?.data?.email,
        phoneNumber: userData?.data?.phoneNumber,
        birthDate: userData?.data?.birthDate,
        typeId: userData?.data?.typeId,
        emailNotification: userData?.data?.emailNotification,
        webPushNotification: userData?.data?.webPushNotification,
      })
    }
  }, [id, userData, currentUserData])

  const onChangeUserStatus = async (status: boolean) => {
    try {
      setOpenDialogDeleteUser(false)
      setOpenDialogActivateUser(false)
      setLoader(true)
      const res = await changeStatusService({
        userId: Number(id),
        status,
      }).unwrap()
      if (res.success) {
        status
          ? notifySuccess('Учетная запись активирована')
          : notifySuccess('Учетная запись деактивирована')
      }
      setLoader(false)
    } catch (e) {
      console.log(e)
    }
  }

  const onDeleteUserAvatar = async () => {
    try {
      setOpenDialogAvatarDelete(false)
      setLoader(true)
      const res = await deleteUserAvatarService({ id: Number(id) }).unwrap()
      if (res.success) {
        notifySuccess('Аватар удален')
        refetchCurrentUser()
      }
      setLoader(false)
    } catch (e) {
      console.log(e)
    }
  }

  const onSubmit = async (data: IUserData) => {
    setLoader(true)
    data.birthDate =
      String(data.birthDate) === String(new Date())
        ? ''
        : dayjs(data.birthDate).format('DD.MM.YYYY')
    data.phoneNumber =
      data.phoneNumber === '00000000000' ? '' : data.phoneNumber
    const userId = isNewUser ? '0' : (userData?.data?.id.toString() ?? id ?? '')
    const file = canvas
      ? dataURItoBlob(canvas, data.firstName)
      : userData?.data?.avatar
    data.organizationId =
      isNewUser && currentUserData?.data?.typeId === UserTypes.admin
        ? currentUserData?.data?.organizationId
        : data.organizationId
    const dataFormData = new FormData()
    dataFormData.append('avatar', file ?? '')
    dataFormData.append('firstName', data.firstName)
    dataFormData.append('emailNotification', data.emailNotification.toString())
    dataFormData.append(
      'webPushNotification',
      data.webPushNotification.toString()
    )
    dataFormData.append('surName', data.surName)
    dataFormData.append('patronymic', data.patronymic)
    dataFormData.append('position', data.position)
    dataFormData.append('organizationId', data.organizationId.toString())
    dataFormData.append('email', data.email)
    dataFormData.append('phoneNumber', data.phoneNumber)
    dataFormData.append('birthDate', data.birthDate.toString())
    dataFormData.append('typeId', data.typeId.toString())
    dataFormData.append('id', userId)
    try {
      setOpenDialogSaveUser(false)
      const res = await updateUserProfileService({
        id: isNewUser ? 0 : (userData?.data?.id ?? Number(id)),
        data: dataFormData,
      }).unwrap()
      if (res.success) {
        isNewUser
          ? notifySuccess('Пользователь создан')
          : notifySuccess('Пользователь обновлен')
        resetAvatarEditor()
        if (isCurrentUser) {
          refetchCurrentUser()
        }
        if (isNewUser) {
          navigate(`/users/${res?.id}`)
        }
      }
      setLoader(false)
    } catch (e) {
      setLoader(false)
      console.log(e)
    } finally {
      setLoader(false)
    }
  }

  const resetAvatarEditor = () => {
    const avatarInput = document.getElementById('avatar') as HTMLInputElement
    if (avatarInput) {
      avatarInput.value = ''
    }
    setImg('')
    setZoom(1)
    setEditorImg(null)
    setPosition({ x: 0, y: 0 })
    setCanvas(undefined)
  }

  const onSaveBtnClick = (e: React.MouseEvent) => {
    //e.preventDefault()
    if (
      getValues('organizationId') === 0 &&
      getValues('typeId') === UserTypes.admin
    ) {
      return setError('organizationId', {
        type: 'onChange',
        message: 'Необходимо назначить организацию',
      })
    }
    if (isNewUser) {
      trigger('email').then(() => {
        //console.log(formState.errors)
      })
    }
    trigger().then(() => {
      if (
        !formState.errors.organizationId &&
        !formState.errors.birthDate &&
        !formState.errors.email &&
        !formState.errors.phoneNumber &&
        !formState.errors.position &&
        !formState.errors.patronymic &&
        !formState.errors.surName &&
        !formState.errors.firstName
      ) {
        setOpenDialogSaveUser(true)
      }
    })
  }

  useChangeSet(() => {
    if (watch('organizationId')) {
      trigger('organizationId')
    }
  }, [watch('organizationId')])

  useChangeSet(() => {
    if (watch('email').length) {
      trigger('email')
    }
  }, [watch('email')])

  useChangeSet(() => {
    if (watch('birthDate') === null) {
      trigger('birthDate')
    }
    if (watch('birthDate')) {
      trigger('birthDate')
    }
  }, [watch('birthDate')])

  useChangeSet(() => {
    const isEmpty = watch('phoneNumber') === '+_(___)___-__-__'
    if (isEmpty) {
      trigger('phoneNumber')
    }
    if (stringToNumber(watch('phoneNumber')).length >= 1) {
      trigger('phoneNumber')
    }
  }, [watch('phoneNumber')])

  useChangeSet(() => {
    if (canvas) {
      setValue('firstName', `${getValues('firstName')} `, { shouldDirty: true })
    }
  }, [canvas])

  useChangeSet(() => {
    if (watch('surName').length) {
      trigger('surName')
    }
    if (watch('firstName').length) {
      trigger('firstName')
    }
    if (watch('patronymic').length) {
      trigger('patronymic')
    }
  }, [watch('surName'), watch('firstName'), watch('patronymic')])

  useChangeSet(() => {
    if (watch('position').length) {
      trigger('position')
    }
  }, [watch('position')])

  return (
    <Box
      position='relative'
      padding={{ xss: 0, md: 2 }}
      sx={{ width: '100%', mt: 3, pb: 2 }}
    >
      <AvatarDialog
        open={openDialogAvatar}
        handleClose={() => setOpenDialogAvatar(false)}
        zoom={zoom}
        setZoom={setZoom}
        img={img}
        setImg={setImg}
        setEditorImg={setEditorImg}
        setCanvas={setCanvas}
        editorImg={editorImg}
        position={position}
        setPosition={setPosition}
        resetAvatarEditor={resetAvatarEditor}
        canvas={canvas}
      />
      <ChangePassDialog
        userId={userData?.data?.id ?? 0}
        open={openDialogChangePass}
        setLoader={setLoader}
        handleClose={() => setOpenDialogChangePass(false)}
      />
      <Dialog
        message='Вы действительно хотите удалить?'
        open={openDialogAvatarDelete}
        handleClose={() => setOpenDialogAvatarDelete(false)}
        handleAgree={onDeleteUserAvatar}
      />
      <Dialog
        message='Изменения будут сохранены, продолжить?'
        open={openDialogSaveUser}
        handleClose={() => setOpenDialogSaveUser(false)}
        handleAgree={() => {
          if (Object.keys(formState.errors).length >= 1) {
            notifyError('Исправте ошибки в форме')
          }
          handleSubmit(onSubmit)()
        }}
      />
      <Dialog
        message='Данная учётная запись будет деактивирована. Продолжить?'
        open={openDialogDeleteUser}
        handleClose={() => setOpenDialogDeleteUser(false)}
        handleAgree={() => onChangeUserStatus(false)}
      />
      <Dialog
        message='Вы действительно хотите активировать пользователя?'
        open={openDialogActivateUser}
        handleClose={() => setOpenDialogActivateUser(false)}
        handleAgree={() => onChangeUserStatus(true)}
      />
      <form onSubmit={(e) => e.preventDefault()}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3, xl: 5, xxl: 6 }}
        >
          <Grid
            item
            xss={12}
            lg={6}
          >
            <Box
              width='100%'
              display='flex'
              alignItems='center'
              justifyContent={{ xss: 'center', md: 'flex-start' }}
              gap={5}
              p={{ xss: 1, md: 4 }}
            >
              <Avatar
                src={canvas ?? userData?.data?.avatarUrl}
                className={s('avatar')}
              />
              <Stack
                direction='column'
                alignItems='flex-start'
                gap='3px'
              >
                <Link
                  component='button'
                  variant='body2'
                  onClick={(e) => {
                    e.preventDefault()
                    setOpenDialogAvatar(true)
                  }}
                >
                  Загрузить
                </Link>
                {userData?.data?.avatarUrl ? (
                  <Link
                    component='button'
                    variant='body2'
                    color='#d32f2f'
                    onClick={(e) => {
                      e.preventDefault()
                      setOpenDialogAvatarDelete(true)
                    }}
                  >
                    Удалить
                  </Link>
                ) : null}
              </Stack>
            </Box>
          </Grid>
          <Grid
            item
            xss={12}
            lg={6}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Grid
              container
              rowSpacing={2}
              columnSpacing={2}
              justifyContent='flex-end'
            >
              <Grid
                item
                xss={12}
                lg={6}
              >
                <Stack
                  direction='column'
                  alignItems='flex-start'
                  gap={2}
                >
                  <UserSelect
                    name='typeId'
                    control={control}
                    label='Группа'
                    items={
                      currentUserType !== UserTypes.superAdmin
                        ? groups.filter((item) => item.id !== 1)
                        : groups
                    }
                    isGroup
                    disabled={
                      (isCurrentUser && userData?.data?.typeId === 1) ||
                      isLoadingUpdateUser ||
                      currentUserType === UserTypes.user
                    }
                  />
                  {isNewUser ? null : (
                    <Link
                      component='button'
                      variant='body2'
                      onClick={(e) => {
                        e.preventDefault()
                        setOpenDialogChangePass(true)
                      }}
                    >
                      Сменить пароль
                    </Link>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xss={12}
            lg={6}
          >
            <Stack
              direction='column'
              gap={2}
            >
              <Input
                name='surName'
                type='text'
                label='Фамилия'
                control={control}
                disabled={isLoadingUpdateUser}
              />
              <Input
                name='firstName'
                type='text'
                label='Имя'
                control={control}
                disabled={isLoadingUpdateUser}
              />
              <Input
                name='patronymic'
                type='text'
                label='Отчество'
                control={control}
                disabled={isLoadingUpdateUser}
              />
              <DatePickers
                control={control}
                name='birthDate'
                label='Дата рождения'
                disableFuture
                disabled={isLoadingUpdateUser}
              />
              <Controller
                name='emailNotification'
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    labelPlacement='start'
                    sx={{ justifyContent: 'flex-end' }}
                    control={
                      <Switch
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    }
                    label='Присылать EMAIL уведомления'
                  />
                )}
              />
              <Stack
                direction='row'
                gap={2}
              >
                <Controller
                  name='webPushNotification'
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      labelPlacement='start'
                      sx={{ justifyContent: 'flex-end' }}
                      control={
                        <Switch
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      }
                      label='Присылать PUSH уведомления'
                    />
                  )}
                />
                <NotificationComponent
                  subscriptionsArr={userData?.data?.webPushSubscriptions ?? []}
                  isSend={watch('webPushNotification')}
                />
              </Stack>
            </Stack>
          </Grid>

          <Grid
            item
            xss={12}
            lg={6}
          >
            <Stack
              direction='column'
              gap={2}
            >
              <Input
                name='position'
                type='text'
                label='Должность'
                control={control}
                disabled={isLoadingUpdateUser}
              />
              <UserSelect
                name='organizationId'
                control={control}
                label='Организация'
                items={organisationsData}
                organization={
                  isNewUser && currentUserData?.data?.typeId === UserTypes.admin
                    ? currentUserData?.data?.organization
                    : userData?.data?.organization
                }
                organizationId={
                  isNewUser && currentUserData?.data?.typeId === UserTypes.admin
                    ? currentUserData?.data?.organizationId
                    : userData?.data?.organizationId
                }
                disabled={
                  isLoadingUpdateUser ||
                  currentUserType === UserTypes.user ||
                  currentUserType === UserTypes.admin
                }
              />
              <Input
                name='email'
                type='email'
                label='Логин'
                control={control}
                disabled={!isNewUser || isLoadingUpdateUser}
              />
              <PhoneInput
                name='phoneNumber'
                control={control}
                label='Телефон'
                disabled={isLoadingUpdateUser}
              />
            </Stack>
          </Grid>
        </Grid>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
          gap={2}
          mt={1}
        >
          {isNewUser ||
          userData?.data?.typeId === UserTypes.superAdmin ? null : (
            <>
              {userData?.data?.deleted ? (
                <LoadingButton
                  loading={isLoadingChangeStatus || isLoadingUpdateUser}
                  variant='outlined'
                  color='error'
                  onClick={(e) => {
                    e.preventDefault()
                    setOpenDialogActivateUser(true)
                  }}
                >
                  <span>Активировать</span>
                </LoadingButton>
              ) : (
                <LoadingButton
                  loading={isLoadingChangeStatus || isLoadingUpdateUser}
                  variant='outlined'
                  color='error'
                  onClick={(e) => {
                    e.preventDefault()
                    setOpenDialogDeleteUser(true)
                  }}
                >
                  <span>Удалить</span>
                </LoadingButton>
              )}
            </>
          )}
          {isNewUser &&
          currentUserData?.data?.typeId === UserTypes.admin &&
          !currentUserData?.data?.organizationId ? null : (
            <LoadingButton
              loading={isLoadingChangeStatus || isLoadingUpdateUser}
              variant='outlined'
              onClick={onSaveBtnClick}
              disabled={!formState.isDirty}
            >
              <span>Сохранить</span>
            </LoadingButton>
          )}
        </Box>
      </form>
    </Box>
  )
}

export default UserData
