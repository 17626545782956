import { api } from '@/api'
import { TypeUnit, UnitSearchReq, UnitSearchRes } from '../types/newUnitsType'
import _ from 'lodash'
import { setTypeUnitsTanks } from '@/store/filterTanksSlise'

const newUnitsListService = api.injectEndpoints({
  endpoints: (build) => ({
    getAquaUnitsList: build.mutation<UnitSearchRes, UnitSearchReq>({
      query: (body) => ({
        url: '/aquaDevices/search',
        method: 'POST',
        body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const types = _.cloneDeep(data.types)
          types.unshift({ id: 0, name: 'Все' })
          dispatch(setTypeUnitsTanks(types))
        } catch (e) {
          console.log(e)
        }
      },
    }),
    getTypesUnitsAquaForUser: build.query<
      TypeUnit[],
      void | { isNew?: boolean }
    >({
      query: () => ({
        url: '/aquaDevices/getUserDeviceTypes',
        method: 'GET',
      }),
      transformResponse: (
        res: { success: boolean; data: TypeUnit[] },
        meta,
        arg
      ) => {
        if (res.success && !arg?.isNew) {
          res.data.unshift({ id: 0, name: 'Все' })
        }
        return res.data
      },
    }),
  }),
  overrideExisting: false,
})

export const { useGetAquaUnitsListMutation, useGetTypesUnitsAquaForUserQuery } =
  newUnitsListService
