import { useAppSelector } from '@/hooks'
import { messagesSelector } from '@/modules/sidebar/store/messagesSlice'
import { List } from '@mui/material'
import Paper from '@mui/material/Paper'
import React, { FC } from 'react'
import NotificationsItem from './NotificationsItem'

interface Props {}

const NotificationsList: FC<Props> = () => {
  const { messages } = useAppSelector(messagesSelector)

  return (
    <Paper
      elevation={3}
      sx={{ p: 1, m: 1, overflow: 'hidden', height: { xss: '50%', md: 300 } }}
    >
      <List sx={{ overflow: 'auto', height: '100%' }}>
        {messages && messages.length > 0
          ? messages.map((item, index) => (
              <NotificationsItem
                key={index}
                message={item}
              />
            ))
          : null}
      </List>
    </Paper>
  )
}

export default NotificationsList
