import { Dialog } from '@/components'
import { useActions, useAppSelector } from '@/hooks'
import { useLogoutMutation } from '@/modules/login/services/login'
import Notifications from '@/modules/sidebar/components/Notifications/Notifications'
import { useGetUserProfileQuery } from '@/modules/user/services/userService'
import { isChangeModulesSelector } from '@/store/changeDialogSlise'
import cn from '@/utils/cn'
import LogoutIcon from '@mui/icons-material/Logout'
import PersonIcon from '@mui/icons-material/Person'
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const s = cn('sidebar')

interface IUserAvatar {
  setIsShowSidebar: Dispatch<SetStateAction<boolean>>
}

export const UserAvatar: FC<IUserAvatar> = ({ setIsShowSidebar }) => {
  const {
    isChangeModules,
    isChangeDescription,
    isChangeFeatures,
    isChangeUser,
    isChangeOrganization,
  } = useAppSelector(isChangeModulesSelector)
  const {
    setIsOpenChangeModulesDialog,
    setIsOpenChangeOrganizationDialog,
    setIsOpenChangeUserDialog,
    setIsOpenChangeDescriptionDialog,
    setIsOpenChangeFeaturesDialog,
    setFavoriteId,
  } = useActions()

  const { data, isLoading: isLoadingUserData } = useGetUserProfileQuery('0')

  const [openLogoutDialog, setOpenLogOutDialog] = useState(false)

  const { setUser } = useActions()

  const [onLogout] = useLogoutMutation()
  const navigate = useNavigate()

  const logout = async () => {
    await onLogout()
    setOpenLogOutDialog(false)
    localStorage.clear()
    //setUser(null)
    window.location.replace('/login')
  }

  const avatarNavigate = () => {
    if (isChangeModules) {
      setIsOpenChangeModulesDialog(true)
      return
    }
    if (isChangeDescription) {
      setIsOpenChangeDescriptionDialog(true)
      return
    }
    if (isChangeFeatures) {
      setIsOpenChangeFeaturesDialog(true)
      return
    }
    if (isChangeUser) {
      setIsOpenChangeUserDialog(true)
      return
    }
    if (isChangeOrganization) {
      setIsOpenChangeOrganizationDialog(true)
      return
    }
    navigate(`/users/${data?.data?.id}`)
    setFavoriteId(0)
  }

  const logoutNavigate = () => {
    if (isChangeModules) {
      setIsOpenChangeModulesDialog(true)
      return
    }
    if (isChangeDescription) {
      setIsOpenChangeDescriptionDialog(true)
      return
    }
    if (isChangeFeatures) {
      setIsOpenChangeFeaturesDialog(true)
      return
    }
    if (isChangeUser) {
      setIsOpenChangeUserDialog(true)
      return
    }
    if (isChangeOrganization) {
      setIsOpenChangeOrganizationDialog(true)
      return
    }
    setOpenLogOutDialog(true)
  }

  const linkNavigate = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setIsShowSidebar(false)
    if (isChangeModules) {
      setIsOpenChangeModulesDialog(true)
      return
    }
    if (isChangeDescription) {
      setIsOpenChangeDescriptionDialog(true)
      return
    }
    if (isChangeFeatures) {
      setIsOpenChangeFeaturesDialog(true)
      return
    }
    if (isChangeUser) {
      setIsOpenChangeUserDialog(true)
      return
    }
    if (isChangeOrganization) {
      setIsOpenChangeOrganizationDialog(true)
      return
    }
    navigate(`/users/${data?.data?.id}`)
    setFavoriteId(0)
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
    >
      <Dialog
        open={openLogoutDialog}
        handleClose={() => setOpenLogOutDialog(false)}
        handleAgree={logout}
        message='Вы действительно желаете выйти?'
      />
      <Typography
        variant='h6'
        style={{ color: 'white' }}
      >
        {import.meta.env.VITE_APP_NAME}
      </Typography>
      <Box mt={2} />
      <Box position='relative'>
        <Notifications />
        <Avatar
          src={data?.data?.avatarUrl}
          className={s('avatar')}
          onClick={avatarNavigate}
        >
          <PersonIcon fontSize='inherit' />
        </Avatar>
        <IconButton
          className={s('logout')}
          onClick={logoutNavigate}
        >
          <LogoutIcon />
        </IconButton>
      </Box>
      <Box mt={1} />
      <Stack
        direction='row'
        alignItems='center'
        gap={2}
      >
        {isLoadingUserData ? (
          <CircularProgress size={30} />
        ) : (
          <Link
            onClick={linkNavigate}
            to={`/users/${data?.data?.id}`}
            className={s('link')}
          >
            <Typography variant='subtitle1'>{`${
              data?.data?.firstName ?? ''
            } ${data?.data?.surName ?? ''}`}</Typography>
          </Link>
        )}
      </Stack>
    </Box>
  )
}
