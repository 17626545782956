import {
  DeviceDetail,
  DeviceModule,
  DeviceModuleMeteo,
} from '@/modules/device-module/types/deviceType'
import { TerminalUnit } from '@/modules/device-module/types/terminal'
import { RootState } from '@/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

export interface TableItem {
  id: number
  name: string
}

export interface StateDevice {
  isOnChange: boolean
  selected: number[]
  selectedMeteo: number
  selectedDevice: boolean
  disabledSaveBtn: boolean
  selectedFilters: number[]
  selectedTab: number
  device: DeviceDetail | null
  fromDate: dayjs.Dayjs
  fromDateEvents: dayjs.Dayjs
  toDate: dayjs.Dayjs
  deleted: number[]
  commands: TerminalUnit[]
  selectedModulesId: number
}

const initialState: StateDevice = {
  isOnChange: false,
  selected: [],
  selectedMeteo: 0,
  disabledSaveBtn: false,
  selectedFilters: [0],
  selectedTab: 0,
  device: null,
  fromDate: dayjs(new Date().setHours(0, 0, 0, 0)).subtract(1, 'day'),
  fromDateEvents: dayjs(new Date().setHours(0, 0, 0, 0)).subtract(1, 'year'),
  toDate: dayjs(new Date().setHours(23, 59, 0, 0)),
  selectedDevice: true,
  deleted: [],
  commands: [],
  selectedModulesId: 0,
}

export const stateDeviceSlice = createSlice({
  name: 'stateDevice',
  initialState,
  reducers: {
    toggleOnChangeDevice: (state) => {
      state.isOnChange = !state.isOnChange
    },
    setSelected: (state, action: PayloadAction<number[]>) => {
      state.selected = action.payload
    },
    setSelectedMeteo: (state, action: PayloadAction<number>) => {
      state.selectedMeteo = action.payload
    },
    setSelectedModulesId: (state, action: PayloadAction<number>) => {
      state.selectedModulesId = action.payload
    },
    setDisabledSaveBtn: (state, action: PayloadAction<boolean>) => {
      state.disabledSaveBtn = action.payload
    },
    setFilters: (state, action: PayloadAction<number[]>) => {
      state.selectedFilters = action.payload
    },
    setSelectedTab: (state, action: PayloadAction<number>) => {
      state.selectedTab = action.payload
    },
    removeItemDevice: (state, action: PayloadAction<number>) => {
      state.device?.modules.splice(action.payload, 1)
      // state.favorites.forEach((item, index) => {
      //   item.displaySequence = index + 1
      // })
    },
    removeItemDeviceMeteo: (state, action: PayloadAction<number>) => {
      state.device?.modulesMeteo?.forEach((item, index) => {
        if (index === action.payload) {
          item.deleted = true
        }
      })
    },
    setDevice: (state, action: PayloadAction<DeviceDetail>) => {
      state.device = action.payload
    },
    updateDevice: (state, action: PayloadAction<DeviceDetail>) => {
      const { modules, modulesMeteo, ...updatedFields } = action.payload
      state.device = {
        ...state.device,
        ...updatedFields,
        modulesMeteo: state.device?.modulesMeteo?.map((item, index) => {
          return {
            ...item,
            ...(modulesMeteo && modulesMeteo[index]),
            name: item.name,
            displaySequence: item.displaySequence,
          }
        }),
        modules:
          state?.device?.modules?.map((module, index) => {
            return {
              ...module,
              ...modules[index],
              name: module.name,
              displaySequence: module.displaySequence,
            }
          }) ?? [],
      }
    },
    setDeviceModules: (state, action: PayloadAction<DeviceModule[]>) => {
      if (state.device) {
        state.device.modules = action.payload
      }
    },
    setDeviceModulesMeteo: (
      state,
      action: PayloadAction<DeviceModuleMeteo[]>
    ) => {
      if (state.device) {
        state.device.modulesMeteo = action.payload
      }
    },
    setFromDate: (state, action: PayloadAction<dayjs.Dayjs>) => {
      state.fromDate = action.payload
    },
    setFromDateEvents: (state, action: PayloadAction<dayjs.Dayjs>) => {
      state.fromDateEvents = action.payload
    },
    setToDate: (state, action: PayloadAction<dayjs.Dayjs>) => {
      state.toDate = action.payload
    },
    setSelectedDevice: (state, action: PayloadAction<boolean>) => {
      state.selectedDevice = action.payload
    },
    setAddDeleted: (state, action: PayloadAction<number>) => {
      state.deleted.push(action.payload)
    },
    setClearDeleted: (state) => {
      state.deleted = []
    },
    setCommands: (state, action: PayloadAction<TerminalUnit[]>) => {
      state.commands = action.payload
    },
    removeCommand: (state, action: PayloadAction<number>) => {
      state.commands.splice(action.payload, 1)
    },
  },
})

export const {
  toggleOnChangeDevice,
  setSelected,
  removeItemDevice,
  setDisabledSaveBtn,
  setFilters,
  setSelectedTab,
  setDevice,
  setDeviceModules,
  setToDate,
  setFromDate,
  setSelectedDevice,
  setAddDeleted,
  setClearDeleted,
  updateDevice,
  setSelectedMeteo,
  setDeviceModulesMeteo,
  removeItemDeviceMeteo,
  setCommands,
  removeCommand,
  setSelectedModulesId,
  setFromDateEvents,
} = stateDeviceSlice.actions

export const stateDeviceSelector = (state: RootState) => state.stateDevice
export const stateDeviceSelectorFromDate = (state: RootState) =>
  state.stateDevice.fromDate
export const stateDeviceSelectorFromDateEvents = (state: RootState) =>
  state.stateDevice.fromDateEvents
export const stateDeviceSelectorToDate = (state: RootState) =>
  state.stateDevice.toDate
export const stateDeviceSelectorSelected = (state: RootState) =>
  state.stateDevice.selected
export const stateDeviceSelectorSelectedMeteo = (state: RootState) =>
  state.stateDevice.selectedMeteo
export const stateDeviceSelectorSelectedForChat = (state: RootState) =>
  state.stateDevice.selectedDevice
export const modulesSelector = (state: RootState) =>
  state.stateDevice.device?.modules
export const commandsSelector = (state: RootState) => state.stateDevice.commands

export default stateDeviceSlice.reducer
