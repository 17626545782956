import Shell from '@/modules/login/layout/Shell'
import { Box, Checkbox, FormControlLabel } from '@mui/material'
import React, { useEffect } from 'react'
import cn from '@/utils/cn'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useLocation, useNavigate } from 'react-router-dom'
import Input from '@/modules/login/components/Input/Input'
import Button from '@/modules/login/components/Button/Button'
import { useLoginMutation } from '@/modules/login/services/login'
import { ILoginField } from '@/modules/login/types'
import { useActions } from '@/hooks'

const s = cn('loginModule')

const validationSchema = Yup.object().shape({
  login: Yup.string()
    .required('Email не может быть пустым')
    .email('Некорректный E-mail')
    .min(2, 'Длина Email должна быть менее 2-ч символов')
    .max(50, 'Длина Email должна быть не более 50-ти символов'),
  password: Yup.string()
    .required('Пароль не может быть пустым')
    .min(6, 'Введите пароль от 6-ти до 20-ти символов')
    .max(20, 'Введите пароль от 6-ти до 20-ти символов'),
})

const LoginModule = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const from =
    location.state?.from?.pathname || localStorage.getItem('pathname') || '/'
  const [loginService, { isLoading }] = useLoginMutation()
  const { setIsLogin } = useActions()

  const defaultValues: ILoginField = {
    isRemember: false,
    login: '',
    password: '',
  }

  const { handleSubmit, control, reset } = useForm<ILoginField>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })
  const onSubmit = async (data: ILoginField) => {
    try {
      const res = await loginService(data).unwrap()
      if (res.success) {
        setIsLogin(true)
        localStorage.removeItem('pathname')
        navigate(from, { replace: true })
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    reset(defaultValues)
  }, [])

  return (
    <Shell isLoading={isLoading}>
      <form
        className={s('form')}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          display='flex'
          flexDirection='column'
          width='100%'
          mt={{ xss: 2, xs: 3, md: 4 }}
        >
          <Input
            control={control}
            name='login'
            label='Введите почту*'
          />
          <Box mt={{ xss: 2, xxl: 4 }} />
          <Input
            control={control}
            name='password'
            type='password'
            label='Введите пароль*'
          />
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            flexDirection={{ xss: 'column', xs: 'row' }}
            mt={{ xss: 2, xs: 1, xxl: 2 }}
          >
            <Button
              onClick={() => navigate('/recovery-password')}
              label='Забыли пароль?'
              isText
            />
            <Controller
              name='isRemember'
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  value='start'
                  className={s('checkbox')}
                  control={
                    <Checkbox
                      size='small'
                      {...field}
                    />
                  }
                  label='Оставаться в системе'
                />
              )}
            />
          </Box>
          <Box
            display='flex'
            justifyContent='space-between'
            mt={{ xss: 2, md: 4 }}
            alignItems='center'
          >
            <Button
              onClick={() => navigate('/register')}
              label='Регистрация'
            />
            <Button
              type='submit'
              variant='outlined'
              //loading={isLoading}
              label='Вход'
            />
          </Box>
        </Box>
      </form>
    </Shell>
  )
}

export default LoginModule
