import {
  FormControl,
  FormControlProps,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  useMediaQuery,
} from '@mui/material'
import React, { FC } from 'react'
import cn from '@/utils/cn'

const s = cn('select-custom')

type Item = {
  name: string
  id: number | string
}

type ISelect = {
  items: Item[] | undefined
}

type SelectPropsUser = ISelect & SelectProps & FormControlProps

const CustomSelect: FC<SelectPropsUser> = ({
  label,
  items = [],
  disabled,
  ...props
}) => {
  const is4K = useMediaQuery('(min-width:2560px)')

  return (
    <FormControl
      variant='filled'
      fullWidth
      size={is4K ? undefined : 'small'}
      margin='none'
      className={s()}
      disabled={disabled}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        disableUnderline
        {...props}
      >
        {items?.map((item) => (
          <MenuItem
            key={item?.id}
            value={item?.id}
          >
            {item?.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default CustomSelect
