import { api } from '@/api'
import { DetailUnit, UnitsMapTypeRes } from '@/modules/maps/types/unitsMapType'
import { UnitSearchReq } from '@/modules/devices-list/types/newUnitsType'
import _ from 'lodash'
import { setTypeUnits } from '@/store/filterSystemsSlise'
import { setUnits, updateUnits } from '@/store/unitsSlise'

const homeMapService = api.injectEndpoints({
  endpoints: (build) => ({
    getUnitsHomeMap: build.mutation<
      UnitsMapTypeRes,
      Omit<UnitSearchReq, 'page'>
    >({
      query: (body) => ({
        url: '/devices/searchForMap',
        method: 'POST',
        body,
      }),
      transformResponse: (res: UnitsMapTypeRes) => {
        if (res.success) {
          res.data.data.map((unit) => {
            unit.coordinates = JSON.parse(unit.coordinates as any)
            unit.isGroup = false
          })
        }
        return res
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const types = _.cloneDeep(data.data.types)
          types.unshift({ id: 0, name: 'Все' })
          dispatch(setTypeUnits(types))
          dispatch(setUnits(data))
        } catch (e) {
          console.log(e)
        }
      },
    }),
    getDetailUnit: build.mutation<DetailUnit, number>({
      query: (id) => ({
        url: '/devices/getDetailsForMap',
        method: 'GET',
        params: { id },
      }),
      transformResponse: (res: { success: boolean; data: DetailUnit }) => {
        res.data.address = res.data.address
          ? res.data.address.replaceAll(/\n/g, '<br/>')
          : '---'
        return res.data
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(updateUnits(data))
        } catch (error) {
          console.log(error)
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const { useGetUnitsHomeMapMutation, useGetDetailUnitMutation } =
  homeMapService
