import React, { useEffect, useState } from 'react'
import cn from '@/utils/cn'
import { Box, CircularProgress } from '@mui/material'
import UnitData from './components/UnitData/UnitData'
import { useNavigate } from 'react-router-dom'
import { useGetUserProfileQuery } from '@/modules/user/services/userService'
import { UserTypes } from '@/types/typeUser'

const s = cn('add-new-tsodd')
const AddNewTSODD = () => {
  const navigate = useNavigate()

  const [loader, setLoader] = useState(false)

  const { data: currentUserData } = useGetUserProfileQuery('0')
  const currentUserType = currentUserData?.data.typeId

  useEffect(() => {
    if (
      currentUserType === UserTypes.user ||
      currentUserType === UserTypes.admin
    ) {
      navigate(-1)
    }
  }, [])

  return (
    <>
      <CircularProgress
        className={loader ? s('show-loader') : s('hide-loader')}
      />
      <Box
        className={s({ loading: loader })}
        height='100%'
      >
        <UnitData setLoader={setLoader} />
      </Box>
    </>
  )
}

export default AddNewTSODD
