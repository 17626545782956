import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  useMediaQuery,
} from '@mui/material'
import React, { FC, useState } from 'react'
import { useController, UseControllerProps } from 'react-hook-form'
import cn from '@/utils/cn'
import { Visibility, VisibilityOff } from '@mui/icons-material'

const s = cn('input-organization')

type IInput = {}

type InputProps = TextFieldProps & IInput & UseControllerProps<any>

const Input: FC<InputProps> = ({ name, control, ...props }) => {
  const [showPassword, setShowPassword] = useState(false)

  const is4K = useMediaQuery('(min-width:2560px)')

  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields, errors },
  } = useController({
    name,
    control,
  })

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  return (
    <TextField
      {...field}
      {...props}
      value={field.value ?? ''}
      color='primary'
      className={s()}
      autoComplete='new-password'
      variant='filled'
      size={is4K ? undefined : 'small'}
      margin='none'
      fullWidth
      type={
        props.type === 'password'
          ? showPassword
            ? 'text'
            : 'password'
          : 'text'
      }
      error={!!errors[name]}
      helperText={errors[name]?.message as string}
      onChange={(e) => {
        if (props.type === 'number') {
          field.onChange(e.target.value.replace(/[^0-9]/g, ''))
        } else {
          field.onChange(e.target.value)
        }
      }}
      InputProps={{
        endAdornment:
          props.type === 'password' ? (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge='end'
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ) : null,
        disableUnderline: true,
      }}
    />
  )
}

export default Input
