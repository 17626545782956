import React, { Suspense } from 'react'
import { RouterProvider } from 'react-router-dom'
import { router } from '@/app/router'
import LoaderFullScreenBlur from '@/ui/LoaderFullScreen/LoaderFullScreenBlur'

function App() {
  return (
    <Suspense fallback={<LoaderFullScreenBlur />}>
      <RouterProvider router={router} />
    </Suspense>
  )
}

export default App
