import { api } from '@/api'
import { AddNewUnitRes } from '@/modules/add-new-video/types/newUnitType'
import { ISimpleResponse } from '@/modules/login/types'

const newTsoddService = api.injectEndpoints({
  endpoints: (build) => ({
    addNewVideo: build.mutation<ISimpleResponse, AddNewUnitRes>({
      query: (body) => ({
        url: '/devices/updateVideoCameraProperties',
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useAddNewVideoMutation } = newTsoddService
