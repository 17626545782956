import { notifySuccess } from '@/components'
import { useActions } from '@/hooks'
import { useSetTerminalCommandsMutation } from '@/modules/device-module/services/deviceService'
import { TerminalUnit } from '@/modules/device-module/types/terminal'
import cn from '@/utils/cn'
import { Box, CircularProgress } from '@mui/material'
import * as React from 'react'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const s = cn('terminal-commands')

type Props = {
  item: TerminalUnit
  setValue: Dispatch<SetStateAction<string>>
}
export const CommandItem = ({ item, setValue }: Props) => {
  const { id } = useParams()

  const [setCommandService] = useSetTerminalCommandsMutation()

  const [loading, setLoading] = useState(false)
  const [clickCount, setClickCount] = useState(0)
  const [lastClickTime, setLastClickTime] = useState(0)

  const { triggerLoadHistory } = useActions()

  const onSetCommand = async (command: string) => {
    try {
      setLoading(true)
      const res = await setCommandService({
        command,
        deviceId: Number(id),
      }).unwrap()
      triggerLoadHistory()
      if (res.success) {
        notifySuccess('Команда отправлена')
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const resetClickState = () => {
      setClickCount(0)
      setLastClickTime(0)
    }
    const timeout = setTimeout(resetClickState, 500)
    return () => clearTimeout(timeout)
  }, [clickCount])

  const handleOnClick = (text: string) => {
    const now = new Date().getTime()
    const DOUBLE_CLICK_INTERVAL = 300

    if (clickCount === 1 && now - lastClickTime <= DOUBLE_CLICK_INTERVAL) {
      onSetCommand(text).then()
    } else {
      setClickCount(1)
      setLastClickTime(now)
      setValue(text)
    }
  }

  return (
    <Box
      className={s('command')}
      key={item.id}
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      sx={{
        lineBreak: 'anywhere',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: loading
            ? 'rgba(0, 0, 0, 0.01)'
            : 'rgba(0, 0, 0, 0.1)',
        },
      }}
      onClick={() => {
        if (loading) {
          return
        }
        handleOnClick(item.text)
      }}
    >
      <span
        style={{
          color: loading ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.87)',
        }}
      >
        {item.text}
      </span>
      {loading ? <CircularProgress size={13} /> : null}
    </Box>
  )
}
