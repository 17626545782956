import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  useMediaQuery,
} from '@mui/material'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useController, UseControllerProps } from 'react-hook-form'
import cn from '@/utils/cn'
import { Visibility, VisibilityOff } from '@mui/icons-material'

const s = cn('input-login')

type IInput = {}

type InputProps = TextFieldProps & IInput & UseControllerProps<any>

const Input: FC<InputProps> = ({ name, control, ...props }) => {
  const ref = useRef<HTMLInputElement>(null)

  const [showPassword, setShowPassword] = useState(false)

  const is4K = useMediaQuery('(min-width:2560px)')

  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields, errors },
  } = useController({
    name,
    control,
  })

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  useEffect(() => {
    //ref.current.focus()
  }, [showPassword])

  const handleFocus = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const lengthOfInput = event.target.value.length
    return event.target.setSelectionRange(lengthOfInput, lengthOfInput)
  }

  return (
    <TextField
      {...field}
      {...props}
      ref={ref}
      color='primary'
      className={s()}
      autoComplete='off'
      autoFocus={false}
      variant='filled'
      size={is4K ? undefined : 'small'}
      margin='none'
      fullWidth
      onFocus={handleFocus}
      type={
        props.type === 'password'
          ? showPassword
            ? 'text'
            : 'password'
          : 'text'
      }
      error={!!errors[name]}
      helperText={errors[name]?.message as string}
      inputMode='search'
      InputProps={{
        endAdornment:
          props.type === 'password' ? (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                onMouseUp={handleMouseDownPassword}
                edge='end'
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ) : null,
        disableUnderline: true,
      }}
    />
  )
}

export default Input
