import cloudBlue from '@/assets/img/cloud-blue.png'
import cloudGray from '@/assets/img/cloud-gray.png'
import cloudRed from '@/assets/img/cloud-red.png'
import photo from '@/assets/img/icons8-camera-80 (1).png'
import video from '@/assets/img/icons8-camera-80.png'
import {
  useGetDeviceDetailQuery,
  useGetPhotosDetailQuery,
} from '@/modules/device-module/services/deviceService'
import cn from '@/utils/cn'
import { Box } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import dayjs from 'dayjs'
import { useLocation, useParams } from 'react-router-dom'

const s = cn('device-module-info-icons-camera')

const InfoIconsCamera = () => {
  const { id } = useParams()
  const { pathname } = useLocation()

  const { data } = useGetDeviceDetailQuery(Number(id), {
    skip: pathname.includes('photos'),
  })
  const { data: photosData } = useGetPhotosDetailQuery(Number(id), {
    skip: !pathname.includes('photos'),
  })

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      gap={{ xss: 0.5, lg: 2 }}
      pt={1}
      className={s()}
    >
      <Box
        display='flex'
        alignItems='center'
        gap={2}
      >
        <Box
          display='flex'
          alignItems='center'
          gap={1}
          width={{ xss: 140, lg: 160 }}
        >
          {data?.typeId === 8 ? null : (
            <>
              <Tooltip
                title={
                  data?.typeId === 7 || photosData?.typeId === 7
                    ? 'Время прихода последнего изображения'
                    : 'Время прихода последнего видео'
                }
                placement='top'
              >
                <img
                  src={
                    data?.typeId === 7 || photosData?.typeId === 7
                      ? photo
                      : video
                  }
                  height={25}
                  width={25}
                  alt='Связь'
                />
              </Tooltip>
              <span style={{ color: '#fff' }}>
                {dayjs(
                  data?.typeId === 7 || photosData?.typeId === 7
                    ? photosData?.lastPhotoDate
                    : data?.lastPhotoDate
                ).format('DD.MM.YYYY HH:mm')}
              </span>
            </>
          )}
        </Box>

        <Box
          display='flex'
          alignItems='center'
          gap={1}
          width={{ xss: 140, lg: 160 }}
        >
          <Tooltip
            title={'Состояние подключения'}
            placement='top'
          >
            <img
              src={
                data?.connectionStatus === 1 ||
                photosData?.connectionStatus === 1
                  ? cloudBlue
                  : data?.connectionStatus === 2 ||
                      photosData?.connectionStatus === 2
                    ? cloudGray
                    : data?.connectionStatus === 3 ||
                        photosData?.connectionStatus === 3
                      ? cloudRed
                      : cloudRed
              }
              height={25}
              width={25}
              alt='Связь'
            />
          </Tooltip>
          <span style={{ color: '#fff' }}>
            {data?.connectionStatus === 1 || photosData?.connectionStatus === 1
              ? 'В сети'
              : data?.connectionStatus === 2 ||
                  photosData?.connectionStatus === 2
                ? 'Не в сети'
                : data?.connectionStatus === 3 ||
                    photosData?.connectionStatus === 3
                  ? 'Нет соединения'
                  : '-'}
          </span>
        </Box>
      </Box>
    </Box>
  )
}

export default InfoIconsCamera
