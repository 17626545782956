import { api } from '@/api'
import {
  addNotification,
  setCountNotRead,
  setLastId,
  setLastMessages,
  setMessages,
} from '@/modules/sidebar/store/messagesSlice'
import {
  LastNotificationsRes,
  Message,
  Notifications,
  NotificationsRes,
} from '@/modules/sidebar/types/notifications'
import dayjs from 'dayjs'

const notificationService = api.injectEndpoints({
  endpoints: (build) => ({
    getNotifications: build.mutation<Message[], void>({
      query: (body) => ({
        url: '/events/getHistoryEvents',
        method: 'POST',
        body,
      }),
      transformResponse: (res: NotificationsRes) => {
        if (res.data) {
          res.data.forEach((item) => {
            item.createdDate = dayjs(new Date(item.createdDate)).format(
              'DD.MM.YYYY HH:mm:ss'
            )
          })
        }
        return res.data
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setMessages(data ?? []))
        } catch (e) {
          console.log(e)
        }
      },
    }),
    getLastNotifications: build.query<
      LastNotificationsRes,
      { lastEventId: number }
    >({
      query: (body) => ({
        url: '/events/getLastEvents',
        method: 'POST',
        body,
      }),
      transformResponse: (res: LastNotificationsRes) => {
        if (res.data.messages && res.data.messages.length > 0) {
          res.data.messages.forEach((item) => {
            item.createdDate = dayjs(new Date(item.createdDate)).format(
              'DD.MM.YYYY HH:mm:ss'
            )
          })
        }
        return res
      },
      async onQueryStarted({ lastEventId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          //dispatch(setLastMessages(null))
          dispatch(addNotification(data.data.messages ?? []))
          dispatch(setLastId(data.data.lastEventId ?? 0))
          dispatch(setCountNotRead(data.data.countNew ?? 0))

          if (
            lastEventId !== 0 &&
            data.data.messages &&
            data.data.messages.length > 0
          ) {
            const lastMessage =
              data.data.messages[data.data.messages.length - 1]
            dispatch(setLastMessages(lastMessage))
          }
        } catch (e) {
          console.log(e)
        }
      },
    }),
    readNotifications: build.mutation<
      Notifications,
      { deviceId: number; eventId: number }
    >({
      query: (body) => ({
        url: '/events/ReadEvent',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Notifications'],
    }),
    deletedNotifications: build.mutation<
      Notifications,
      { deviceId: number; eventId: number }
    >({
      query: (body) => ({
        url: '/events/deleteEvent',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Notifications'],
    }),

    readAllNotifications: build.mutation<Notifications, void>({
      query: (body) => ({
        url: '/events/readAllEvents',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Notifications'],
    }),
    deletedAllNotifications: build.mutation<Notifications, void>({
      query: (body) => ({
        url: '/events/deleteAllEvents',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Notifications'],
    }),

    addSubscription: build.mutation<{ success: boolean }, string>({
      query: (body) => ({
        url: '/events/addWebPushSubscription',
        method: 'POST',
        body,
      }),
    }),
    deleteWebPushSubscription: build.mutation<Notifications, string>({
      query: (body) => ({
        url: '/events/deleteWebPushSubscription',
        method: 'POST',
        body,
      }),
    }),
    viewEvent: build.mutation<
      Notifications,
      { deviceId: number; eventId: number }
    >({
      query: (body) => ({
        url: '/events/ViewDeviceEvent',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Notifications'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetNotificationsMutation,
  useReadNotificationsMutation,
  useDeletedNotificationsMutation,
  useDeletedAllNotificationsMutation,
  useReadAllNotificationsMutation,
  useAddSubscriptionMutation,
  useDeleteWebPushSubscriptionMutation,
  useViewEventMutation,
  useGetLastNotificationsQuery,
} = notificationService
