import fog from '@/assets/img/weather/fog.png'
import hail from '@/assets/img/weather/hail.png'
import rain from '@/assets/img/weather/rain.png'
import snow from '@/assets/img/weather/snow.png'

const directionWind = [
  'Северный',
  'Северный-Северо-Восточный',
  'Северо-Восточный',
  'Восточный-Северо-Восточный',
  'Восточный',
  'Восточный-Юго-Восточный',
  'Юго-Восточный',
  'Южный-Юго-Восточный',
  'Южный',
  'Южный-Юго-Западный',
  'Юго-Западный',
  'Западный-Юго-Западный',
  'Западный',
  'Западный-Северо-Западный',
  'Северо-Западный',
  'Северный-Северо-Западный',
]

export function degToCompass(num: number) {
  const val = Math.round(num / 22.5 + 0.5)
  return directionWind[val % 16]
}

const synopCodes: any = []

function createSynop(
  name: any,
  intensityIntervalType: any,
  intensityValue1: any,
  intensityValue2: any,
  code4680: any,
  code4677: any
) {
  const obj: any = {}
  obj['name'] = name
  obj['intensityIntervalType'] = intensityIntervalType
  obj['intensityValue1'] = intensityValue1
  obj['intensityValue2'] = intensityValue2
  obj['code4680'] = code4680
  obj['code4677'] = code4677
  synopCodes.push(obj)
}

function initSynop() {
  createSynop('Моросящий дождь (легкий)', '0', '0.2', '', '51', '51')
  createSynop('Моросящий дождь (умеренный)', '0', '0.2', '0.5', '52', '53')
  createSynop('Моросящий дождь (сильный)', '0', '0.5', '', '53', '55')
  createSynop('Небольшой дождь (легкий)', '0', '0.2', '', '57', '58')
  createSynop('Небольшой дождь (умеренный)', '2', '0', '0.5', '58', '59')
  createSynop('Небольшой дождь (сильный)', '3', '0.5', '', '58', '59')
  createSynop('Cлабый дождь', '0', '0.2', '', '61', '61')
  createSynop('Дождь', '0', '0.5', '4.0', '62', '63')
  createSynop('Cильный дождь', '0', '4.0', '', '63', '65')
  createSynop('Cлабый снег с дождем', '0', '0.5', '', '67', '68')
  createSynop('Cнег с дождем', '0', '0.5', '', '68', '69')
  createSynop('Небольшой снег', '0', '0.5', '', '71', '71')
  createSynop('Cнег', '0', '0.5', '4.0', '72', '73')
  createSynop('Cнегопад', '0', '4.0', '', '73', '75')
  createSynop('Cнежная крупа (легкая)', '1', '0.5', '', '77', '77')
  createSynop('Cнежная крупа (умеренная)', '2', '0.5', '4.0', '77', '77')
  createSynop('Cнежная крупа (сильная)', '3', '4.0', '', '77', '77')
  createSynop('Ледяной дождь (легкий)', '0', '0.4', '', '87', '87')
  createSynop('Ледяной дождь (умеренный)', '0', '0.4', '', '88', '88')
  createSynop('Град (легкий)', '1', '7.5', '', '89', '89')
  createSynop('Град (умеренный)', '3', '7.5', '', '89', '90')
  createSynop('Осадки', '0', '', '', '0', '0')
  createSynop('Без осадков', '0', '', '', '99', '99')
}

initSynop()

export function getSynop(
  isSynop4680: boolean,
  code: number,
  intensity: number
) {
  for (let i = 0; i < synopCodes.length; i++) {
    const obj = synopCodes[i]

    if (
      (isSynop4680 && obj['code4680'] == code) ||
      (!isSynop4680 && obj['code4677'] == code)
    ) {
      let success = false

      switch (obj['intensityIntervalType']) {
        case '1':
          var val1 = parseFloat(obj['intensityValue1'])

          if (intensity < val1) {
            success = true
          }
          break
        case '2':
          var val1 = parseFloat(obj['intensityValue1'])
          var val2 = parseFloat(obj['intensityValue2'])

          if (intensity > val1 && intensity < val2) {
            success = true
          }
          break
        case '3':
          var val1 = parseFloat(obj['intensityValue1'])

          if (intensity > val1) {
            success = true
          }
          break
        case '0':
          success = true
          break
      }

      if (success) {
        return obj['name']
      }
    }
  }

  return 'Без осадков'
}

export const TypeSynop = {
  1: 'Нет осадков',
  2: 'Mоросящий дождь (легкий)',
  3: 'Mоросящий дождь (умеренный)',
  4: 'Mоросящий дождь (сильный)',
  5: 'Небольшой дождь (легкий)',
  6: 'Небольшой дождь (умеренный)',
  7: 'Небольшой дождь (сильный)',
  8: 'Слабый дождь (легкий)',
  9: 'Дождь (умеренный)',
  10: 'Сильный дождь (сильный)',
  11: 'Слабый снег с дождем (легкий)',
  12: 'Снег с дождем (умеренный)',
  13: 'Небольшой снег (легкий)',
  14: 'Снег (умеренный)',
  15: 'Снегопад (сильный)',
  16: 'Снежная крупа (легкая)',
  17: 'Снежная крупа (умеренная)',
  18: 'Снежная крупа (сильная)',
  19: 'Ледяной дождь (легкий)',
  20: 'Ледяной дождь (умеренный)',
  21: 'Град (легкий)',
  22: 'Град (умеренный)',
  23: 'Осадки',
} as const

export const TypeUltraShortTermForecastId = {
  0: 'Сбор данных для прогнозирования',
  1: 'Нет прогноза',
  2: 'Ближайшие 3 часа ожидается гололёд',
  3: 'В настоящее время детектируется гололёд',
  4: 'Ближайшие 3 часа ожидается гололедица',
  5: 'В настоящее время детектируется гололедица',
} as const

export const getMeteoImage = (id: number) => {
  //дождь
  if (id > 1 && id <= 8) return rain
  if (id === 9) return rain
  if (id === 10) return rain
  //снег
  if (id > 10 && id <= 13) return snow
  if (id === 14) return snow
  if (id >= 15 && id <= 20) return snow
  //град
  if (id >= 21 && id <= 22) return hail
  //туман
  if (id === 23) return fog

  return ''
}
