import Shell from '@/modules/login/layout/Shell'
import { Box, Typography, useTheme } from '@mui/material'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import cn from '@/utils/cn'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Button from '@/modules/login/components/Button/Button'
import { useActions, useAppSelector } from '@/hooks'
import { userEmailSelector } from '@/modules/login/store/userLoginStore'
import { LoaderFullScreen } from '@/ui'
import { useConfirmEmailMutation } from '@/modules/login/services/login'

const s = cn('loginModule')

const ConfirmEmailModule = () => {
  const [isConfirmEmail, setIsConfirmEmail] = useState<string>('')

  const navigate = useNavigate()
  const theme = useTheme()
  const location = useLocation()
  const userEmail = useAppSelector(userEmailSelector)
  const { setUserEmail } = useActions()
  const { token } = useParams()

  const [confirmEmail, { isLoading }] = useConfirmEmailMutation()

  const onConfirmEmail = async () => {
    try {
      const resConfirmEmail = await confirmEmail(token ?? '').unwrap()
      if (!resConfirmEmail.success && resConfirmEmail.messages) {
        resConfirmEmail.messages.map((mes) => setIsConfirmEmail(mes))
      }
      if (resConfirmEmail.success) {
        setTimeout(() => {
          navigate('/login')
        }, 3000)
      }
    } catch (e) {
      setIsConfirmEmail('Что-то пошло не так. Обратитесь к администратору')
    }
  }

  useLayoutEffect(() => {
    if (location.pathname.includes('/confirm-email-success')) {
      onConfirmEmail().then()
    }
  }, [])

  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      setUserEmail('')
      if (!location.pathname.includes('/confirm-email-success')) {
        navigate('/login')
      }
    }, 3000)
    return () => {
      clearTimeout(redirectTimer)
    }
  }, [])

  return (
    <Shell>
      <LoaderFullScreen open={isLoading} />
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        mt={4}
      >
        {location.pathname === '/confirm-email' ? (
          <Typography
            color={theme.palette.white.main}
            fontSize={16}
            mb={1}
            textAlign='center'
          >
            Подтверждение регистрации.
          </Typography>
        ) : null}
        <Typography
          color={theme.palette.white.main}
          variant='h5'
          fontSize={{
            xss: location.pathname === '/confirm-email' ? 14 : 20,
            xxl: location.pathname === '/confirm-email' ? 18 : 24,
          }}
          mb={1}
          textAlign='center'
        >
          {location.pathname === '/confirm-email'
            ? `Мы отправили Вам письмо на ${userEmail || 'почту'}`
            : location.pathname === '/confirm-email-instruction'
              ? 'Мы отправили Вам инструкцию по восстановлению пароля'
              : location.pathname === '/password-changed'
                ? 'Пароль успешно изменен'
                : isLoading && isConfirmEmail === ''
                  ? 'Подождите немного...'
                  : isConfirmEmail === '' && !isLoading
                    ? 'Адрес электронной почты успешно подтвержден'
                    : isConfirmEmail}
        </Typography>
        <Box
          display='flex'
          justifyContent='center'
          mt={4}
          alignItems='center'
        >
          <Button
            variant='outlined'
            onClick={() => navigate('/login')}
            label='Войти в систему'
          />
        </Box>
      </Box>
    </Shell>
  )
}

export default ConfirmEmailModule
