import IconsSelector from '@/components/Molecules/IconsSelector/Icons'
import { useActions, useAppSelector } from '@/hooks'
import {
  useGetDeviceDetailQuery,
  useGetDeviceEventsQuery,
  useGetPhotosDetailQuery,
} from '@/modules/device-module/services/deviceService'
import {
  stateDeviceSelector,
  stateDeviceSelectorFromDateEvents,
  stateDeviceSelectorToDate,
} from '@/modules/device-module/store/stateSlice'
import { useViewEventMutation } from '@/modules/sidebar/services/notificationService'
import cn from '@/utils/cn'
import {
  Box,
  CircularProgress,
  Pagination,
  TableCell,
  Typography,
} from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import dayjs from 'dayjs'
import * as React from 'react'
import { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { filterSystemSelector } from '@/store/filterSystemsSlise'

const s = cn('device-module')
const Events = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { pathname, state } = useLocation()
  const fromDate = useAppSelector(stateDeviceSelectorFromDateEvents)
  const toDate = useAppSelector(stateDeviceSelectorToDate)
  const { modulesStateFilter } = useAppSelector(filterSystemSelector)
  const { setModulesFilterValues } = useActions()
  const { selectedFilters } = useAppSelector(stateDeviceSelector)
  const [page, setPage] = React.useState(1)
  const [pages, setPages] = React.useState(1)
  const [count, setCount] = React.useState(0)
  const [viewEventService] = useViewEventMutation()

  const { data: photosData } = useGetPhotosDetailQuery(Number(id), {
    skip: !pathname.includes('photos'),
  })
  const { data: deviceData } = useGetDeviceDetailQuery(Number(id), {
    skip: pathname.includes('photos'),
  })
  const { data, isLoading, refetch, isFetching } = useGetDeviceEventsQuery({
    deviceId: Number(id),
    start: dayjs(fromDate).toISOString(),
    end: dayjs(toDate).toISOString(),
    page,
    filterModules: modulesStateFilter,
    loadFilterModules: true,
    eventTypeFilter: selectedFilters.every((item) => item === 0)
      ? []
      : selectedFilters,
  })
  if (data?.filterModules) {
    setModulesFilterValues(data.filterModules)
  }

  useEffect(() => {
    refetch()
  }, [fromDate, toDate, page, selectedFilters, modulesStateFilter])

  useEffect(() => {
    if (data) {
      setPages(data.pages)
      setCount(data.count)
      setPage(data.page)
    }
  }, [data])

  useEffect(() => {
    if (state && state.eventId && state.deviceId) {
      onViewEvent()
    }
  }, [state])

  const onViewEvent = async () => {
    try {
      await viewEventService({
        deviceId: state.deviceId,
        eventId: state.eventId,
      }).unwrap()
      navigate(`/devices/${state.deviceId}`, { state: null })
    } catch (e) {
      console.log(e)
    }
  }

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage)
  }

  return (
    <>
      <CircularProgress
        className={
          isLoading || isFetching ? s('show-loader') : s('hide-loader')
        }
      />
      <Box
        flex={1}
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        className={s({ loading: isLoading })}
        sx={{
          maxHeight: 'calc(100vh - 285px)',
          width: '100%',
          overflow: 'auto',
        }}
      >
        <TableContainer
          sx={{
            maxHeight: '100%',
            overflow: 'auto',
          }}
        >
          <Table
            size='small'
            sx={{ backgroundColor: '#fff' }}
          >
            <TableBody>
              {data?.data?.map((item, index) => {
                let iconId = item.typeId
                if (
                  iconId === 1 &&
                  (photosData?.typeId !== 7 || deviceData?.typeId !== 8)
                ) {
                  iconId = 101
                }
                return (
                  <TableRow key={item.id}>
                    <TableCell
                      align='left'
                      //sx={{ width: '50%' }}
                    >
                      <Box
                        display='flex'
                        alignItems='center'
                        gap={2}
                      >
                        <Box
                          display='flex'
                          alignItems='center'
                          justifyContent='center'
                          width={40}
                        >
                          <IconsSelector id={iconId} />
                        </Box>
                        <Typography
                          sx={{ color: 'gray', fontSize: { xss: 9, lg: 12 } }}
                        >
                          {item.createdDate}
                        </Typography>
                        <Box
                          display='flex'
                          flexDirection={{ xss: 'column', lg: 'row' }}
                        >
                          <Typography
                            sx={{
                              color: 'rgba(0, 114, 188, .7)',
                              fontSize: { xss: 12, lg: 16 },
                              width: { xss: 'unset', md: 200 },
                            }}
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            sx={{
                              color: 'gray',
                              fontSize: { xss: 10, lg: 14 },
                            }}
                          >
                            {item.remouteAddress}
                          </Typography>
                          <Typography
                            sx={{
                              display: { xss: 'inline-block', md: 'none' },
                              color: 'gray',
                              fontSize: { xss: 9, lg: 14 },
                            }}
                          >
                            {item.message}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell
                      align='right'
                      sx={{ display: { xss: 'none', md: 'table-cell' } }}
                    >
                      <Typography
                        sx={{ color: 'gray', fontSize: { xss: 9, lg: 14 } }}
                      >
                        {item.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={{ xss: 0, md: 2 }} />
        <Box
          display='flex'
          width='100%'
          justifyContent='flex-end'
        >
          {count <= 25 ? null : (
            <Pagination
              count={pages}
              page={page}
              onChange={handleChangePage}
              size='small'
              siblingCount={1}
              boundaryCount={2}
              color={'primary'}
            />
          )}
        </Box>
      </Box>
    </>
  )
}

export default Events
