import cyl from '@/assets/img/cylinder.png'
import ok from '@/assets/img/icons8-галочка-40.png'
import InfoIcon from '@/components/icons/InfoIcon'
import cn from '@/utils/cn'
import Box from '@mui/material/Box'

import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import * as React from 'react'
import { FC, memo } from 'react'

const s = cn('bottom-panel')

interface Props {
  isMap?: boolean
  totalErrors?: number
  totalNotSmart?: number
  totalModulesCount?: number
  totalServiceable?: number
  totalWarnings?: number
  totalOk?: number
  isModules?: boolean
}

const BottomPanel: FC<Props> = ({
  isMap,
  totalModulesCount,
  totalServiceable,
  totalWarnings,
  totalNotSmart,
  totalErrors,
  totalOk,
  isModules,
}) => {
  return (
    <Paper
      elevation={6}
      style={{ overflowX: 'auto' }}
      sx={{
        position: isMap ? 'absolute' : 'unset',
        bottom: 0,
        borderRadius: isMap ? 0 : 4,
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        boxShadow: 'unset',
        //backgroundImage: `url(${bg})`,
        color: '#fff',
      }}
    >
      <Box
        width='100%'
        display='flex'
        justifyContent='flex-end'
        //alignItems='center'
        p={1}
        gap={{ xss: 1, md: 3 }}
        minWidth={400}
        className={s()}
      >
        {totalOk === undefined ? null : (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            gap={1}
            flexWrap='wrap'
          >
            <Tooltip
              title='Датчиков исправно'
              placement='top'
            >
              <img
                width={30}
                height={30}
                src={ok}
                alt='ok'
              />
            </Tooltip>
            <span style={{ textAlign: 'center' }}>{totalOk} шт.</span>
          </Box>
        )}
        {totalModulesCount === undefined ? null : (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            gap={1}
            flexWrap='wrap'
          >
            <Tooltip
              title='Резервуаров всего'
              placement='top'
            >
              <img
                width={30}
                height={30}
                src={cyl}
                alt='processor'
              />
            </Tooltip>
            <span style={{ textAlign: 'center' }}>{totalModulesCount} шт.</span>
          </Box>
        )}
        {totalServiceable === undefined ? null : (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            gap={1}
            flexWrap='wrap'
          >
            <Tooltip
              title='Pезервуаров исправно'
              placement='top'
            >
              <img
                width={30}
                height={30}
                src={ok}
                alt='ok'
              />
            </Tooltip>
            <span style={{ textAlign: 'center' }}>{totalServiceable} шт.</span>
          </Box>
        )}
        {totalWarnings === undefined ? null : (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            gap={1}
            flexWrap='wrap'
          >
            <InfoIcon
              type='warning'
              tooltip={
                isModules ? 'Датчиков с ошибками' : 'Резервуаров с ошибками'
              }
            />
            <span style={{ textAlign: 'center' }}>{totalWarnings} шт.</span>
          </Box>
        )}
        {totalErrors === undefined ? null : (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            gap={1}
            flexWrap='wrap'
          >
            <InfoIcon
              type='error'
              tooltip={
                isModules ? 'Датчиков неисправно' : 'Резервуаров неисправно'
              }
            />
            <span style={{ textAlign: 'center' }}>{totalErrors} шт.</span>
          </Box>
        )}
      </Box>
    </Paper>
  )
}

export default memo(BottomPanel)
