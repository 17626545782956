import temp from '@/assets/img/temp.png'
import { roundNumber } from '@/utils/roundedNumber'
import Box, { BoxProps } from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import * as React from 'react'
import { FC } from 'react'

type PropsIcon = {
  temperature: number
}

type Props = BoxProps & PropsIcon

const TempIcon: FC<Props> = ({ temperature, width = 70, ...props }) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      gap={1}
      width={width}
      {...props}
    >
      <Tooltip
        title='Температура жидкости'
        placement='top'
      >
        <img
          width={25}
          height={25}
          src={temp}
          alt='Температура'
        />
      </Tooltip>
      <span>{roundNumber(temperature)}°C</span>
    </Box>
  )
}

export default TempIcon
