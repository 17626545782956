import elB from '@/assets/img/electricalBlue.png'
import Box, { BoxProps } from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import * as React from 'react'
import { FC } from 'react'

type PropsIcon = {
  is220v: boolean
}

type Props = BoxProps & PropsIcon

const Is220Icon: FC<Props> = ({ is220v, width = 70, ...props }) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      gap={1}
      width={width}
      {...props}
    >
      {is220v ? (
        <>
          <Tooltip
            title='Питание'
            placement='top'
          >
            <img
              width={25}
              height={25}
              src={elB}
              alt='Батарея'
            />
          </Tooltip>
          <span>220В</span>
        </>
      ) : (
        <>
          <Tooltip
            title='Питание'
            placement='top'
          >
            <img
              width={25}
              height={25}
              src={elB}
              alt='Батарея'
            />
          </Tooltip>
          <span>АКБ</span>
        </>
      )}
    </Box>
  )
}

export default Is220Icon
