import loc from '@/assets/img/icons8-location-40.png'
import ContentPopover from '@/modules/devices-list/components/DevicesTable/ContentPopover'
import cn from '@/utils/cn'
import Box from '@mui/material/Box'
import { Popover } from 'antd'
import * as React from 'react'
import { FC } from 'react'

const s = cn('bottom-panel-location')

interface Props {
  isLocation: boolean
  totalVideo?: number
  totalPhoto?: number
  totalNotSmart?: number
  totalModulesCount?: number
}

const Location: FC<Props> = ({
  isLocation,
  totalPhoto,
  totalVideo,
  totalNotSmart,
  totalModulesCount,
}) => {
  return (
    <>
      {isLocation ? (
        <Popover
          className={s()}
          content={
            <ContentPopover
              totalPhoto={totalPhoto}
              totalVideo={totalVideo}
              totalNotSmart={totalNotSmart}
              totalModulesCount={totalModulesCount}
            />
          }
          trigger='hover'
        >
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{ cursor: 'pointer' }}
            gap={1}
            flexWrap='wrap'
          >
            <img
              width={30}
              height={30}
              src={loc}
              alt='tsodd'
            />
            <span style={{ textAlign: 'center' }}>
              {(totalNotSmart ?? 0) +
                (totalPhoto ?? 0) +
                (totalVideo ?? 0) +
                (totalModulesCount ?? 0)}{' '}
              шт.
            </span>
          </Box>
        </Popover>
      ) : null}
    </>
  )
}

export default Location
