import { SIDEBAR_WIDTH } from '@/constants'
import { useActions, useAppSelector, useOnClickOutside } from '@/hooks'
import { BottomMenu } from '@/modules/sidebar/components/BottomMenu/BottomMenu'
import { UserAvatar } from '@/modules/sidebar/components/UserAvatar/UserAvatar'
import { filterSystemSelector } from '@/store/filterSystemsSlise'
import cn from '@/utils/cn'
import { Box, List, ListItemButton, ListItemText } from '@mui/material'
import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import FavoritesList from './components/Favorites/FavoritesList'

const s = cn('sidebar')

interface ISidebar {
  isShowSideBar: boolean
  setIsShowSidebar: Dispatch<SetStateAction<boolean>>
}

const Sidebar: FC<ISidebar> = ({ isShowSideBar, setIsShowSidebar }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { favoriteId } = useAppSelector(filterSystemSelector)

  const ref = useRef<HTMLDivElement>(null)
  const isIphone = navigator.userAgent.includes('iPhone')

  useOnClickOutside(ref, () => setIsShowSidebar(false))

  const { setSidebarWidth } = useActions()

  useEffect(() => {
    if (ref.current) {
      setSidebarWidth(ref.current.offsetWidth)
    }
  }, [ref.current?.offsetWidth])

  const onNavigate = () => {
    setIsShowSidebar(false)
    if (pathname === '/') {
      navigate('/devices')
    } else {
      navigate('/')
    }
    //setFavoriteId(0)
  }

  return (
    <Box
      ref={ref}
      minWidth={{
        xss: SIDEBAR_WIDTH[200],
        xs: SIDEBAR_WIDTH[240],
        xl: SIDEBAR_WIDTH[300],
        xxl: SIDEBAR_WIDTH[400],
      }}
      className={isShowSideBar ? 'sidebar sidebar_show' : 'sidebar'}
      //width='100%'
      pt={2}
      pb={2}
      display='flex'
      flexDirection='column'
      alignItems='center'
      style={{ height: isIphone ? document.documentElement.clientHeight : '' }}
    >
      <UserAvatar setIsShowSidebar={setIsShowSidebar} />
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        mb={1}
      >
        <List sx={{ width: '100%' }}>
          <ListItemButton
            className={s('nav-btn')}
            alignItems='center'
            onClick={onNavigate}
            sx={{
              backgroundColor:
                pathname === '/' || pathname === '/devices'
                  ? '#37424d'
                  : 'transparent',
            }}
          >
            <ListItemText
              style={{ color: 'white' }}
              sx={{ display: 'flex', justifyContent: 'center' }}
              primary={
                import.meta.env.VITE_APP_NAME === 'RobertAqua'
                  ? pathname === '/'
                    ? 'К списку резервуаров'
                    : 'К резервуарам на карте'
                  : pathname === '/'
                    ? 'К списку систем'
                    : 'К системам на карте'
              }
            />
          </ListItemButton>
        </List>
      </Box>
      <FavoritesList />
      <BottomMenu setIsShowSidebar={setIsShowSidebar} />
    </Box>
  )
}

export default Sidebar
