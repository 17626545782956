import PrivateRoute from '@/components'
import PrivateRoutePermissionsUser from '@/components/PrivateRoute/PrivateRoutePermissionsUser'
import PrivateRoutePermissionsUserAdmin from '@/components/PrivateRoute/PrivateRoutePermissionsUserAdmin'
import Layout from '@/Layout'
import React, { lazy } from 'react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom'

// const lazyDelayed = (path: string) => {
//   return lazy(() => Promise.all([
//     import(path),
//     new Promise((resolve) => setTimeout(resolve, 300)),
//   ]).then(([module]) => module))
// }

// const Home = lazyDelayed('../pages/Home')
// const Login = lazyDelayed('../pages/Login/Login')
// const Register = lazyDelayed('../pages/Login/Register')
// const RecoverPass = lazyDelayed('../pages/Login/RecoverPass')
// const ChangePass = lazyDelayed('../pages/Login/ChangePass')
// const ConfirmEmail = lazyDelayed('../pages/Login/ConfirmEmail')
// const Users = lazyDelayed('../pages/UsersManagement/Users')
// const UserPage = lazyDelayed('../pages/UsersManagement/UserPage')
// const UserAccount = lazyDelayed('../pages/UsersManagement/UserAccount')
// const Events = lazyDelayed('../pages/UsersManagement/Events')
// const Organisations = lazyDelayed('../pages/UsersManagement/Organisations')
// const ListOfSystems = lazyDelayed('../pages/ListOfSystems')
// const SystemsManagement = lazyDelayed('../pages/SystemsManagement')
// const Events = lazyDelayed('../pages/Events')

const Home = lazy(() => import('../pages/Home'))
const Login = lazy(() => import('../pages/Login/Login'))
const Register = lazy(() => import('../pages/Login/Register'))
const RecoverPass = lazy(() => import('../pages/Login/RecoverPass'))
const ChangePass = lazy(() => import('../pages/Login/ChangePass'))
const ConfirmEmail = lazy(() => import('../pages/Login/ConfirmEmail'))
const Users = lazy(() => import('../pages/UsersManagement/Users'))
const UserPage = lazy(() => import('../pages/UsersManagement/UserPage'))
const UserAccount = lazy(() => import('../pages/UsersManagement/UserAccount'))
const Events = lazy(() => import('../pages/UsersManagement/Events'))
const Organisations = lazy(
  () => import('../pages/UsersManagement/Organisations')
)
const OrganizationPage = lazy(
  () => import('../pages/UsersManagement/OrganizationPage')
)
const ListOfSystems = lazy(() => import('../pages/ListOfSystems'))
const SystemsManagement = lazy(() => import('../pages/SystemsManagement'))
const NewUnitsIS = lazy(() => import('../pages/NewUnits'))
const NewUnits = lazy(() => import('../pages/NewUnitsLayoutPage'))
const AddNewUnits = lazy(() => import('../pages/AddNewUnits'))
const AddNewTSODD = lazy(() => import('../pages/AddNewTSODD'))
const AddNewPhoto = lazy(() => import('../pages/AddNewPhotoPage'))
const AddNewVideo = lazy(() => import('../pages/AddNewVideoPage'))
const Device = lazy(() => import('../pages/Device'))
const Favorites = lazy(() => import('../pages/Favorites'))

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<PrivateRoute />}>
        <Route
          path='/'
          element={<Layout />}
        >
          <Route
            index
            element={<Home />}
          />
          <Route
            path='devices'
            element={<ListOfSystems />}
          />
          <Route
            path='devices/:id'
            element={<Device />}
          />
          <Route
            path='photos/:id'
            element={<Device />}
          />
          <Route
            path='favorites'
            element={<Favorites />}
          />
          <Route
            path='systems-management'
            element={<SystemsManagement />}
          />

          {/*only for superadmin*/}
          <Route element={<PrivateRoutePermissionsUserAdmin />}>
            <Route
              path='new-units'
              element={<NewUnits />}
            />
            <Route
              path='new-units-is'
              element={<NewUnitsIS />}
            />
            <Route
              path='new-units/:id'
              element={<AddNewUnits />}
            />
            <Route
              path='new-tsodd'
              element={<AddNewTSODD />}
            />
            <Route
              path='new-photo'
              element={<AddNewPhoto />}
            />
            <Route
              path='new-video'
              element={<AddNewVideo />}
            />
            <Route
              path='organizations'
              element={<Organisations />}
            />
          </Route>

          {/*only for superadmin and admin*/}
          <Route element={<PrivateRoutePermissionsUser />}>
            <Route
              path='organizations/:id'
              element={<OrganizationPage />}
            />
            <Route
              path='users'
              element={<Users />}
            />
          </Route>

          <Route
            path='events'
            element={<Events />}
          />
          <Route
            path='users/:id'
            element={<UserPage />}
          />
          <Route
            path='account'
            element={<UserAccount />}
          />
        </Route>
      </Route>
      <Route
        path='login'
        element={<Login />}
      />
      <Route
        path='register'
        element={<Register />}
      />
      <Route
        path='recovery-password'
        element={<RecoverPass />}
      />
      <Route
        path='confirm-email'
        element={<ConfirmEmail />}
      />
      <Route
        path='confirm-email-success/:token'
        element={<ConfirmEmail />}
      />
      <Route
        path='confirm-email-instruction'
        element={<ConfirmEmail />}
      />
      <Route
        path='change-password/:token'
        element={<ChangePass />}
      />
      <Route
        path='password-changed'
        element={<ConfirmEmail />}
      />
    </>
  )
)
