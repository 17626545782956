import video from '@/assets/video/v1.mp4'
import VideoItem from '@/modules/devices-list/components/DevicesTable/VideoItem'
import cn from '@/utils/cn'
import { Box } from '@mui/material'
import * as React from 'react'

const s = cn('video')

const VideoComponent = () => {
  return (
    <Box
      flex={1}
      display='flex'
      justifyContent='center'
      alignItems='center'
      className={s()}
      px={0.5}
    >
      <Box
        width={{ xss: '90%', lg: '70%' }}
        // width={{
        //   xss: 'calc(100vw - 100px)',
        //   xs: 'calc(100vw - 100px)',
        //   sm: 'calc(100vw - 100px)',
        //   md: 'calc(100vw - 200px)',
        //   lg: 'calc(100vw - 350px)',
        //   xl: 'calc(100vw - 500px)',
        //   xll: 'calc(100vw - 900px)',
        //   xxl: 'calc(100vw - 700px)',
        // }}
        sx={{ aspectRatio: '16/9', backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
      >
        <VideoItem
          video={video}
          isHovered={true}
        />
      </Box>
    </Box>
  )
}

export default VideoComponent
