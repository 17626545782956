import { TerminalHistory } from '@/modules/device-module/types/terminal'
import { RootState } from '@/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: { history: TerminalHistory[] } = {
  history: [],
}

const terminalSlice = createSlice({
  name: 'terminal',
  initialState,
  reducers: {
    setTerminalHistory: (state, action: PayloadAction<TerminalHistory[]>) => {
      state.history = action.payload
    },
    addOneTerminalHistory: (state, action: PayloadAction<TerminalHistory>) => {
      state.history.push(action.payload)
    },
  },
})

export const { setTerminalHistory, addOneTerminalHistory } =
  terminalSlice.actions

export const terminalHistorySelector = (state: RootState) =>
  state.terminal.history

export default terminalSlice.reducer
