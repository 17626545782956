import { baseQueryCustom } from '@/api/baseQuery'
import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit'
import { isRejectedWithValue } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'

export const api = createApi({
  baseQuery: baseQueryCustom,
  reducerPath: 'apiWeb',
  tagTypes: [
    'User',
    'Organizations',
    'Favorites',
    'FavoritesAqua',
    'FavoritesSmall',
    'FavoritesSmallAqua',
    'Device',
    'Description',
    'Commands',
    'History',
    'Properties',
    'Notifications',
    'HistoryNew',
  ],
  endpoints: () => ({}),
})

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      console.error('We got a rejected action!')
      console.log(action)
    }

    return next(action)
  }
