import arrowDown from '@/assets/img/arrow-down.png'
import arrowUp from '@/assets/img/arrow-up.png'
import blackCar from '@/assets/img/car.png'
import redCar from '@/assets/img/car_red.png'
import minus from '@/assets/img/minus.png'
import { useGetForeCastQuery } from '@/modules/device-module/services/deviceService'
import cn from '@/utils/cn'
import { TypeUltraShortTermForecastId } from '@/utils/meteo'
import { CircularProgress, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import * as React from 'react'
import { FC } from 'react'
import { useParams } from 'react-router-dom'

const s = cn('devices-list')

interface Props {}

const Forecast: FC<Props> = () => {
  const { id } = useParams()
  const { data, isLoading } = useGetForeCastQuery(
    { deviceId: id ?? '' },
    { skip: !id }
  )

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
    >
      <CircularProgress
        className={isLoading ? s('show-loader') : s('hide-loader')}
      />
      <Box
        px={3}
        mt={{ xss: 3, lg: 0 }}
        width='100%'
        sx={{ filter: isLoading ? 'blur(6px)' : 'unset' }}
      >
        <Typography
          variant='body1'
          color='#57729a'
          fontSize={18}
        >
          Сверхкраткосрочный прогноз
        </Typography>
        <Box
          display='flex'
          alignItems='center'
          gap={2}
          my={{ xss: 2, lg: 4 }}
        >
          {data?.data?.ultraShortTermForecastId &&
          data?.data?.ultraShortTermForecastId > 1 ? (
            <img
              src={
                data?.data?.ultraShortTermForecastId === 2 ||
                data?.data?.ultraShortTermForecastId === 4
                  ? blackCar
                  : redCar
              }
              alt='icon'
              width={40}
              height={40}
            />
          ) : null}
          <Typography
            variant='body1'
            color='#262538'
            fontWeight='bold'
            fontSize={20}
          >
            {
              TypeUltraShortTermForecastId[
                data?.data
                  ?.ultraShortTermForecastId as keyof typeof TypeUltraShortTermForecastId
              ]
            }
          </Typography>
        </Box>
        <Box width={400}>
          <Box position='relative'>
            <Typography
              sx={{ mb: 1 }}
              variant='body1'
              color='#57729a'
              fontSize={18}
            >
              Тенденции
            </Typography>
            <div
              style={{
                width: 120,
                height: 1.5,
                backgroundColor: '#57729a',
                position: 'absolute',
                top: 30,
                left: 0,
                zIndex: 1,
              }}
            />
          </Box>
          {data?.data?.trendCalculated ? (
            <>
              <Box
                display='flex'
                alignItems='center'
                gap={2}
                py={1}
              >
                <Typography
                  sx={{ width: 150 }}
                  variant='body1'
                  color='#57729a'
                  fontSize={18}
                >
                  Давление
                </Typography>
                <Box
                  display='flex'
                  alignItems='center'
                  gap={2}
                >
                  <img
                    src={
                      data?.data?.pressureTrend === 1
                        ? arrowUp
                        : data?.data?.pressureTrend === -1
                          ? arrowDown
                          : minus
                    }
                    alt='icon'
                    width={40}
                    height={40}
                  />
                  <Typography
                    variant='body1'
                    color='#57729a'
                    fontSize={18}
                  >
                    {data?.data?.pressureTrend === 1
                      ? 'Растет'
                      : data?.data?.pressureTrend === -1
                        ? 'Убывает'
                        : 'Не меняется'}
                  </Typography>
                </Box>
              </Box>
              <Box
                display='flex'
                alignItems='center'
                gap={2}
                py={1}
                bgcolor='#E8E9EB'
              >
                <Typography
                  sx={{ width: 150 }}
                  variant='body1'
                  color='#57729a'
                  fontSize={18}
                >
                  Температура
                </Typography>
                <Box
                  display='flex'
                  alignItems='center'
                  gap={2}
                >
                  <img
                    src={
                      data?.data?.temperatureTrend === 1
                        ? arrowUp
                        : data?.data?.temperatureTrend === -1
                          ? arrowDown
                          : minus
                    }
                    alt='icon'
                    width={40}
                    height={40}
                  />
                  <Typography
                    variant='body1'
                    color='#57729a'
                    fontSize={18}
                  >
                    {data?.data?.temperatureTrend === 1
                      ? 'Растет'
                      : data?.data?.temperatureTrend === -1
                        ? 'Убывает'
                        : 'Не меняется'}
                  </Typography>
                </Box>
              </Box>
              <Box
                display='flex'
                alignItems='center'
                gap={2}
                py={1}
              >
                <Typography
                  sx={{ width: 150 }}
                  variant='body1'
                  color='#57729a'
                  fontSize={18}
                >
                  Влажность
                </Typography>
                <Box
                  display='flex'
                  alignItems='center'
                  gap={2}
                >
                  <img
                    src={
                      data?.data?.humidityTrend === 1
                        ? arrowUp
                        : data?.data?.humidityTrend === -1
                          ? arrowDown
                          : minus
                    }
                    alt='icon'
                    width={40}
                    height={40}
                  />
                  <Typography
                    variant='body1'
                    color='#57729a'
                    fontSize={18}
                  >
                    {data?.data?.humidityTrend === 1
                      ? 'Растет'
                      : data?.data?.humidityTrend === -1
                        ? 'Убывает'
                        : 'Не меняется'}
                  </Typography>
                </Box>
              </Box>
            </>
          ) : (
            <Typography
              sx={{ mt: 2 }}
              variant='body1'
              color='#57729a'
              fontSize={18}
            >
              Данных нет
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default Forecast
