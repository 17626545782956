import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useChangeSet } from '@/utils/useChangeSet'
import Input from './Input/Input'
import { Box, Button, Stack } from '@mui/material'
import { useDispatch } from 'react-redux'
import { addFavorite } from '@/modules/favorites/store/favoritesSlise'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Имя не может быть пустым')
    .trim('Имя не может быть пустым')
    .max(30, 'Поле должно содержать не более 30 символов'),
})

export interface IFavoritesFields {
  name: string
}

const Header = () => {
  const dispatch = useDispatch()

  const defaultValues: IFavoritesFields = {
    name: '',
  }

  const {
    handleSubmit,
    control,
    reset,
    trigger,
    formState,
    watch,
    getValues,
    setValue,
  } = useForm<IFavoritesFields>({
    defaultValues,
    resolver: yupResolver(validationSchema),
    criteriaMode: 'all',
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    reset({
      name: '',
    })
  }, [])

  useChangeSet(() => {
    if (watch('name')?.length) {
      trigger('name')
    }
  }, [watch('name')])

  const onSubmit = async (data: IFavoritesFields) => {
    try {
      dispatch(addFavorite(data.name))
      reset({
        name: '',
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Box width={{ xss: 350, sm: 400, md: 500, lg: 700 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction={{ xss: 'column', md: 'row' }}
          justifyContent='space-between'
          alignItems={{ xss: 'flex-end', md: 'center' }}
          pt={{ xss: 0, md: 2 }}
          gap={{ xss: 1, md: 2 }}
        >
          <Input
            control={control}
            name='name'
            label='Название избранного'
          />
          <Button
            sx={{ padding: { xss: '6px 30px', md: '13px 35px' } }}
            type='submit'
            variant='outlined'
          >
            Добавить
          </Button>
        </Stack>
      </form>
    </Box>
  )
}

export default Header
