import { DeviceDetail, Status } from '@/modules/device-module/types/deviceType'
import { RootState } from '@/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: Partial<DeviceDetail> = {
  deviceDate: '',
  operator: '',
  signal: 0,
  connectionStatus: 0,
  lastPackageDate: '',
  modulesCount: 0,
  powerVoltage: 0,
  state: Status.ok,
  errorMessage: '',
}

const infoIconsSlice = createSlice({
  name: 'infoIcons',
  initialState,
  reducers: {
    setInfoIcons: (state, action: PayloadAction<DeviceDetail>) => {
      state.deviceDate = action.payload.deviceDate
      state.operator = action.payload.operator
      state.connectionStatus = action.payload.connectionStatus
      state.lastPackageDate = action.payload.lastPackageDate
      state.modulesCount = action.payload.modulesCount
      state.powerVoltage = action.payload.powerVoltage
      state.is220v = action.payload.is220v
      state.signal = action.payload.signal
      state.state = action.payload.state
      state.errorMessage = action.payload.errorMessage
    },
  },
})

export const { setInfoIcons } = infoIconsSlice.actions

export const infoIconsSelector = (state: RootState) => state.infoIcons

export default infoIconsSlice.reducer
