import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './index'
import { TypeUnit } from '@/modules/devices-list/types/newUnitsType'

interface State {
  searchValue: string
  isSearch: boolean
  filter: number[]
  favoriteId: number
  typeUnits: TypeUnit[]
}

const initialState: State = {
  searchValue: localStorage.getItem('searchValueDevices') ?? '',
  isSearch: false,
  filter: (localStorage.getItem('filterValueDevices') &&
    JSON.parse(localStorage.getItem('filterValueDevices') ?? '')) ?? [0],
  favoriteId: 0,
  typeUnits: [],
}

export const filterTanksSlice = createSlice({
  name: 'filterSystemTanks',
  initialState,
  reducers: {
    setSearchValueTanks: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload
    },
    setIsSearchTanks: (state, action: PayloadAction<boolean>) => {
      state.isSearch = action.payload
    },
    setFilterTanks: (state, action: PayloadAction<number[]>) => {
      state.filter = action.payload
    },
    setFavoriteIdTanks: (state, action: PayloadAction<number>) => {
      state.favoriteId = action.payload
    },
    setTypeUnitsTanks: (state, action: PayloadAction<TypeUnit[]>) => {
      //state.typeUnits.unshift({ id: 0, name: 'Все' })
      state.typeUnits = action.payload
    },
  },
})

export const {
  setSearchValueTanks,
  setIsSearchTanks,
  setFilterTanks,
  setFavoriteIdTanks,
  setTypeUnitsTanks,
} = filterTanksSlice.actions

export const filterTanksSelector = (state: RootState) => state.filterTanks

export default filterTanksSlice.reducer
