import Shell from '@/modules/login/layout/Shell'
import { Box, Typography, useTheme } from '@mui/material'
import React, { useEffect, useLayoutEffect } from 'react'
import cn from '@/utils/cn'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate, useParams } from 'react-router-dom'
import Input from '@/modules/login/components/Input/Input'
import Button from '@/modules/login/components/Button/Button'
import { IChangePassField } from '@/modules/login/types'
import {
  useChangePasswordCheckTokenMutation,
  useChangePasswordMutation,
} from '@/modules/login/services/login'
import { notifyError } from '@/components'
import { useChangeSet } from '@/utils/useChangeSet'

const s = cn('loginModule')

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Пароль не может быть пустым')
    .min(6, 'Введите пароль от 6-ти до 20-ти символов')
    .max(20, 'Введите пароль от 6-ти до 20-ти символов'),
  //.matches(/^(?=.*[a-zA-Z])(?!.*\W)(?!.* ).{6,20}$/, 'Некорректный пароль'),
  confirmPassword: Yup.string()
    .required('Пароль не может быть пустым')
    .oneOf(
      [Yup.ref('password'), null],
      'Пароли не совпадают. Повторите попытку'
    ),
})

const ChangePassModule = () => {
  const navigate = useNavigate()
  const theme = useTheme()

  const { token } = useParams()

  const defaultValues: IChangePassField = {
    confirmPassword: '',
    password: '',
  }

  const { handleSubmit, control, reset, trigger, watch } =
    useForm<IChangePassField>({
      defaultValues,
      resolver: yupResolver(validationSchema),
    })

  const [onChangePassword, { isLoading }] = useChangePasswordMutation()
  const [onCheckToken, { isLoading: isLoadingToken }] =
    useChangePasswordCheckTokenMutation()

  const checkToken = async () => {
    try {
      const res = await onCheckToken(token || '').unwrap()
      if (!res.success) {
        navigate('/login')
      }
    } catch (e) {
      console.log(e)
    }
  }

  useLayoutEffect(() => {
    checkToken().then()
  }, [token])
  const onSubmit = async (data: IChangePassField) => {
    try {
      if (token) {
        const res = await onChangePassword({ ...data, token }).unwrap()
        if (res.success) {
          navigate('/password-changed')
        } else if (!res.success && res.messages) {
          //res.messages.forEach(item => notifyError(item))
        }
      } else {
        notifyError('Что-то пошло не так. Обратитесь к администратору')
      }
    } catch (e) {
      console.log(e)
    }
  }

  useChangeSet(() => {
    if (watch('confirmPassword').length) {
      trigger('confirmPassword')
    }
  }, [watch('password')])

  useEffect(() => {
    reset(defaultValues)
  }, [])

  return (
    <Shell isLoading={isLoading || isLoadingToken}>
      <form
        className={s('form')}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          display='flex'
          flexDirection='column'
          width='100%'
          mt={4}
        >
          <Typography
            color={theme.palette.white.main}
            fontSize={20}
            mb={1}
          >
            Новый пароль
          </Typography>
          <Input
            control={control}
            name='password'
            type='password'
            label='Введите новый пароль'
            disabled={isLoading}
          />
          <Box mt={{ xss: 2, xxl: 4 }} />
          <Input
            control={control}
            name='confirmPassword'
            type='password'
            label='Повторите пароль'
            //disabled={isLoading}
          />
          <Box
            display='flex'
            justifyContent='flex-end'
            mt={{ xss: 2, md: 4 }}
            alignItems='center'
          >
            <Button
              //loading={isLoading}
              type='submit'
              label='Отправить'
              variant='outlined'
            />
          </Box>
        </Box>
      </form>
    </Shell>
  )
}

export default ChangePassModule
