import React, { FC } from 'react'
import cn from '@/utils/cn'
import { LoadingButtonProps } from '@mui/lab'
import LoadingButton from '@mui/lab/LoadingButton'

const s = cn('btn-login')

type IBtn = {
  label: string
  isText?: boolean
}

type BtnProps = LoadingButtonProps & IBtn

const Button: FC<BtnProps> = ({ label, isText, ...props }) => {
  return (
    <LoadingButton
      {...props}
      className={
        isText
          ? s('text')
          : props.variant === 'outlined'
            ? s()
            : s('transparent')
      }
      size='small'
    >
      <span className={props.loading ? s('loading-text') : ''}>{label}</span>
    </LoadingButton>
  )
}

export default Button
