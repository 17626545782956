import ConnectionStatusIcon from '@/components/icons/ConnectionStatusIcon'
import GridTankIcon from '@/components/icons/GridTankIcon'
import InfoIcon from '@/components/icons/InfoIcon'
import Is220Icon from '@/components/icons/Is220Icon'
import SignalIcon from '@/components/icons/SignalIcon'
import TempIcon from '@/components/icons/TempIcon'
import VolumeIcon from '@/components/icons/VolumeIcon'
import { useActions, useAppSelector } from '@/hooks'
import { Status } from '@/modules/device-module/types/deviceType'
import { filterTanksSelector } from '@/store/filterTanksSlise'
import cn from '@/utils/cn'
import { CircularProgress, Pagination } from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useGetAquaUnitsListMutation } from '../../services/newTanksListService'
import { Unit, UnitSearchReq } from '../../types/newUnitsType'
import BottomPanel from './BottomPanel'

const s = cn('tanks-list')

interface IUsersTable {}

const TanksGrid: FC<IUsersTable> = () => {
  const { filter, searchValue, isSearch, favoriteId } =
    useAppSelector(filterTanksSelector)
  const { setSearchValueTanks } = useActions()
  const navigate = useNavigate()

  const [getUnitsService, { isLoading: isLoadingSearch }] =
    useGetAquaUnitsListMutation()

  const [units, setUnits] = useState<Unit[]>([])
  const [page, setPage] = React.useState(
    Number(localStorage.getItem('pageTanks')) || 1
  )
  const [pages, setPages] = React.useState(1)
  const [count, setCount] = React.useState(0)
  const [filtersRes, setFilterRes] = React.useState([0])

  const [totalErrors, setTotalErrors] = React.useState(0)
  const [totalNotSmart, setTotalNotSmart] = React.useState(0)
  const [totalModulesCount, setTotalModulesCount] = React.useState(0)
  const [totalServiceable, setTotalServiceable] = React.useState(0)
  const [totalWarnings, setTotalWarnings] = React.useState(0)

  const handleChangePage = (event: unknown, newPage: number) => {
    localStorage.setItem('pageTanks', String(newPage))
    setPage(newPage)
  }

  const onGetUnits = async () => {
    try {
      const usersSearchData: UnitSearchReq = {
        value: !searchValue.trim().length ? '' : searchValue,
        page: page,
        deviceTypeFilter: filter,
        favoriteId: favoriteId,
      }
      const res = await getUnitsService(usersSearchData).unwrap()
      if (res.success) {
        setUnits(res.data)
        setPage(res.page || 1)
        setPages(res.pages || 1)
        setCount(res.count ?? 0)

        setTotalModulesCount(res.totalModulesCount ?? 0)
        setTotalErrors(res.totalErrors ?? 0)
        setTotalNotSmart(res.totalNotSmart ?? 0)
        setTotalServiceable(res.totalServiceable ?? 0)
        setTotalWarnings(res.totalWarnings ?? 0)
      }
      if (!searchValue.trim().length) {
        setSearchValueTanks('')
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    onGetUnits().then()
  }, [page, isSearch])

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      position='relative'
      className={s('container')}
    >
      <CircularProgress
        className={isLoadingSearch ? s('show-loader') : s('hide-loader')}
      />
      <Box
        width='100%'
        height='100%'
        display='flex'
        flexDirection='column'
        className={isLoadingSearch ? s('table-loading') : ''}
      >
        <Paper
          sx={{
            flex: 1,
            width: '100%',
            height: '79%',
            pb: { xss: 0, md: 2 },
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.83)',
          }}
          elevation={6}
        >
          <Box
            height='97%'
            py={2}
            px={4}
            overflow='auto'
            className={s('box-wrapper')}
          >
            <Box
              display='flex'
              flexWrap='wrap'
              gap={3}
            >
              {units.map((unit, index) => {
                return (
                  <Box
                    key={unit.id}
                    className={s('box')}
                    sx={{
                      width: { xss: 310, lg: 360 },
                      minHeight: { xss: 345, lg: 410 },
                      borderRadius: 3,
                      p: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='space-between'
                    >
                      <Link
                        to={`/devices/${unit.id}`}
                        className={s('link')}
                      >
                        <span
                          className={s('name')}
                          style={{ color: 'gray' }}
                        >
                          {unit.type} {unit.volume}м3
                        </span>
                        <span
                          className={s('address')}
                          style={{ color: '#0072BC' }}
                        >
                          {unit.address}
                        </span>
                      </Link>
                      <InfoIcon
                        tooltip={unit.errorMessage}
                        type={
                          unit.state === Status.error
                            ? 'error'
                            : unit.state === Status.warning
                              ? 'warning'
                              : 'ok'
                        }
                      />
                    </Box>
                    <Box
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      pt={2}
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        gap={'90px'}
                      >
                        <TempIcon temperature={unit.temperature} />
                        <VolumeIcon volume={unit.currentVolume} />
                      </Box>
                      <GridTankIcon
                        currentVolume={unit.currentVolume}
                        volume={unit.volume}
                        isRect={unit.formTypeId === 2}
                      />
                    </Box>
                    <Box
                      display='flex'
                      width='100%'
                      alignItems='center'
                      justifyContent='space-between'
                      px={1}
                    >
                      <ConnectionStatusIcon
                        status={unit.connectionStatus}
                        lastDate={unit.lastPackageDate}
                        width='fit-content'
                      />
                      <SignalIcon signalNumber={unit.signal} />
                      <Is220Icon is220v={unit.is220v} />
                    </Box>
                  </Box>
                )
              })}
            </Box>
          </Box>

          <Box mt={{ xss: 0, md: 2 }} />
          <Box
            display='flex'
            width='100%'
            justifyContent='flex-end'
          >
            {count <= 25 ? null : (
              <Pagination
                count={pages}
                page={page}
                onChange={handleChangePage}
                size='small'
                siblingCount={1}
                boundaryCount={2}
                color={'primary'}
              />
            )}
          </Box>
        </Paper>
        <BottomPanel
          totalErrors={totalErrors}
          totalNotSmart={totalNotSmart}
          totalModulesCount={totalModulesCount}
          totalServiceable={totalServiceable}
          totalWarnings={totalWarnings}
        />
      </Box>
    </Box>
  )
}

export default TanksGrid
