import { api } from '@/api'
import {
  EventsSearchReq,
  EventsSearchRes,
} from '@/modules/events/types/eventsType'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import uts from 'dayjs/plugin/utc'

dayjs.extend(uts)
dayjs.extend(timezone)

const eventsService = api.injectEndpoints({
  endpoints: (build) => ({
    searchEvents: build.mutation<EventsSearchRes, EventsSearchReq>({
      query: (body) => ({
        url: '/events/get',
        method: 'POST',
        body,
      }),
      transformResponse: (res: EventsSearchRes) => {
        if (res.success) {
          res.data.forEach((item) => {
            item.createDate = dayjs(item.createDate)
              .add(3, 'h')
              .format('DD.MM.YYYY HH:mm:ss')
            //item.createDate = dayjs.utc(item.createDate).tz(dayjs.tz.guess()).format('DD.MM.YYYY HH:mm:ss')
          })
        }
        return res
      },
    }),
  }),
  overrideExisting: false,
})

export const { useSearchEventsMutation } = eventsService
