import { useActions, useAppSelector } from '@/hooks'
import { useGetFavoritesSmallQuery } from '@/modules/favorites/services/favoritesService'
import { filterSystemSelector } from '@/store/filterSystemsSlise'
import cn from '@/utils/cn'
import StarIcon from '@mui/icons-material/Star'
import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

const s = cn('favorites-list')
const FavoritesList = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { data, isLoading } = useGetFavoritesSmallQuery()
  const { setFavoriteId, setIsSearch, setFilter } = useActions()
  const { favoriteId, isSearch } = useAppSelector(filterSystemSelector)

  return (
    <>
      <Box width='100%'>
        <ListItem disablePadding>
          <ListItemButton
            className={s('btn', { active: pathname === '/favorites' })}
            onClick={() => {
              navigate('/favorites')
              setFavoriteId(0)
            }}
          >
            <ListItemIcon>
              <StarIcon htmlColor='#e4dc42' />
            </ListItemIcon>
            <ListItemText primary='Избранное' />
          </ListItemButton>
        </ListItem>
      </Box>
      <Box
        flex={1}
        width='100%'
        maxHeight='100%'
        overflow='auto'
        className={s({ loading: isLoading })}
      >
        <CircularProgress
          className={isLoading ? s('show-loader') : s('hide-loader')}
        />
        <List sx={{ width: '100%', overflowY: 'auto' }}>
          {data?.data?.map((item, i) => (
            <ListItem
              key={i}
              disablePadding
              sx={{
                backgroundColor:
                  favoriteId === item.id ? '#37424d' : 'transparent',
                border: favoriteId === item.id ? '1px solid #0072BC' : 'unset',
              }}
            >
              <ListItemButton
                onClick={() => {
                  setIsSearch(!isSearch)
                  setFilter([0])
                  if (favoriteId === item.id) {
                    setFavoriteId(0)
                  } else {
                    navigate(pathname === '/' ? '/' : '/devices')
                    setFavoriteId(item.id)
                  }
                }}
              >
                <ListItemText
                  inset
                  className={s({ active: favoriteId === item.id })}
                  primary={item.name}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <Divider />
    </>
  )
}

export default FavoritesList
