import { useActions, useAppSelector } from '@/hooks'
import { PhotoBottom } from '@/modules/device-module/components/image/PhotoBottom'
import { PhotoTop } from '@/modules/device-module/components/image/PhotoTop'
import { useGetPhotosMutation } from '@/modules/device-module/services/deviceService'
import { photosSelector } from '@/modules/device-module/store/photoSliderSlice'
import { PhotoItem } from '@/modules/device-module/types/deviceType'
import { filterSystemSelector } from '@/store/filterSystemsSlise'
import { asyncFunk } from '@/utils/asyncFunk'
import cn from '@/utils/cn'
import { copyImageToClipboardWithFetch } from '@/utils/screenShot'
import { FullScreen } from '@chiragrupani/fullscreen-react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Box, CircularProgress, useMediaQuery } from '@mui/material'
import dayjs from 'dayjs'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import {
  Manipulation,
  Navigation,
  Pagination,
  Thumbs,
  Virtual,
} from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { Swiper as SwiperType } from 'swiper/types'

const s = cn('slider')

const ImageSliderSwiper = () => {
  const { id } = useParams()

  const { toggleFullScreenDetail, addPhotoBefore } = useActions()

  const photos = useAppSelector(photosSelector)
  const [getPhotos, { isLoading }] = useGetPhotosMutation()

  const [slideIndex, setSlideIndex] = useState(0)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [loader, setLoader] = useState(false)
  const [loading, setLoading] = useState(false)
  const [activeSlide, setActiveSlide] = useState(0)

  const [swiperRefTop, setSwiperRefTop] = useState<SwiperType | null>(null)
  const [swiperRefBottom, setSwiperRefBottom] = useState<SwiperType | null>(
    null
  )
  const [swiperRefCenter, setSwiperRefCenter] = useState<SwiperType | null>(
    null
  )
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null)
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null)
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null)
  const [slides, setSlides] = useState<PhotoItem[]>([])
  const [slideIndexFullScreen, setSlideIndexFullScreen] = useState(0)

  const { isFullScreen: isFullScreenGlobal, isFullScreenDetail } =
    useAppSelector(filterSystemSelector)

  const is1024 = useMediaQuery('(max-width:1024px)')
  const is768 = useMediaQuery('(max-width:768px)')
  const isMobile = useMediaQuery('(max-width:459px)')

  useEffect(() => {
    if (photos) {
      const lastIndex = photos.length - 1
      setSlideIndex(() => lastIndex)
      setSlides(() => photos)
      setActiveSlide(() => photos.length - 1)
    }
  }, [photos])

  useEffect(() => {
    if (isFullScreenGlobal) {
      setIsFullScreen(true)
    } else {
      setIsFullScreen(false)
    }
  }, [isFullScreenGlobal])

  if (photos.length === 0) {
    return null
  }

  return (
    <Box
      flex={1}
      display='flex'
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
      className={s()}
    >
      <CircularProgress
        sx={{ zIndex: 100, color: '#fff' }}
        className={loader ? s('show-loader') : s('hide-loader')}
      />
      <Box
        width={{
          xss: 'calc(100vw - 167px)',
          xs: 'calc(100vw - 167px)',
          sm: 'calc(100vw - 167px)',
          md: 'calc(100vw - 420px)',
          lg: 'calc(100vw - 410px)',
          xlg: 'calc(100vw - 725px)',
          xl: 'calc(100vw - 500px)',
          xl3: 'calc(100vw - 725px)',
          xll: 'calc(100vw - 1050px)',
          xxl: 'calc(100vw - 780px)',
        }}
        className={s({ full_screen: isFullScreen })}
      >
        {/*Слайдер при фулскрине*/}
        <FullScreen
          isFullScreen={isFullScreenDetail}
          onChange={(isFull) => toggleFullScreenDetail(isFull)}
        >
          <Swiper
            className='fullScreenSlide'
            style={{ display: isFullScreenDetail ? 'block' : 'none' }}
          >
            <SwiperSlide>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <img
                  src={photos[swiperRefTop?.activeIndex ?? 0]?.bigImg}
                  width='95%'
                  alt='img'
                  style={{ aspectRatio: '16/9' }}
                />
                <div style={{ position: 'absolute', bottom: 5, right: 10 }}>
                  <button
                    className='MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium btn-material'
                    type='button'
                    onClick={() =>
                      copyImageToClipboardWithFetch(
                        photos[swiperRefTop?.activeIndex ?? 0]?.bigImg,
                        setLoader
                      )
                    }
                  >
                    <svg
                      className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root'
                      focusable='false'
                      color='#fff'
                      aria-hidden='true'
                      viewBox='0 0 24 24'
                      data-testid='ScreenshotMonitorIcon'
                    >
                      <path d='M20 3H4c-1.11 0-2 .89-2 2v12c0 1.1.89 2 2 2h4v2h8v-2h4c1.1 0 2-.9 2-2V5c0-1.11-.9-2-2-2zm0 14H4V5h16v12z'></path>
                      <path d='M6.5 7.5H9V6H5v4h1.5zM19 12h-1.5v2.5H15V16h4z'></path>
                    </svg>
                    <span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
                  </button>
                  <button
                    className='MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium btn-material'
                    type='button'
                    onClick={() => {
                      toggleFullScreenDetail(false)
                    }}
                  >
                    <svg
                      className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root'
                      focusable='false'
                      color='#fff'
                      aria-hidden='true'
                      viewBox='0 0 24 24'
                      data-testid='FullscreenExitIcon'
                    >
                      <path d='M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z'></path>
                    </svg>
                    <span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
                  </button>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </FullScreen>

        {/*Слайдер верхний*/}

        <Swiper
          onSwiper={(slide) => {
            setSwiperRefTop(slide)
          }}
          // onSlideChange={slide => {
          //   const activeIndex = slide.activeIndex
          //   swiperRefBottom?.slideTo(activeIndex)
          //   swiperRefTop?.slideTo(activeIndex)
          //   swiperRefCenter?.slideTo(activeIndex)
          //   setSlideIndex(activeIndex)
          // }}
          centeredSlides={true}
          allowTouchMove={false}
          spaceBetween={30}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[Pagination, Navigation, Manipulation, Thumbs]}
          className='mySwiper'
          initialSlide={photos.length - 1}
          observer
          observeParents
        >
          {photos.map((item) => (
            <SwiperSlide key={item.date}>
              <PhotoTop
                item={item}
                setLoader={setLoader}
                setSlideIndexFullScreen={setSlideIndexFullScreen}
                swiperRefTopActiveIndex={swiperRefTop?.activeIndex}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Box
        className={s({ full_screen: isFullScreen })}
        width={{
          xss: 'calc(100vw - 167px)',
          xs: 'calc(100vw - 147px)',
          sm: 'calc(100vw - 147px)',
          md: 'calc(100vw - 430px)',
          lg: 'calc(100vw - 380px)',
          xlg: 'calc(100vw - 425px)',
          xl: 'calc(100vw - 450px)',
          xl3: 'calc(100vw - 455px)',
          xll: 'calc(100vw - 650px)',
          xxl: 'calc(100vw - 580px)',
        }}
      >
        {/*Полоса слайдера*/}
        <Box
          height={70}
          display={isFullScreen ? 'none' : 'block'}
        >
          <Swiper
            style={{ height: '100%' }}
            onSwiper={(slide) => {
              setSwiperRefCenter(slide)
              setThumbsSwiper(slide)
            }}
            breakpoints={{
              2048: {
                slidesPerView: 20,
              },
              1920: {
                slidesPerView: 10,
              },
              1536: {
                slidesPerView: 6,
              },
              1440: {
                slidesPerView: 7,
              },
              1024: {
                slidesPerView: 4,
              },
              768: {
                slidesPerView: 4,
              },
              320: {
                slidesPerView: 2,
              },
            }}
            centeredSlides={false}
            initialSlide={photos.length - 1}
            onClick={(slide) => {
              if (!isLoading && !loading) {
                swiperRefBottom?.slideTo(slide.clickedIndex)
                swiperRefCenter?.slideTo(slide.clickedIndex)
                swiperRefTop?.slideTo(slide.clickedIndex)
                setSlideIndex(slide.clickedIndex)
                setActiveSlide(slide.clickedIndex)
              }
            }}
            onInit={(slide) => {
              setSlideIndex(slide.activeIndex)
            }}
            //allowTouchMove={false}
            freeMode={false}
            observer
            grabCursor
            observeParents
            // onSlideChange={(slide) => {
            //   if(!isLoading && !loading) {
            //     swiperRefBottom?.slideTo(slide.activeIndex)
            //     swiperRefCenter?.slideTo(slide.activeIndex)
            //     swiperRefTop?.slideTo(slide.activeIndex)
            //     setSlideIndex(slide.activeIndex)
            //     setActiveSlide(slide.activeIndex)
            //   }
            // }}
            modules={[Pagination, Navigation, Thumbs, Virtual]}
          >
            {photos.map((item, index) => (
              <SwiperSlide
                key={item.date}
                virtualIndex={index}
              >
                <div className={s('date', { active: index === activeSlide })}>
                  {dayjs(item.date).format('DD.MM HH:mm')}
                </div>
                <div className={s('line', { active: index === activeSlide })} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>

        {/*Слайдер нижний */}
        <Swiper
          onSwiper={(slide) => {
            setSwiperRefBottom(slide)
            setThumbsSwiper(slide)
          }}
          breakpoints={{
            2048: {
              slidesPerView: 10,
            },
            1920: {
              slidesPerView: 9,
            },
            1536: {
              slidesPerView: 6,
            },
            1440: {
              slidesPerView: 7,
            },
            1024: {
              slidesPerView: 5,
            },
            968: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView: 4,
            },
            320: {
              slidesPerView: 2,
            },
          }}
          centeredSlides={false}
          initialSlide={photos.length - 1}
          onClick={(slide) => {
            swiperRefTop?.update()
            //swiperRefBottom?.slideTo(slide.clickedIndex)
            //swiperRefCenter?.slideTo(slide.clickedIndex)
            swiperRefTop?.slideTo(slide.clickedIndex)
            setSlideIndex(slide.clickedIndex)
            setActiveSlide(slide.clickedIndex)
          }}
          onInit={(slide) => {
            setSlideIndex(slide.activeIndex)
          }}
          freeMode={true}
          allowTouchMove={false}
          observer
          observeParents
          onSlideChange={(slide) => {
            const activeIndex = slide.activeIndex
            swiperRefBottom?.slideTo(activeIndex, 0)
            //swiperRefTop?.slideTo(activeIndex, 0)
            swiperRefCenter?.slideTo(activeIndex, 0)
            setSlideIndex(activeIndex)
            if (
              swiperRefBottom?.activeIndex &&
              swiperRefBottom?.activeIndex <= 4 &&
              !isLoading &&
              !loading
            ) {
              const lastDate = photos.at(0)?.date
              getPhotos({
                deviceId: id ?? '0',
                lastPhotoDate: lastDate ?? '',
                isNew: false,
              })
                .unwrap()
                .then(async (data) => {
                  if (data.length > 0) {
                    setLoading(true)
                    await asyncFunk(addPhotoBefore(data))
                    setLoading(false)
                    swiperRefTop?.update()
                    swiperRefBottom?.update()
                    swiperRefCenter?.update()
                    swiperRefBottom?.slideTo(activeIndex + data.length, 0)
                  }
                })
            }
          }}
          navigation={{ prevEl, nextEl }}
          modules={[Pagination, Navigation, Thumbs, Virtual]}
        >
          {photos.map((item, index) => (
            <SwiperSlide
              key={item.date}
              virtualIndex={index}
            >
              <PhotoBottom
                item={item}
                isActive={index === activeSlide}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        {/*Кнопки слайдера */}
        <Box
          width='100%'
          position='relative'
        >
          <div
            className={s('prev')}
            ref={(node) => setPrevEl(node)}
            onClick={() => {
              if (isLoading || loading) return
              swiperRefBottom?.slidePrev()
            }}
          >
            <ArrowBackIosIcon />
          </div>
          <div
            className={s('next')}
            ref={(node) => setNextEl(node)}
            onClick={() => {
              if (isLoading || loading) return
              swiperRefBottom?.slideNext()
            }}
          >
            <ArrowForwardIosIcon />
          </div>
        </Box>
      </Box>
    </Box>
  )
}

export default ImageSliderSwiper
