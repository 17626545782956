import { Message } from '@/modules/sidebar/types/notifications'
import { RootState } from '@/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: {
  messages: Message[]
  lastMessage: Message | null
  lastId: number
  countNotRead: number
} = {
  messages: [],
  lastId: 0,
  countNotRead: 0,
  lastMessage: null,
}

const messagesSlice = createSlice({
  name: 'messagesSlice',
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<Message[]>) => {
      state.messages = action.payload
    },
    setLastMessages: (state, action: PayloadAction<Message | null>) => {
      state.lastMessage = action.payload
    },
    setLastId: (state, action: PayloadAction<number>) => {
      state.lastId = action.payload
    },
    setCountNotRead: (state, action: PayloadAction<number>) => {
      state.countNotRead = action.payload
    },
    addNotification: (state, action: PayloadAction<Message[]>) => {
      state.messages = [...state.messages, ...action.payload]
    },
    markAsRead: (state, action: PayloadAction<number>) => {
      state.messages = state.messages.map((item) => {
        if (item.id === action.payload) {
          item.new = false
        }
        return item
      })
    },
    deleteNotification: (state, action: PayloadAction<number>) => {
      state.messages = state.messages.filter(
        (item) => item.id !== action.payload
      )
    },
    markAllAsRead: (state) => {
      state.messages = state.messages.map((item) => {
        item.new = false
        return item
      })
    },
    deleteAll: (state) => {
      state.messages = []
    },
  },
})

export const {
  setMessages,
  markAsRead,
  deleteNotification,
  addNotification,
  markAllAsRead,
  deleteAll,
  setLastId,
  setCountNotRead,
  setLastMessages,
} = messagesSlice.actions

export const messagesSelector = (state: RootState) => state.messagesSlice

export default messagesSlice.reducer
