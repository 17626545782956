import { api } from '@/api'
import { SearchTypeRes, SearchTypesReq } from '../types/searchTypes'

const searchOrganizations = api.injectEndpoints({
  endpoints: (build) => ({
    searchOrganizations: build.mutation<SearchTypeRes, SearchTypesReq>({
      query: (body) => ({
        url: '/organizations/search',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Organizations', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
})

export const { useSearchOrganizationsMutation } = searchOrganizations
