import { Box, CircularProgress } from '@mui/material'
import { FC } from 'react'

interface ILoader {}

const LoaderFullScreenBlur: FC<ILoader> = () => {
  return (
    <Box
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(255, 255, 255, .5)',
        transition: 'all 0.3s ease',
      }}
      display='flex'
      justifyContent='center'
      alignItems='center'
      position='absolute'
      width='100%'
      height='100%'
    >
      <CircularProgress color='primary' />
    </Box>
  )
}

export default LoaderFullScreenBlur
