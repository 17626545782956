import IconWarning from '@/components/Atoms/Icons/IconTriangleWarning'
import IconSuccessGreen from '@/components/Atoms/Icons/IconRoundSuccessGreen'
import IconError from '@/components/Atoms/Icons/IconTriangleError'
import { useAppSelector } from '@/hooks'
import { EventType } from '@/modules/events/types/eventsType'
import {
  useGetLastNotificationsQuery,
  useGetNotificationsMutation,
} from '@/modules/sidebar/services/notificationService'
import { messagesSelector } from '@/modules/sidebar/store/messagesSlice'
import cn from '@/utils/cn'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { Badge, IconButton } from '@mui/material'
import { Popover } from 'antd'
import * as React from 'react'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Content from './Content'

const s = cn('sidebar')

interface Props {}

const Notifications: FC<Props> = () => {
  const navigate = useNavigate()

  const { countNotRead, lastId, lastMessage } = useAppSelector(messagesSelector)

  const [getNotificationsService, { isLoading }] = useGetNotificationsMutation()

  useGetLastNotificationsQuery(
    { lastEventId: lastId },
    {
      pollingInterval: 60_000,
    }
  )

  const onGetNotifications = async () => {
    try {
      await getNotificationsService().unwrap()
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (lastMessage) {
      toast(`${lastMessage.moduleName}: ${lastMessage.message}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10_000,
        theme: 'light',
        icon: () =>
          lastMessage.type === EventType.system ? (
            <IconWarning />
          ) : lastMessage.type === EventType.warnings ? (
            <IconError />
          ) : (
            <IconSuccessGreen />
          ),
        onClick: () =>
          navigate(`/devices/${lastMessage.deviceId}`, {
            state: { eventId: lastMessage.id, deviceId: lastMessage.deviceId },
          }),
        type:
          lastMessage.type === EventType.system
            ? 'warning'
            : lastMessage.type === EventType.warnings
              ? 'error'
              : 'success',
      })
    }
  }, [lastMessage])

  return (
    <Popover
      className={s('notifications-popover')}
      content={
        <Content
          count={countNotRead}
          isLoading={isLoading}
        />
      }
      trigger='click'
      placement='bottomLeft'
    >
      <IconButton
        className={s('notifications')}
        onClick={onGetNotifications}
      >
        <Badge
          badgeContent={countNotRead}
          max={100_000}
          color={countNotRead > 0 ? 'red' : 'transparent'}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
    </Popover>
  )
}

export default Notifications
