import { Sidebar } from '@/modules'
import SidebarTanks from '@/modules/sidebar-aqua/Sidebar'
import MenuIcon from '@mui/icons-material/Menu'
import { Box, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'

const Layout = () => {
  const [isShowSideBar, setIsShowSidebar] = useState(false)
  const isRobertAqua = import.meta.env.VITE_APP_NAME === 'RobertAqua'
  return (
    <>
      <IconButton
        className='sidebar_button'
        onClick={() => setIsShowSidebar(true)}
      >
        <MenuIcon />
      </IconButton>
      <Box
        className={isRobertAqua ? 'layout layoutAqua' : 'layout'}
        display='flex'
        flexDirection='row'
      >
        {isRobertAqua ? (
          <SidebarTanks
            isShowSideBar={isShowSideBar}
            setIsShowSidebar={setIsShowSidebar}
          />
        ) : (
          <Sidebar
            isShowSideBar={isShowSideBar}
            setIsShowSidebar={setIsShowSidebar}
          />
        )}
        <Outlet />
      </Box>
    </>
  )
}

export default Layout
