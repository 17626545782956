import { useSearchEventsMutation } from '@/modules/events/services/eventsService'
import { Event, EventType } from '@/modules/events/types/eventsType'
import cn from '@/utils/cn'
import DvrIcon from '@mui/icons-material/Dvr'
import ErrorOutlineTwoToneIcon from '@mui/icons-material/ErrorOutlineTwoTone'
import WarningAmberTwoToneIcon from '@mui/icons-material/WarningAmberTwoTone'
import {
  CircularProgress,
  Pagination,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import * as React from 'react'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

const s = cn('events')

interface IUnitsTable {
  isSearch: boolean
  searchValue: string
  filter: number[]
  setSearchValue: Dispatch<SetStateAction<string>>
}

const EventsTable: FC<IUnitsTable> = ({
  isSearch,
  searchValue,
  filter,
  setSearchValue,
}) => {
  const [events, setEvents] = useState<Event[]>([])
  const [page, setPage] = React.useState(
    Number(localStorage.getItem('pageEvents')) || 1
  )
  const [pages, setPages] = React.useState(1)
  const [count, setCount] = React.useState(0)

  const [getEventsService, { isLoading: isLoadingSearch }] =
    useSearchEventsMutation()

  const handleChangePage = (event: unknown, newPage: number) => {
    localStorage.setItem('pageEvents', String(newPage))
    setPage(newPage)
  }

  const onGetUnits = async () => {
    try {
      const unitsSearchData = {
        value: !searchValue.trim().length ? '' : searchValue,
        eventTypeFilter: filter,
        page: page,
      }
      const res = await getEventsService(unitsSearchData).unwrap()
      if (res.success) {
        setEvents(res.data)
        setPage(res.page || 1)
        setPages(res.pages || 1)
        setCount(res.count ?? 0)
      }
      if (!searchValue.trim().length) {
        setSearchValue('')
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    onGetUnits().then()
  }, [page, isSearch, filter])

  const isTablet = useMediaQuery('(max-width:870px)')
  const isIphone = navigator.userAgent.includes('iPhone')

  return (
    <>
      <CircularProgress
        className={isLoadingSearch ? 'show-loader' : 'hide-loader'}
      />
      <Paper
        sx={{
          width: '100%',
          mt: 3,
          pb: 2,
        }}
        elevation={6}
        className={s({ loading: isLoadingSearch })}
      >
        <TableContainer
          sx={{
            height: `calc(100vh - ${
              isIphone
                ? document.documentElement.clientHeight - 300
                : isTablet
                  ? 370
                  : 192
            }px)`,
            overflowY: 'auto',
          }}
          className={s('tableContainer')}
        >
          <Table size='small'>
            <TableBody>
              {events.length === 0 ? (
                <TableRow>
                  <TableCell
                    component='th'
                    scope='row'
                  >
                    <Typography textAlign='center'>
                      Ни одной записи не найдено
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                events.map((event, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={index}
                      className={s()}
                    >
                      <TableCell padding='checkbox'>
                        {event.groupId === EventType.information ? (
                          <ErrorOutlineTwoToneIcon htmlColor='#0072BC' />
                        ) : event.groupId === EventType.system ? (
                          <DvrIcon htmlColor='#0072BC' />
                        ) : event.groupId === EventType.warnings ? (
                          <WarningAmberTwoToneIcon htmlColor='#ffeea3' />
                        ) : null}
                      </TableCell>
                      <TableCell
                        component='th'
                        scope='row'
                      >
                        <Stack direction='column'>
                          <span>{event.createDate}</span>
                          <span style={{ fontWeight: 'bold' }}>
                            {event.userName}
                          </span>
                        </Stack>
                      </TableCell>
                      <TableCell align='left'>{event.message}</TableCell>
                    </TableRow>
                  )
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={2} />
        <Box
          display='flex'
          width='100%'
          justifyContent='flex-end'
        >
          {count <= 25 ? null : (
            <Pagination
              count={pages}
              page={page}
              onChange={handleChangePage}
              size='small'
              siblingCount={1}
              boundaryCount={2}
              color={'primary'}
            />
          )}
        </Box>
      </Paper>
    </>
  )
}

export default EventsTable
