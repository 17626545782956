import { api } from '@/api'
import { UnitsTypesListRes } from '@/types/unitsTypesList'

const unitsListService = api.injectEndpoints({
  endpoints: (build) => ({
    getUnitsTypesList: build.query<
      UnitsTypesListRes,
      void | {
        isNew?: boolean
        isAll?: boolean
        isSmart?: boolean
        tsodd?: boolean
      }
    >({
      query: ({
        isAll,
        isSmart,
        isNew,
        tsodd,
      }: {
        isAll?: boolean
        isSmart?: boolean
        isNew?: boolean
        tsodd?: boolean
      }) => ({
        url: '/devices/getDeviceTypes',
        method: 'GET',
        params: {
          all: isAll ?? true,
          smart: isSmart ?? true,
          tsodd: tsodd ?? false,
        },
      }),
      transformResponse: (res: UnitsTypesListRes, meta, arg) => {
        if (res.success && !arg?.isNew) {
          res.data.unshift({ id: 0, name: 'Все' })
        }
        return res
      },
    }),
    getUnitsAquaTypesList: build.query<
      UnitsTypesListRes,
      void | { isNew?: boolean; isAll?: boolean; isSmart?: boolean }
    >({
      query: ({
        isAll,
        isSmart,
        isNew,
      }: {
        isAll?: boolean
        isSmart?: boolean
        isNew?: boolean
      }) => ({
        url: '/aquaDevices/getDeviceTypes',
        method: 'GET',
        params: {
          all: isAll ?? true,
          smart: isSmart ?? true,
        },
      }),
      transformResponse: (res: UnitsTypesListRes, meta, arg) => {
        if (res.success && !arg?.isNew) {
          res.data.unshift({ id: 0, name: 'Все' })
        }
        return res
      },
    }),
    getTsoddTypesList: build.query<
      UnitsTypesListRes,
      void | { isNew?: boolean }
    >({
      query: () => ({
        url: '/micsItems/getMicsItemTypes',
        method: 'GET',
      }),
      transformResponse: (res: UnitsTypesListRes, meta, arg) => {
        if (res.success && !arg?.isNew) {
          res.data.unshift({ id: 0, name: 'Все' })
        }
        return res
      },
    }),
  }),
  overrideExisting: false,
})

export const { useGetUnitsTypesListQuery, useGetUnitsAquaTypesListQuery } =
  unitsListService
