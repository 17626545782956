import { CHART_HEIGHT } from '@/constants'
import { useAppSelector } from '@/hooks'
import {
  modulesSelector,
  stateDeviceSelectorFromDate,
  stateDeviceSelectorSelected,
  stateDeviceSelectorSelectedForChat,
  stateDeviceSelectorSelectedMeteo,
  stateDeviceSelectorToDate,
} from '@/modules/device-module/store/stateSlice'
import { ChartDataInterface } from '@/modules/device-module/types/deviceType'
import { COLORS } from '@/utils'
import cn from '@/utils/cn'
import ChartRadioGroupItem from './ChartRadioGroupItem'
import { Box, CircularProgress, FormControl, RadioGroup } from '@mui/material'
import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Decimation,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip,
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import zoomPlugin from 'chartjs-plugin-zoom'
import dayjs from 'dayjs'
import _ from 'lodash'
import { memo, useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { useLocation, useParams } from 'react-router-dom'
import {
  useGetChartDataMutation,
  useGetDeviceDetailQuery,
} from '../../services/deviceService'
import { getChartOptions } from './chartHelpers'
const style = cn('chart')

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  zoomPlugin,
  TimeScale,
  Decimation
)

const Chart = () => {
  const { id } = useParams()
  const { pathname } = useLocation()

  const fromDate = useAppSelector(stateDeviceSelectorFromDate)
  const toDate = useAppSelector(stateDeviceSelectorToDate)
  const selected = useAppSelector(stateDeviceSelectorSelected)
  const selectedMeteo = useAppSelector(stateDeviceSelectorSelectedMeteo)
  const selectedDevice = useAppSelector(stateDeviceSelectorSelectedForChat)
  const modulesTable = useAppSelector(modulesSelector)

  const [getChartDataService, { isLoading }] = useGetChartDataMutation()
  const { data: dataDevice } = useGetDeviceDetailQuery(Number(id), {
    skip: pathname.includes('photos'),
  })

  const [radioValue, setRadioValue] = useState('1')

  const [sz, setSZ] = useState<any>([])
  const [vz, setVZ] = useState<any>([])
  const [modules, setModules] = useState<any>([])
  const [modulesActivity, setModulesActivity] = useState<any>([])
  const [modulesMeteo, setModulesMeteo] = useState<any>([])

  const [maxTemp, setMaxTemp] = useState(80)
  const [minTemp, setMinTemp] = useState(-40)

  const [maxWater, setMaxWater] = useState(20)
  const [minWater, setMinWater] = useState(0)

  const [maxV, setMaxV] = useState(36)
  const [minV, setMinV] = useState(0)

  const onGetChartData = async () => {
    try {
      if (dataDevice) {
        const data = {
          deviceId: id ?? '0',
          //deviceChart: dataDevice?.typeId === 4 ? false : selectedDevice,
          start: dayjs(fromDate).toISOString(),
          end: dayjs(toDate).toISOString(),
          moduleIds:
            Number(radioValue) === 2 && dataDevice?.typeId !== 4
              ? []
              : selected,
          columnType:
            dataDevice?.typeId === 4 || Number(radioValue) === 12
              ? Number(radioValue)
              : selectedDevice
                ? Number(radioValue)
                : null,
        }

        const res: ChartDataInterface = await getChartDataService({
          data,
          isMeteo: dataDevice?.typeId === 4,
        }).unwrap()
        if (res.data) {
          const z = res.data.map((item) => {
            return {
              x: Date.parse(item.d),
              y: item.v,
            }
          })
          const z2 = res.data.map((item) => {
            return {
              x: Date.parse(item.d),
              y: item.v / 100,
            }
          })
          if (radioValue === '1') {
            setSZ(z)
            const max = Math.max(...z.map((item: any) => item.y)) + 0.5
            const min = Math.min(...z.map((item: any) => item.y)) - 0.5
            setMaxV(+Number(max).toFixed(1))
            setMinV(+Number(min).toFixed(1))
          } else {
            setSZ([])
          }
          if (radioValue === '2') {
            setVZ(z2)
          } else {
            setVZ([])
          }
        } else {
          setSZ([])
          setVZ([])
        }
        if (res.modulesVoltage) {
          try {
            const maxValues: number[] = []
            const minValues: number[] = []
            const newModules = _.cloneDeep(res.modulesVoltage)
            newModules.forEach((item) => {
              const index = modulesTable?.findIndex((i) => i.id === item.id)
              item.borderColor =
                index !== undefined && index !== -1 ? COLORS[index] : 'black'
              const max = Math.max(...item.data?.map((item: any) => item.y)) + 1
              const min = Math.min(...item.data?.map((item: any) => item.y)) - 1
              maxValues.push(max)
              minValues.push(min)
            })
            const max = Math.max(...maxValues)
            const min = Math.min(...minValues)
            setMaxV((prev) => max)
            setMinV((prev) => min)
            if (radioValue === '1' || radioValue === '2') {
              setModules(newModules)
            } else {
              setModules([])
            }
          } catch (e) {
            console.error(e)
          }
        }
        if (!res.modulesVoltage) {
          setModules([])
        }
        if (res.modulesActivity) {
          try {
            const maxValues: number[] = []
            const minValues: number[] = []
            const newModules = _.cloneDeep(res.modulesActivity)
            newModules.forEach((item) => {
              const index = modulesTable?.findIndex((i) => i.id === item.id)
              item.borderColor =
                index !== undefined && index !== -1 ? COLORS[index] : 'black'
              if (item.data) {
                const max =
                  Math.max(...item.data?.map((item: any) => item.y)) + 1
                const min =
                  Math.min(...item.data?.map((item: any) => item.y)) - 1
                maxValues.push(max)
                minValues.push(min)
              }
            })
            if (newModules.some((item) => item.data)) {
              const max = Math.max(...maxValues)
              const min = Math.min(...minValues)
              setMaxV((prev) => max)
              setMinV((prev) => min)
            }
            if (radioValue === '1') {
              setModulesActivity(newModules)
            }
            if (radioValue === '12') {
              setModulesActivity(newModules)
            } else {
              setModulesActivity([])
            }
          } catch (e) {
            console.error(e)
          }
        }
        if (!res.modulesActivity) {
          setModulesActivity([])
        }

        if (res.modulesMeteo) {
          try {
            const newModules: any = _.cloneDeep(res.modulesMeteo)

            if (radioValue === '4') {
              newModules.data.forEach((item: any) => {
                item.y = item.y / 100
              })
              newModules.borderColor = '#000080'
              newModules.yAxisID = 'y2'
              newModules.label = 'Влажность'
              setModulesMeteo(newModules)
            }
            if (radioValue === '3' || radioValue === '5') {
              newModules.data.forEach((item: any) => {
                item.y = item.y
              })
              newModules.borderColor = 'red'
              newModules.yAxisID = 'y4'
              newModules.label = 'Температура'
              setModulesMeteo(newModules)
              const max =
                Math.max(...newModules.data.map((item: any) => item.y)) + 1
              const min =
                Math.min(...newModules.data.map((item: any) => item.y)) - 1
              setMaxTemp(+Number(max).toFixed(0))
              setMinTemp(+Number(min).toFixed(0))
            }
            if (radioValue === '6') {
              newModules.data.forEach((item: any) => {
                item.y = item.y
              })
              newModules.borderColor = '#000080'
              newModules.yAxisID = 'y9'
              newModules.label = 'Коэф.'
              setModulesMeteo(newModules)
            }
            if (
              radioValue === '7' ||
              radioValue === '8' ||
              radioValue === '9'
            ) {
              newModules.data.forEach((item: any) => {
                item.y = item.y
              })
              newModules.borderColor = '#000080'
              newModules.yAxisID = 'y10'
              newModules.label = 'Кол-во'
              setModulesMeteo(newModules)
              const max =
                Math.max(...newModules.data.map((item: any) => item.y)) + 1
              const min =
                Math.min(...newModules.data.map((item: any) => item.y)) - 1
              setMaxWater(+Number(max).toFixed(0))
              setMinWater(+Number(min).toFixed(0))
            }
            if (radioValue === '10') {
              newModules.data.forEach((item: any) => {
                item.y = item.y
              })
              newModules.borderColor = '#00ff00'
              newModules.yAxisID = 'y6'
              newModules.label = 'Кол-во'
              setModulesMeteo(newModules)
              const max =
                Math.max(...newModules.data.map((item: any) => item.y)) + 1
              const min =
                Math.min(...newModules.data.map((item: any) => item.y)) - 1
              setMaxWater(+Number(max).toFixed(0))
              setMinWater(+Number(min).toFixed(0))
            }
            if (radioValue === '11') {
              newModules.data.forEach((item: any) => {
                item.y = item.y
              })
              newModules.borderColor = '#ff8000'
              newModules.yAxisID = 'y7'
              newModules.label = 'Видимость'
              setModulesMeteo(newModules)
            }
            if (radioValue === '1' && dataDevice?.typeId === 4) {
              newModules.data.forEach((item: any) => {
                item.y = item.y
              })
              newModules.borderColor = '#0f8fff'
              newModules.yAxisID = 'y'
              newModules.label = 'Напряжение'
              setModulesMeteo(newModules)
              const max =
                Math.max(...newModules.data.map((item: any) => item.y)) + 0.5
              const min =
                Math.min(...newModules.data.map((item: any) => item.y)) - 0.5
              setMaxV(+Number(max).toFixed(1))
              setMinV(+Number(min).toFixed(1))
            }
            if (radioValue === '2' && dataDevice?.typeId === 4) {
              newModules.data.forEach((item: any) => {
                item.y = item.y / 100
              })
              newModules.borderColor = '#e450ed'
              newModules.yAxisID = 'y2'
              newModules.label = 'Уровень сигнала'
              setModulesMeteo(newModules)
            }
          } catch (e) {
            console.error(e)
          }
        }
        if (!res.modulesMeteo) {
          setModulesMeteo([])
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    onGetChartData().then()
  }, [fromDate, toDate, selected, selectedDevice, radioValue, dataDevice])

  useEffect(() => {
    if (selectedMeteo === 4) {
      setRadioValue('11')
    }
    if (selectedMeteo === 3) {
      setRadioValue('10')
    }
    if (selectedMeteo === 2) {
      setRadioValue('5')
    }
    if (selectedMeteo === 1) {
      setRadioValue('3')
    }
    if (selectedMeteo === 0) {
      setRadioValue('1')
    }
  }, [selectedMeteo])

  const options = getChartOptions({
    radioValue,
    minV,
    maxV,
    maxTemp,
    minTemp,
    maxWater,
    minWater,
  })

  const dataSets: ChartData<'line'> = {
    //labels,
    datasets: [
      {
        label: 'Напряжение питания',
        data: sz,
        borderColor: '#0f8fff',
        tension: 0.3,
        pointRadius: 3,
        pointBackgroundColor: '#fff',
        yAxisID: 'y',
        indexAxis: 'x',
      },
      {
        label: 'Уровень сигнала',
        data: vz,
        borderColor: '#e450ed',
        tension: 0.3,
        pointRadius: 3,
        pointBackgroundColor: '#fff',
        yAxisID: 'y2',
        indexAxis: 'x',
      },
      ...modules,
      ...modulesActivity,
      modulesMeteo,
    ],
  }

  return (
    <>
      <Box
        height={{ xss: 77, md: 28 }}
        width='100%'
        bgcolor='#fff'
        display='flex'
        justifyContent='center'
        //position='absolute'
      >
        <FormControl>
          <RadioGroup
            row
            defaultValue='1'
            value={radioValue}
            onChange={(e, val) => {
              setRadioValue(val)
            }}
          >
            <ChartRadioGroupItem
              deviceId={dataDevice?.typeId}
              selectedMeteo={selectedMeteo}
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box
        width='100%'
        height={{ xss: '25vh', sm: CHART_HEIGHT - 90 }}
        bgcolor='#ffffff'
        className={style()}
      >
        <CircularProgress
          className={isLoading ? style('show-loader') : style('hide-loader')}
        />
        <Line
          options={options}
          data={dataSets}
          className={style('chart', { loading: isLoading })}
        />
      </Box>
    </>
  )
}

export default memo(Chart)
