import IconWarning from '@/components/Atoms/Icons/IconTriangleWarning'
import IconSuccess from '@/components/Atoms/Icons/IconRoundSuccess'
import IconError from '@/components/Atoms/Icons/IconTriangleError'
import photo from '@/assets/img/icons8-camera-80 (1).png'
import video from '@/assets/img/icons8-camera-80.png'
import proc from '@/assets/img/processor.png'
import tsoddImg from '@/assets/img/rhombus.png'
import cn from '@/utils/cn'
import Box from '@mui/material/Box'

import Paper from '@mui/material/Paper'
import * as React from 'react'
import { FC, memo } from 'react'
import { useParams } from 'react-router-dom'
import Location from './Location'
import DevicesStatus from './DevicesStatus'
const s = cn('bottom-panel')
import { TotalSmartDetailsType } from './DevicesStatus'
interface Props {
  isMap?: boolean
  totalErrors?: number
  totalServiceable?: number
  totalWarnings?: number
  totalOk?: number
  totalVideo?: number
  totalPhoto?: number
  totalNotSmart?: number
  totalSmartCount?: number
  totalSmartDetails?: TotalSmartDetailsType
}

const BottomPanel: FC<Props> = ({
  isMap,
  totalSmartCount,
  totalServiceable,
  totalWarnings,
  totalNotSmart,
  totalErrors,
  totalOk,
  totalPhoto,
  totalVideo,
  totalSmartDetails,
}) => {
  const { id } = useParams()

  return (
    <Paper
      elevation={6}
      sx={{
        position: isMap ? 'absolute' : 'unset',
        bottom: 0,
        borderRadius: isMap ? 0 : 0,
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        boxShadow: 'unset',
        //backgroundImage: `url(${bg})`,
        color: '#fff',
      }}
    >
      <Box
        width='100%'
        display='flex'
        justifyContent='flex-end'
        //alignItems='center'
        p={1}
        gap={{ xss: 1, md: 3 }}
        minWidth={400}
        className={s()}
      >
        {totalSmartCount && (
          <DevicesStatus
            total={totalSmartCount}
            totalDetails={totalSmartDetails}
          />
        )}
        {id ? null : (
          <Location
            isLocation={
              (totalPhoto !== 0 && totalVideo !== 0) ||
              (totalPhoto !== 0 && totalNotSmart !== 0) ||
              (totalPhoto !== 0 && totalSmartCount !== 0) ||
              (totalVideo !== 0 && totalPhoto !== 0) ||
              (totalVideo !== 0 && totalSmartCount !== 0) ||
              (totalVideo !== 0 && totalNotSmart !== 0) ||
              (totalNotSmart !== 0 && totalPhoto !== 0) ||
              (totalNotSmart !== 0 && totalVideo !== 0) ||
              (totalNotSmart !== 0 && totalSmartCount !== 0) ||
              (totalSmartCount !== 0 && totalNotSmart !== 0) ||
              (totalSmartCount !== 0 && totalPhoto !== 0) ||
              (totalSmartCount !== 0 && totalVideo !== 0)
            }
            totalPhoto={totalPhoto}
            totalVideo={totalVideo}
            totalNotSmart={totalNotSmart}
            totalModulesCount={totalSmartCount}
          />
        )}
        {totalNotSmart === undefined ||
        totalVideo === 0 ||
        totalNotSmart !== 0 ||
        totalPhoto !== 0 ||
        totalSmartCount !== 0 ? null : (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            gap={1}
            flexWrap='wrap'
          >
            <img
              width={30}
              height={30}
              src={video}
              alt='video'
            />
            <span style={{ textAlign: 'center' }}>{totalVideo} шт.</span>
          </Box>
        )}
        {totalNotSmart === undefined ||
        totalPhoto === 0 ||
        totalNotSmart !== 0 ||
        totalVideo !== 0 ||
        totalSmartCount !== 0 ? null : (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            gap={1}
            flexWrap='wrap'
          >
            <img
              width={30}
              height={30}
              src={photo}
              alt='photo'
            />
            <span style={{ textAlign: 'center' }}>{totalPhoto} шт.</span>
          </Box>
        )}
        {totalNotSmart === undefined ||
        totalNotSmart === 0 ||
        totalPhoto !== 0 ||
        totalVideo !== 0 ||
        totalSmartCount !== 0 ? null : (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            gap={1}
            flexWrap='wrap'
          >
            <img
              width={30}
              height={30}
              src={tsoddImg}
              alt='tsodd'
            />
            <span style={{ textAlign: 'center' }}>{totalNotSmart} шт.</span>
          </Box>
        )}
        {totalSmartCount === undefined ||
        totalSmartCount === 0 ||
        totalPhoto !== 0 ||
        totalVideo !== 0 ||
        totalNotSmart !== 0 ? null : (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            gap={1}
            flexWrap='wrap'
          >
            <img
              width={30}
              height={30}
              src={proc}
              alt='processor'
            />
            <span style={{ textAlign: 'center' }}>{totalSmartCount} шт.</span>
          </Box>
        )}
        {totalServiceable === undefined ? null : (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            gap={1}
            flexWrap='wrap'
          >
            <IconSuccess />
            <span style={{ textAlign: 'center' }}>{totalServiceable} шт.</span>
          </Box>
        )}
        {totalWarnings === undefined || totalWarnings === 0 ? null : (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            gap={1}
            flexWrap='wrap'
          >
            <IconWarning />
            <span style={{ textAlign: 'center' }}>{totalWarnings} шт.</span>
          </Box>
        )}
        {totalErrors === undefined || totalErrors === 0 ? null : (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            gap={1}
            flexWrap='wrap'
          >
            <IconError />
            <span style={{ textAlign: 'center' }}>{totalErrors} шт.</span>
          </Box>
        )}
      </Box>
    </Paper>
  )
}

export default memo(BottomPanel)
