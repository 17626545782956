import { notifySuccess } from '@/components'
import { useActions, useAppSelector } from '@/hooks'
import { CommandItem } from '@/modules/device-module/components/terminal/CommandItem'
import { useAddTerminalCommandsMutation } from '@/modules/device-module/services/deviceService'
import { stateDeviceSelector } from '@/modules/device-module/store/stateSlice'
import { terminalCommandsSelector } from '@/modules/device-module/store/terminalNewSlice'
import cn from '@/utils/cn'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import ClearIcon from '@mui/icons-material/Clear'
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  TextField,
} from '@mui/material'
import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

const s = cn('terminal-commands')

interface Props {
  setValue: Dispatch<SetStateAction<string>>
}

const Commands = ({ setValue }: Props) => {
  const { isOnChange } = useAppSelector(stateDeviceSelector)
  const commands = useAppSelector(terminalCommandsSelector)
  const messageListRef = useRef<any>(null)

  const { setCommands, removeCommandNew, triggerLoadHistory } = useActions()

  const { id } = useParams()

  const [addCommandService, { isLoading }] = useAddTerminalCommandsMutation()

  const [command, setCommand] = React.useState('')
  const [selected, setSelected] = React.useState<number | null>(null)
  const [isAdd, setIsAdd] = React.useState(false)

  const scrollToBottom = () => {
    const messageList = messageListRef.current
    if (messageList) {
      messageList.scrollTop = messageList.scrollHeight
    }
  }

  const onSubmit = async () => {
    try {
      if (command.trim() === '') {
        return
      }
      const res = await addCommandService({
        text: command,
        deviceId: Number(id),
      }).unwrap()
      triggerLoadHistory()
      if (res.success) {
        notifySuccess('Команда добавлена')
        setCommand('')
        setIsAdd((prev) => !prev)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    itemId: number
  ) => {
    const updatedItems = commands?.map((item) => {
      if (item.id === itemId) {
        return { ...item, text: event.target.value }
      }
      return item
    })
    if (updatedItems) {
      setCommands(updatedItems)
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [isAdd])

  return (
    <Box
      flex={1}
      display='flex'
      flexDirection='column'
    >
      <Paper
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          py: 1,
          gap: { xss: 0.5, lg: 1 },
        }}
      >
        <Box
          flex={1}
          overflow='auto'
          justifyContent='space-between'
          px={1}
          display='flex'
          maxHeight={{ xss: 'calc(100vh - 650px)', lg: 'calc(100vh - 371px)' }}
          ref={messageListRef}
        >
          <Box
            flex={1}
            display='flex'
            gap={2}
            flexDirection='column'
          >
            <Box
              display='flex'
              gap={2}
              flexDirection='column'
            >
              {commands?.map((item, index) => (
                <React.Fragment key={item.id}>
                  {isOnChange ? (
                    <Box
                      key={item.id}
                      sx={{ lineBreak: 'anywhere', cursor: 'pointer' }}
                      onClick={() => setSelected(item.id)}
                    >
                      {selected === item.id ? (
                        <Box
                          display='flex'
                          alignItems='center'
                          width='98%'
                          sx={{ lineBreak: 'anywhere', cursor: 'pointer' }}
                        >
                          <TextField
                            size='small'
                            fullWidth
                            disabled={isLoading}
                            value={item.text}
                            onChange={(e) => {
                              handleNameChange(e, item.id)
                            }}
                          />
                          <IconButton
                            edge='end'
                            onClick={() => {
                              removeCommandNew(index)
                            }}
                          >
                            <ClearIcon htmlColor='red' />
                          </IconButton>
                        </Box>
                      ) : (
                        <span>{item.text}</span>
                      )}
                    </Box>
                  ) : (
                    <CommandItem
                      key={item.id}
                      item={item}
                      setValue={setValue}
                    />
                  )}
                </React.Fragment>
              ))}
            </Box>
          </Box>
        </Box>
        {isOnChange ? (
          <Box
            display='flex'
            gap={1}
            alignItems='center'
            px={1}
          >
            <TextField
              size='small'
              fullWidth
              placeholder='Введите команду'
              disabled={isLoading}
              value={command}
              onChange={(e) => setCommand(e.target.value)}
            />
            {isLoading ? (
              <CircularProgress
                size={25}
                sx={{ mx: '9px', my: '15px' }}
              />
            ) : (
              <IconButton onClick={onSubmit}>
                <AddCircleIcon
                  htmlColor='green'
                  fontSize='large'
                />
              </IconButton>
            )}
          </Box>
        ) : null}
      </Paper>
    </Box>
  )
}

export default Commands
