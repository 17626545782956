import { PhotoItem } from '@/modules/device-module/types/deviceType'
import { RootState } from '@/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: { photos: PhotoItem[] } = {
  photos: [],
}

const photoSliderSlice = createSlice({
  name: 'photoSlider',
  initialState,
  reducers: {
    setPhotos: (state, action: PayloadAction<PhotoItem[]>) => {
      state.photos = action.payload
    },
    addPhotoBefore: (state, action: PayloadAction<PhotoItem[]>) => {
      state.photos = [...action.payload, ...state.photos]
    },
    addPhotoAfter: (state, action: PayloadAction<PhotoItem[]>) => {
      state.photos = [...state.photos, ...action.payload]
    },
  },
})

export const { setPhotos, addPhotoBefore, addPhotoAfter } =
  photoSliderSlice.actions

export const photosSelector = (state: RootState) => state.photoSlider.photos

export default photoSliderSlice.reducer
