import { useActions, useAppSelector, useOnClickOutside } from '@/hooks'
import { BottomMenu } from '@/modules/sidebar/components/BottomMenu/BottomMenu'
import { UserAvatar } from '@/modules/sidebar/components/UserAvatar/UserAvatar'
import { isChangeModulesSelector } from '@/store/changeDialogSlise'
import cn from '@/utils/cn'
import { Box, List, ListItemButton, ListItemText } from '@mui/material'
import { Dispatch, FC, SetStateAction, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import FavoritesList from './components/Favorites/FavoritesList'

const s = cn('sidebar')

interface ISidebar {
  isShowSideBar: boolean
  setIsShowSidebar: Dispatch<SetStateAction<boolean>>
}

const Sidebar: FC<ISidebar> = ({ isShowSideBar, setIsShowSidebar }) => {
  const {
    isChangeModules,
    isChangeDescription,
    isChangeFeatures,
    isChangeUser,
    isChangeOrganization,
  } = useAppSelector(isChangeModulesSelector)
  const {
    setIsOpenChangeModulesDialog,
    setIsOpenChangeOrganizationDialog,
    setIsOpenChangeUserDialog,
    setIsOpenChangeDescriptionDialog,
    setIsOpenChangeFeaturesDialog,
  } = useActions()

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const ref = useRef(null)
  const isIphone = navigator.userAgent.includes('iPhone')

  useOnClickOutside(ref, () => setIsShowSidebar(false))

  const onNavigate = () => {
    if (isChangeModules) {
      setIsOpenChangeModulesDialog(true)
      return
    }
    if (isChangeDescription) {
      setIsOpenChangeDescriptionDialog(true)
      return
    }
    if (isChangeFeatures) {
      setIsOpenChangeFeaturesDialog(true)
      return
    }
    if (isChangeUser) {
      setIsOpenChangeUserDialog(true)
      return
    }
    if (isChangeOrganization) {
      setIsOpenChangeOrganizationDialog(true)
      return
    }
    setIsShowSidebar(false)
    if (pathname === '/') {
      navigate('/devices')
    } else {
      navigate('/')
    }
  }

  return (
    <Box
      ref={ref}
      maxWidth={{ xss: 200, xs: 240, xl: 300, xxl: 400 }}
      className={isShowSideBar ? 'sidebar sidebar_show' : 'sidebar'}
      width='100%'
      pt={2}
      pb={2}
      display='flex'
      flexDirection='column'
      alignItems='center'
      style={{ height: isIphone ? document.documentElement.clientHeight : '' }}
    >
      <UserAvatar setIsShowSidebar={setIsShowSidebar} />
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        mb={1}
      >
        <List sx={{ width: '100%' }}>
          <ListItemButton
            className={s('nav-btn')}
            alignItems='center'
            onClick={onNavigate}
          >
            <ListItemText
              style={{ color: 'white' }}
              sx={{ display: 'flex', justifyContent: 'center' }}
              primary={
                import.meta.env.VITE_APP_NAME === 'RobertAqua'
                  ? pathname === '/'
                    ? 'К списку резервуаров'
                    : 'К резервуарам на карте'
                  : pathname === '/'
                    ? 'К списку систем'
                    : 'К системам на карте'
              }
            />
          </ListItemButton>
        </List>
      </Box>
      <FavoritesList />
      <BottomMenu setIsShowSidebar={setIsShowSidebar} />
    </Box>
  )
}

export default Sidebar
