import React, { Dispatch, FC, SetStateAction, useEffect } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as Yup from 'yup'
import { useChangeSet } from '@/utils/useChangeSet'
import Input from '@/modules/user/components/Input/Input'
import cn from '@/utils/cn'
import { useChangeUserPasswordMutation } from '@/modules/user/services/userService'
import SaveIcon from '@mui/icons-material/Save'
import { notifySuccess } from '@/components'

const s = cn('user')

interface IDialog {
  open: boolean
  handleClose: () => void
  userId: number
  setLoader: Dispatch<SetStateAction<boolean>>
}

interface IChangePass {
  password: string
  confirmPassword: string
}

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Пароль не может быть пустым')
    .min(6, 'Введите пароль от 6-ти до 20-ти символов')
    .max(20, 'Введите пароль от 6-ти до 20-ти символов'),
  confirmPassword: Yup.string()
    .required('Пароль не может быть пустым')
    .oneOf(
      [Yup.ref('password'), null],
      'Пароли не совпадают. Повторите попытку'
    ),
})

const ChangePassDialog: FC<IDialog> = ({
  open,
  handleClose,
  userId,
  setLoader,
}) => {
  const [changePasswordService, { isLoading: isLoadingChangePassService }] =
    useChangeUserPasswordMutation()

  const defaultValues: IChangePass = {
    password: '',
    confirmPassword: '',
  }

  const { handleSubmit, control, reset, watch, trigger } = useForm<IChangePass>(
    {
      defaultValues,
      resolver: yupResolver(validationSchema),
      reValidateMode: 'onChange',
    }
  )

  const onSubmit = async (data: IChangePass) => {
    try {
      setLoader(true)
      const res = await changePasswordService({ id: userId, ...data }).unwrap()
      if (res.success) {
        notifySuccess('Пароль изменен')
      }
      setLoader(false)
      handleClose()
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    reset({
      password: '',
      confirmPassword: '',
    })
  }, [open])

  useChangeSet(() => {
    if (watch('confirmPassword').length) {
      trigger('confirmPassword')
    }
  }, [watch('password')])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={s('change-pass-dialog')}
    >
      <DialogTitle>Введите новый пароль</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            px={0}
            width={{ xss: 230, sm: 330, md: 350 }}
          >
            <Input
              control={control}
              name='password'
              type='password'
              label='Введите пароль'
              disabled={isLoadingChangePassService}
            />
            <Box mt={{ xss: 2, xxl: 4 }} />
            <Input
              control={control}
              name='confirmPassword'
              type='password'
              label='Повторите пароль'
              disabled={isLoadingChangePassService}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant='outlined'
            color='error'
          >
            Закрыть
          </Button>
          <LoadingButton
            variant='outlined'
            autoFocus
            loading={isLoadingChangePassService}
            loadingPosition='start'
            type='submit'
            startIcon={<SaveIcon />}
          >
            <span>Сохранить</span>
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ChangePassDialog
