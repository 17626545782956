import { toast } from 'react-toastify'

export const notifySuccess = (message: string, delay?: number) => {
  toast.success(message, {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: delay || 1500,
  })
}

export const notifyError = (message: string) => {
  toast.error(message, {
    autoClose: 10000,
    position: toast.POSITION.BOTTOM_RIGHT,
  })
}

export const notifyWarning = (message: string, delay: number) => {
  toast.warning(message, {
    autoClose: delay || 2000,
    position: toast.POSITION.BOTTOM_RIGHT,
  })
}
