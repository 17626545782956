export const consoleWarn = console.error
const SUPPRESSED_WARNINGS = [
  'Invariant failed: Draggable[id:',
  'MUI: The `value` provided to the Tabs component is invalid.',
]

console.error = function filterWarnings(msg, ...args) {
  if (!SUPPRESSED_WARNINGS.some((entry) => msg?.toString().includes(entry))) {
    consoleWarn(msg, ...args)
  }
}
