import {
  CommandNew,
  TerminalHistoryNew,
} from '@/modules/device-module/types/terminal-new'
import { RootState } from '@/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

const initialState: {
  history: TerminalHistoryNew[]
  commands: CommandNew[]
  lastUpdatedDate: string
  time: string
  triggerLoadHistory: boolean
} = {
  history: [],
  commands: [],
  lastUpdatedDate: new Date('1900').toISOString(),
  time: dayjs(new Date('1900').toISOString()).format('HH:mm:ss'),
  triggerLoadHistory: false,
}

const terminalNewSlice = createSlice({
  name: 'terminal-new',
  initialState,
  reducers: {
    setTerminalHistoryNew: (
      state,
      action: PayloadAction<TerminalHistoryNew[]>
    ) => {
      state.history = action.payload
    },
    addOneTerminalHistoryNew: (
      state,
      action: PayloadAction<TerminalHistoryNew>
    ) => {
      state.history.push(action.payload)
    },
    addOneTerminalHistoryFromApi: (
      state,
      action: PayloadAction<TerminalHistoryNew[]>
    ) => {
      state.history = [...state.history, ...action.payload]
    },
    updateTerminalHistoryStatus: (
      state,
      action: PayloadAction<{ index: number; id: 1 | 2 | 3 }>
    ) => {
      state.history[action.payload.index].typeId = action.payload.id
    },
    setLastUpdatedDate: (state, action: PayloadAction<string>) => {
      state.lastUpdatedDate = action.payload
      state.time = dayjs(action.payload).format('HH:mm:ss')
    },
    setCommands: (state, action: PayloadAction<CommandNew[]>) => {
      state.commands = action.payload
    },
    removeCommandNew: (state, action: PayloadAction<number>) => {
      state.commands.splice(action.payload, 1)
    },
    updateHistory: (state, action: PayloadAction<TerminalHistoryNew[]>) => {
      const merged = new Map(state.history.map((item) => [item.id, item]))
      action.payload.forEach((newItem) => {
        const existingItem = merged.get(newItem.id)
        if (existingItem) {
          existingItem.typeId = newItem.typeId
        } else {
          merged.set(newItem.id, newItem)
        }
      })
      state.history = Array.from(merged.values())
    },
    triggerLoadHistory: (state) => {
      state.triggerLoadHistory = !state.triggerLoadHistory
    },
  },
})

export const {
  setTerminalHistoryNew,
  addOneTerminalHistoryNew,
  setLastUpdatedDate,
  removeCommandNew,
  setCommands,
  updateTerminalHistoryStatus,
  addOneTerminalHistoryFromApi,
  updateHistory,
  triggerLoadHistory,
} = terminalNewSlice.actions

export const terminalHistoryNewSelector = (state: RootState) =>
  state.terminalNew.history
export const terminalCommandsSelector = (state: RootState) =>
  state.terminalNew.commands
export const terminalLastUpdatedDateSelector = (state: RootState) =>
  state.terminalNew.lastUpdatedDate
export const terminalTimeSelector = (state: RootState) => state.terminalNew.time
export const triggerLoadHistorySelector = (state: RootState) =>
  state.terminalNew.triggerLoadHistory

export default terminalNewSlice.reducer
