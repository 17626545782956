import errorYellow from '@/assets/img/icons8-внимание-48.png'
import errorImg from '@/assets/img/icons8-высокий-приоритет-48.png'
import Tooltip from '@mui/material/Tooltip'
import * as React from 'react'

const InfoIcon = ({
  type,
  size = 30,
  tooltip,
}: {
  type: 'error' | 'warning' | 'ok'
  size?: number
  tooltip?: string
}) => {
  if (type === 'ok') {
    return null
  }
  return (
    <Tooltip
      title={
        tooltip
          ? tooltip
          : type === 'error'
            ? 'Система неисправна'
            : 'Ошибки в работе системы'
      }
      placement='top'
    >
      <img
        src={type === 'error' ? errorImg : errorYellow}
        alt='Ошибка'
        width={size}
        height={size}
      />
    </Tooltip>
  )
}

export default InfoIcon
