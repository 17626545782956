import { useGetNewUnitsListMutation } from '@/modules/new-units/services/newUnitsListService'
import { NewUnit } from '@/modules/new-units/types/newUnitsType'
import cn from '@/utils/cn'
import { CircularProgress, Pagination, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import * as React from 'react'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const s = cn('new-units')

interface IUsersTable {
  isSearch: boolean
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
}

const NewUnitsTable: FC<IUsersTable> = ({
  isSearch,
  searchValue,
  setSearchValue,
}) => {
  const navigate = useNavigate()

  const [getUnitsService, { isLoading: isLoadingSearch }] =
    useGetNewUnitsListMutation()

  const [units, setUnits] = useState<NewUnit[]>([])
  const [page, setPage] = React.useState(
    Number(localStorage.getItem('pageNewUnits')) || 1
  )
  const [pages, setPages] = React.useState(1)
  const [count, setCount] = React.useState(0)

  const handleChangePage = (event: unknown, newPage: number) => {
    localStorage.setItem('pageNewUnits', String(newPage))
    setPage(newPage)
  }

  const onGetUnits = async () => {
    try {
      const usersSearchData = {
        value: !searchValue.trim().length ? '' : searchValue,
        page: page,
        newSearch: true,
      }
      const res = await getUnitsService(usersSearchData).unwrap()
      if (res.success) {
        setUnits(res.data)
        setPage(res.page || 1)
        setPages(res.pages || 1)
        setCount(res.count ?? 0)
      }
      if (!searchValue.trim().length) {
        setSearchValue('')
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    onGetUnits().then()
  }, [page, isSearch])

  const isIphone = navigator.userAgent.includes('iPhone')
  const isOldIphone = navigator.userAgent.includes('OS 15')

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      position='relative'
      className={s('container')}
      bgcolor='transparent'
    >
      <CircularProgress
        className={isLoadingSearch ? s('show-loader') : s('hide-loader')}
      />
      <Box
        width='100%'
        height='100%'
        className={isLoadingSearch ? s('table-loading') : ''}
        bgcolor='transparent'
      >
        <Paper
          sx={{
            width: '100%',
            height:
              isIphone && isOldIphone
                ? 'calc(100% - 54px);'
                : isIphone
                  ? 'calc(100% - 74px);'
                  : 'calc(100% - 54px);',
            mt: 3,
            pb: 2,
            backgroundColor: '#fff',
          }}
          elevation={6}
        >
          <TableContainer
            sx={{
              height: '95%',
              overflowY: 'auto',
              backgroundColor: '#fff',
            }}
            className={s('tableContainer')}
          >
            <Table size='small'>
              <TableBody>
                {units.length === 0 ? (
                  <TableRow>
                    <TableCell
                      component='th'
                      scope='row'
                    >
                      <Typography textAlign='center'>
                        Ни одной записи не найдено
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  units.map((unit) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={unit.id}
                        className={s()}
                      >
                        <TableCell
                          component='th'
                          scope='row'
                          sx={{ width: 200 }}
                        >
                          <Link
                            to={`/new-units/${unit.id}`}
                            className={s('link')}
                          >
                            {unit.createDate || '---'}
                          </Link>
                        </TableCell>
                        <TableCell
                          align='left'
                          onClick={() => navigate(`/new-units/${unit.id}`)}
                          sx={{
                            color: '#0072BC',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                          }}
                        >
                          {unit.name}
                        </TableCell>
                      </TableRow>
                    )
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={2} />
          <Box
            display='flex'
            width='100%'
            justifyContent='flex-end'
          >
            {count <= 25 ? null : (
              <Pagination
                count={pages}
                page={page}
                onChange={handleChangePage}
                size='small'
                siblingCount={1}
                boundaryCount={2}
                color={'primary'}
              />
            )}
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}

export default NewUnitsTable
