export const signal = (signal: number) => {
  if (signal >= 0 && signal <= 38) return 'low'
  if (signal >= 39 && signal <= 66) return 'medium'
  if (signal >= 67 && signal <= 100) return 'high'
  return 'low'
}

export enum VoltageEnum {
  min = 16,
  max = 50,
}

export const voltage12 = (voltage: number) => {
  const num = Number(voltage)
  if (num >= 10.5 && num <= 11.599) return 'low'
  if (num >= 11.6 && num <= 12.799) return 'medium'
  if (num >= 12.8 && num <= 16) return 'high'
  return 'low'
}

export const voltage24 = (voltage: number) => {
  const num = Number(voltage)
  if (num >= 16.1 && num <= 22.999) return 'low'
  if (num >= 23 && num <= 25.999) return 'medium'
  if (num >= 26 && num <= 30) return 'high'
  if (num > 30) return 'high'
  return 'low'
}
