import { useActions } from '@/hooks'
import {
  useDeletedAllNotificationsMutation,
  useReadAllNotificationsMutation,
} from '@/modules/sidebar/services/notificationService'
import { LoadingButton } from '@mui/lab'
import { Box, CircularProgress } from '@mui/material'
import { FC } from 'react'
import NotificationsList from './NotificationsList'

interface Props {
  count: number
  isLoading: boolean
}

const Content: FC<Props> = ({ count, isLoading }) => {
  const [readNotificationsService, { isLoading: isLoadingRead }] =
    useReadAllNotificationsMutation()
  const [deleteNotificationsService, { isLoading: isLoadingDelete }] =
    useDeletedAllNotificationsMutation()

  const { markAllAsRead, deleteAll } = useActions()

  const onReadNotification = async () => {
    try {
      await readNotificationsService().unwrap()
      markAllAsRead()
    } catch (e) {}
  }

  const onDeleteNotification = async () => {
    try {
      await deleteNotificationsService().unwrap()
      deleteAll()
    } catch (e) {}
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent={isLoading ? 'center' : 'unset'}
      alignItems={isLoading ? 'center' : 'unset'}
      width={isLoading ? 476 : 'fit-content'}
      height={isLoading ? 345 : 'fit-content'}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {count > 0 ? (
            <>
              <NotificationsList />
              <Box
                display='flex'
                gap={30}
                p={1}
                pt={0}
                justifyContent='space-between'
              >
                <LoadingButton
                  variant='outlined'
                  loading={isLoadingRead}
                  onClick={onReadNotification}
                >
                  Прочитать все
                </LoadingButton>
                <LoadingButton
                  variant='outlined'
                  loading={isLoadingDelete}
                  onClick={onDeleteNotification}
                >
                  Очистить
                </LoadingButton>
              </Box>
            </>
          ) : (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              height={57}
              px={3}
            >
              Нет уведомлений
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export default Content
