import { useActions, useAppSelector } from '@/hooks'
import { useGetUserProfileQuery } from '@/modules/user/services/userService'
import { isChangeModulesSelector } from '@/store/changeDialogSlise'
import { UserTypes } from '@/types/typeUser'
import cn from '@/utils/cn'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import GroupIcon from '@mui/icons-material/Group'
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined'
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const s = cn('bottom-menu')

interface IBottomMenu {
  setIsShowSidebar: Dispatch<SetStateAction<boolean>>
}

export const BottomMenu: FC<IBottomMenu> = ({ setIsShowSidebar }) => {
  const {
    isChangeModules,
    isChangeDescription,
    isChangeFeatures,
    isChangeUser,
    isChangeOrganization,
  } = useAppSelector(isChangeModulesSelector)
  const {
    setIsOpenChangeModulesDialog,
    setIsOpenChangeOrganizationDialog,
    setIsOpenChangeUserDialog,
    setIsOpenChangeDescriptionDialog,
    setIsOpenChangeFeaturesDialog,
    setFavoriteId,
  } = useActions()

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [openUsersMenu, setOpenUsersMenu] = useState(false)

  const { data: currentUserData } = useGetUserProfileQuery('0')
  const currentUserType = currentUserData?.data.typeId

  const handleClick = () => {
    setOpenUsersMenu(!openUsersMenu)
  }

  const navigateNew = () => {
    if (isChangeModules) {
      setIsOpenChangeModulesDialog(true)
      return
    }
    if (isChangeDescription) {
      setIsOpenChangeDescriptionDialog(true)
      return
    }
    if (isChangeFeatures) {
      setIsOpenChangeFeaturesDialog(true)
      return
    }
    if (isChangeUser) {
      setIsOpenChangeUserDialog(true)
      return
    }
    if (isChangeOrganization) {
      setIsOpenChangeOrganizationDialog(true)
      return
    }
    navigate('/new-units')
    setIsShowSidebar(false)
    setFavoriteId(0)
  }

  const navigateSystems = () => {
    if (isChangeModules) {
      setIsOpenChangeModulesDialog(true)
      return
    }
    if (isChangeDescription) {
      setIsOpenChangeDescriptionDialog(true)
      return
    }
    if (isChangeFeatures) {
      setIsOpenChangeFeaturesDialog(true)
      return
    }
    if (isChangeUser) {
      setIsOpenChangeUserDialog(true)
      return
    }
    if (isChangeOrganization) {
      setIsOpenChangeOrganizationDialog(true)
      return
    }
    navigate('/systems-management')
    setIsShowSidebar(false)
    setFavoriteId(0)
  }

  const navigateUsers = () => {
    if (isChangeModules) {
      setIsOpenChangeModulesDialog(true)
      return
    }
    if (isChangeDescription) {
      setIsOpenChangeDescriptionDialog(true)
      return
    }
    if (isChangeFeatures) {
      setIsOpenChangeFeaturesDialog(true)
      return
    }
    if (isChangeUser) {
      setIsOpenChangeUserDialog(true)
      return
    }
    if (isChangeOrganization) {
      setIsOpenChangeOrganizationDialog(true)
      return
    }
    navigate('/users')
    setIsShowSidebar(false)
    setFavoriteId(0)
  }

  const navigateOrganizations = () => {
    if (isChangeModules) {
      setIsOpenChangeModulesDialog(true)
      return
    }
    if (isChangeDescription) {
      setIsOpenChangeDescriptionDialog(true)
      return
    }
    if (isChangeFeatures) {
      setIsOpenChangeFeaturesDialog(true)
      return
    }
    if (isChangeUser) {
      setIsOpenChangeUserDialog(true)
      return
    }
    if (isChangeOrganization) {
      setIsOpenChangeOrganizationDialog(true)
      return
    }
    navigate(
      currentUserType === UserTypes.admin
        ? `/organizations/${currentUserData?.data?.organizationId}`
        : '/organizations'
    )
    setIsShowSidebar(false)
    setFavoriteId(0)
  }

  const navigateEvents = () => {
    if (isChangeModules) {
      setIsOpenChangeModulesDialog(true)
      return
    }
    if (isChangeDescription) {
      setIsOpenChangeDescriptionDialog(true)
      return
    }
    if (isChangeFeatures) {
      setIsOpenChangeFeaturesDialog(true)
      return
    }
    if (isChangeUser) {
      setIsOpenChangeUserDialog(true)
      return
    }
    if (isChangeOrganization) {
      setIsOpenChangeOrganizationDialog(true)
      return
    }
    navigate('/events')
    setIsShowSidebar(false)
    setFavoriteId(0)
  }

  return (
    <List
      sx={{
        width: '100%',
        marginTop: 1,
      }}
      className={s()}
    >
      {currentUserType === UserTypes.user ||
      currentUserType === UserTypes.admin ? null : (
        <ListItemButton
          className={s({ active: pathname === '/new-units' })}
          onClick={navigateNew}
        >
          <ListItemIcon>
            <AddCircleOutlineOutlinedIcon htmlColor='white' />
          </ListItemIcon>
          <ListItemText
            style={{ color: 'white' }}
            primary='Новые устройства'
          />
        </ListItemButton>
      )}
      {import.meta.env.VITE_APP_NAME === 'RobertAqua' ? null : (
        <ListItemButton
          className={s({ active: pathname === '/systems-management' })}
          onClick={navigateSystems}
        >
          <ListItemIcon>
            <SettingsSuggestOutlinedIcon htmlColor='white' />
          </ListItemIcon>
          <ListItemText
            style={{ color: 'white' }}
            primary='Управление системами'
          />
        </ListItemButton>
      )}
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <GroupIcon htmlColor='white' />
        </ListItemIcon>
        <ListItemText
          style={{ color: 'white' }}
          primary='Пользователи и доступ'
        />
        {openUsersMenu ? (
          <ExpandLess htmlColor='white' />
        ) : (
          <ExpandMore htmlColor='white' />
        )}
      </ListItemButton>
      <Collapse
        in={openUsersMenu}
        timeout='auto'
        unmountOnExit
      >
        <List disablePadding>
          {currentUserType === UserTypes.user ? null : (
            <ListItemButton
              className={s({ active: pathname.includes('users') })}
              onClick={navigateUsers}
              selected={pathname === '/users'}
              sx={{ pl: 6 }}
            >
              <ListItemText
                style={{ color: 'white' }}
                className={s('sub-items')}
                primary='Пользователи'
              />
            </ListItemButton>
          )}
          {currentUserType === UserTypes.user ? null : (
            <ListItemButton
              className={s({ active: pathname.includes('/organizations') })}
              onClick={navigateOrganizations}
              selected={pathname === '/organizations'}
              sx={{ pl: 6 }}
            >
              <ListItemText
                style={{ color: 'white' }}
                className={s('sub-items')}
                primary={
                  currentUserType === UserTypes.admin
                    ? 'Организация'
                    : 'Организации'
                }
              />
            </ListItemButton>
          )}
          <ListItemButton
            className={s({ active: pathname === '/events' })}
            onClick={navigateEvents}
            selected={pathname === '/events'}
            sx={{ pl: 6 }}
          >
            <ListItemText
              style={{ color: 'white' }}
              className={s('sub-items')}
              primary='События'
            />
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  )
}
