import photo from '@/assets/img/icons8-camera-80 (1).png'
import video from '@/assets/img/icons8-camera-80.png'
import proc from '@/assets/img/processor.png'
import tsoodd from '@/assets/img/rhombus.png'
import { Box } from '@mui/material'
import * as React from 'react'
import { FC } from 'react'

interface Props {
  totalVideo?: number
  totalPhoto?: number
  totalNotSmart?: number
  totalModulesCount?: number
}

const ContentPopover: FC<Props> = ({
  totalPhoto,
  totalVideo,
  totalNotSmart,
  totalModulesCount,
}) => {
  return (
    <Box
      width={230}
      pt={2}
      display='flex'
      justifyContent='center'
      pb='12px'
    >
      <Box
        display='flex'
        gap={2}
        flexWrap='wrap'
        justifyContent='flex-start'
        pl={4}
      >
        {totalNotSmart ? (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            gap={1}
          >
            <img
              src={tsoodd}
              width={30}
              alt='proc'
            />
            <span style={{ textAlign: 'center' }}>{totalNotSmart} шт.</span>
          </Box>
        ) : null}
        {totalModulesCount ? (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            gap={1}
          >
            <img
              src={proc}
              width={30}
              alt='proc'
            />
            <span style={{ textAlign: 'center' }}>{totalModulesCount} шт.</span>
          </Box>
        ) : null}
        {totalVideo ? (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            gap={1}
          >
            <img
              src={video}
              width={30}
              alt='proc'
            />
            <span style={{ textAlign: 'center' }}>{totalVideo} шт.</span>
          </Box>
        ) : null}
        {totalPhoto ? (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            gap={1}
          >
            <img
              src={photo}
              width={30}
              alt='proc'
            />
            <span style={{ textAlign: 'center' }}>{totalPhoto} шт.</span>
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}

export default ContentPopover
