import * as Yup from 'yup'
import { AnyObject, Maybe } from 'yup/es/types'

export enum UserTypes {
  user = 3,
  admin = 2,
  superAdmin = 1,
}

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends Yup.BaseSchema<TType, TContext, TOut> {
    ipv4(): StringSchema<TType, TContext>
  }
}
