import { RootState } from '@/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface Systems {
  id: number
  name: string
  address: string
}

export interface Favorites {
  id: number | null
  name: string
  displaySequence: number
  devices: Systems[]
}

export interface FavoritesState {
  favorites: Favorites[]
}

const initialState: FavoritesState = {
  favorites: [],
}

export const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    setFavorites: (state, action: PayloadAction<Favorites[]>) => {
      state.favorites = action.payload
    },
    addFavorite: (state, action: PayloadAction<string>) => {
      state.favorites.push({
        id: 0,
        name: action.payload,
        devices: [],
        displaySequence: state.favorites.length + 1,
      })
    },
    removeFavorite: (state, action: PayloadAction<number>) => {
      state.favorites.splice(action.payload, 1)
      state.favorites.forEach((item, index) => {
        item.displaySequence = index + 1
      })
    },
    removeSystems: (
      state,
      action: PayloadAction<{ favoritesId: number; systemId: number }>
    ) => {
      const { favoritesId, systemId } = action.payload
      state.favorites[favoritesId].devices.splice(systemId, 1)
    },
    editNameFavorite: (
      state,
      action: PayloadAction<{ index: number; name: string }>
    ) => {
      const { index, name } = action.payload
      state.favorites[index].name = name
    },
  },
})

export const {
  addFavorite,
  removeFavorite,
  removeSystems,
  setFavorites,
  editNameFavorite,
} = favoritesSlice.actions

export const favoritesSelector = (state: RootState) => state.favorites

export default favoritesSlice.reducer
