export const getRandomColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

export const COLORS = [
  '#FF0000',
  '#056e0e',
  '#FF7F00',
  '#000000',
  '#800000',
  '#00ff00',
  '#000080',
  '#808000',
  '#008080',
  '#ff8000',
  '#00ff80',
  '#8000ff',
  '#ff0080',
  '#804000',
  '#008040',
  '#400080',
  '#804080',
  '#400000',
  '#004000',
  '#000040',
  '#404000',
  '#004040',
  '#400040',
  '#c00000',
  '#00c000',
  '#c0c000',
  '#00c0c0',
  '#ff4000',
  '#00ff40',
  '#ff0040',
  '#408000',
  '#800040',
  '#bf8000',
  '#00bf00',
  '#00bfbf',
  '#bfbf00',
  '#bf4000',
  '#00bf40',
  '#bf0040',
  '#404080',
  '#804040',
  '#408040',
]

export function generateColor(number: number) {
  // Проверка на допустимый диапазон числа
  if (number < 1 || number > 10000) {
    throw new Error('Number should be between 1 and 10000')
  }

  // Проверка на цвета, которые нужно избежать
  const avoidColors = ['#0f8fff', '#e450ed']

  let color
  do {
    // Генерация случайного цвета
    color = '#' + Math.floor(Math.random() * 16777215).toString(16)
  } while (
    avoidColors.includes(color) ||
    isColorSimilarToAvoidColors(color, avoidColors)
  )

  return color
}

function isColorSimilarToAvoidColors(color: string, avoidColors: string[]) {
  // Проверка на схожесть цвета с цветами, которые нужно избежать
  const threshold = 100 // Пороговое значение для различия цветов

  for (const avoidColor of avoidColors) {
    const color1 = hexToRgb(color)
    const color2 = hexToRgb(avoidColor)

    // Различие между компонентами RGB
    const diffR = Math.abs(color1.r - color2.r)
    const diffG = Math.abs(color1.g - color2.g)
    const diffB = Math.abs(color1.b - color2.b)

    // Если различие между компонентами RGB превышает пороговое значение,
    // считаем, что цвета слишком похожи
    if (diffR < threshold && diffG < threshold && diffB < threshold) {
      return true
    }
  }

  return false
}

function hexToRgb(hex: string) {
  // Преобразование цвета из HEX в RGB
  const bigint = parseInt(hex.slice(1), 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255
  return { r, g, b }
}

export function transformData(data: any) {
  const transformedData = []

  for (let i = 0; i < data.length; i++) {
    const currentData = data[i]

    if (currentData.y === 1) {
      const nextDataIndex = findNextDataIndex(data, i, 0)
      if (nextDataIndex !== -1) {
        const nextData = data[nextDataIndex]
        transformedData.push(
          { x: currentData.x, y: 1 },
          { x: nextData.x, y: 1 },
          { x: nextData.x, y: 0 }
        )
      }
    } else if (currentData.y === 0) {
      const nextDataIndex = findNextDataIndex(data, i, 1)
      if (nextDataIndex !== -1) {
        const nextData = data[nextDataIndex]
        transformedData.push(
          { x: currentData.x, y: 0 },
          { x: nextData.x, y: 0 },
          { x: nextData.x, y: 1 }
        )
      }
    }
  }

  return transformedData
}

function findNextDataIndex(data: any, startIndex: any, targetY: any) {
  for (let i = startIndex + 1; i < data.length; i++) {
    if (data[i].y === targetY) {
      return i
    }
  }
  return -1
}
