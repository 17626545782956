import bg from '@/assets/img/bg1.jpg'
import cloudBlue from '@/assets/img/cloud-blue.png'
import cloudGray from '@/assets/img/cloud-gray.png'
import cloudRed from '@/assets/img/cloud-red.png'
import photo from '@/assets/img/icons8-camera-80 (1).png'
import { useActions } from '@/hooks'
import { Unit } from '@/modules/devices-list/types/newUnitsType'
import cn from '@/utils/cn'
import { copyImageToClipboardWithFetch } from '@/utils/screenShot'
import { FullScreen } from '@chiragrupani/fullscreen-react'
import CropFreeIcon from '@mui/icons-material/CropFree'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor'
import { Box, CircularProgress, IconButton, useMediaQuery } from '@mui/material'
import 'clipboard-polyfill'
import dayjs from 'dayjs'
import * as React from 'react'
import { FC, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const s = cn('devices-list')

interface Props {
  img?: string
  isHovered: boolean
  deviseId?: number
  isSlider?: boolean
  unit?: Unit
}

const PhotoItem: FC<Props> = ({ img, unit, isHovered, isSlider, deviseId }) => {
  const navigate = useNavigate()

  const [isHover, setIsHover] = useState(isHovered)
  const [loader, setLoader] = useState(false)
  const [loaderImg, setLoaderImg] = useState(true)
  const [isFullScreen, setIsFullScreen] = useState(false)

  const { toggleFullScreen } = useActions()

  const elementRef = useRef(null)

  const isMonitor2 = useMediaQuery('(min-width:1366px)')
  const isMonitor3 = useMediaQuery('(min-width:1536px)')
  const is1024 = useMediaQuery('(min-width:1024px)')
  const is768 = useMediaQuery('(min-width:768px)')
  const isMobile = useMediaQuery('(max-width:459px)')

  useEffect(() => {
    if (isFullScreen || isHovered) {
      setIsHover(true)
    } else {
      setIsHover(false)
    }
  }, [isFullScreen])

  return (
    <FullScreen
      isFullScreen={isFullScreen}
      onChange={(isFull) => setIsFullScreen(isFull)}
    >
      <Box
        sx={{
          aspectRatio: '16/9',
          height: { xss: '96%', xl3: '100%', xll: '100%' },
          width: '100%',
          backgroundColor: isFullScreen ? '#000000' : 'unset',
          backgroundImage: isFullScreen ? 'unset' : `url(${bg})`,
        }}
        className={s(isHovered ? '' : 'unit', {
          full_screen: isSlider ? false : isFullScreen,
        })}
        display='flex'
        alignItems='center'
        justifyContent='center'
        position='relative'
        overflow='hidden'
        onMouseOver={() => {
          if (!isHovered) {
            setIsHover(true)
          }
          if (isFullScreen || isHovered) {
            setIsHover(true)
          }
        }}
        onMouseLeave={() => {
          if (!isHovered) {
            setIsHover(false)
          }
          if (isFullScreen || isHovered) {
            setIsHover(true)
          }
        }}
      >
        <CircularProgress
          sx={{ zIndex: 100, color: '#fff' }}
          className={loader || loaderImg ? s('show-loader') : s('hide-loader')}
        />
        {!isHovered ? (
          <Box
            display='flex'
            alignItems='center'
            gap={1}
            position='absolute'
            top={0}
            right={5}
          >
            <img
              src={
                unit?.connectionStatus === 1
                  ? cloudBlue
                  : unit?.connectionStatus === 2
                    ? cloudGray
                    : unit?.connectionStatus === 3
                      ? cloudRed
                      : cloudRed
              }
              height={25}
              width={25}
              alt='Связь'
            />
            <span style={{ color: '#fff' }}>
              {unit?.connectionStatus === 1
                ? 'В сети'
                : unit?.connectionStatus === 2
                  ? 'Не в сети'
                  : unit?.connectionStatus === 3
                    ? 'Нет соединения'
                    : '-'}
            </span>
          </Box>
        ) : null}
        {!isHovered ? (
          <Box
            display='flex'
            alignItems='center'
            gap={1}
            position='absolute'
            top={0}
            left={5}
          >
            <img
              src={photo}
              height={25}
              width={25}
              alt='Связь'
            />
            <span style={{ color: '#fff' }}>{unit?.name}</span>
          </Box>
        ) : null}
        {!isHovered ? (
          <Box
            position='absolute'
            bottom={0}
            left={5}
          >
            <span style={{ color: '#fff' }}>
              {unit?.lastPhotoDate
                ? dayjs(unit?.lastPhotoDate).format('DD.MM.YYYY HH:mm:ss')
                : '-'}
            </span>
          </Box>
        ) : null}
        <Box
          display='flex'
          position='absolute'
          bottom={isHover ? 0 : -50}
          right={0}
          sx={{ transition: 'all 200ms linear' }}
        >
          {!isHovered ? (
            <IconButton
              onClick={() => {
                toggleFullScreen(false)
                navigate(`/photos/${unit?.id}`)
              }}
            >
              <OpenInNewIcon htmlColor='#fff' />
            </IconButton>
          ) : null}
          <IconButton
            onClick={() =>
              copyImageToClipboardWithFetch(
                img ?? unit?.lastPhoto ?? '',
                setLoader
              )
            }
          >
            <ScreenshotMonitorIcon htmlColor='#fff' />
          </IconButton>
          <IconButton
            onClick={() => {
              setIsFullScreen((prev) => !prev)
              if (!isFullScreen) {
                toggleFullScreen(true)
              } else {
                toggleFullScreen(false)
              }
            }}
          >
            {isFullScreen ? (
              <FullscreenExitIcon htmlColor='#fff' />
            ) : (
              <CropFreeIcon htmlColor='#fff' />
            )}
          </IconButton>
        </Box>
        {img || unit?.lastPhoto ? (
          <img
            className={loader ? s('loading') : ''}
            ref={elementRef}
            src={img ? img : unit?.lastPhoto}
            alt='img'
            loading='lazy'
            style={{
              width: loaderImg
                ? isMonitor2
                  ? 519
                  : isMonitor3
                    ? 595
                    : is1024
                      ? 372
                      : is768
                        ? 359
                        : isMobile
                          ? 194
                          : 825
                : '100%',
              height: loaderImg
                ? isMonitor2
                  ? 292
                  : isMonitor3
                    ? 335
                    : is1024
                      ? 209
                      : is768
                        ? 201
                        : isMobile
                          ? 109
                          : 464
                : '100%',
              objectFit: 'contain',
            }}
            onLoad={() => {
              setLoaderImg(false)
            }}
          />
        ) : null}
      </Box>
    </FullScreen>
  )
}

export default PhotoItem
