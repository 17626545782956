import bg from '@/assets/img/bg1.jpg'
import cloudBlue from '@/assets/img/cloud-blue.png'
import cloudGray from '@/assets/img/cloud-gray.png'
import cloudRed from '@/assets/img/cloud-red.png'
import videoIcon from '@/assets/img/icons8-camera-80.png'
import { notifySuccess } from '@/components'
import { useActions } from '@/hooks'
import { Unit } from '@/modules/devices-list/types/newUnitsType'
import cn from '@/utils/cn'
import { dataURLtoBlob } from '@/utils/dataURIToBlob'
import { FullScreen } from '@chiragrupani/fullscreen-react'
import CropFreeIcon from '@mui/icons-material/CropFree'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor'
import { Box, CircularProgress, IconButton } from '@mui/material'
import 'clipboard-polyfill'
import html2canvas from 'html2canvas'
import * as React from 'react'
import { FC, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { useNavigate } from 'react-router-dom'

const s = cn('devices-list')

interface Props {
  video: string
  isHovered: boolean
  deviseId?: number
  isSlider?: boolean
  unit?: Unit
}

const VideoItem: FC<Props> = ({
  video,
  unit,
  isHovered,
  isSlider,
  deviseId,
}) => {
  const navigate = useNavigate()

  const [isHover, setIsHover] = useState(isHovered)
  const [loader, setLoader] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)

  const { toggleFullScreen } = useActions()

  const elementRef = useRef(null)

  const handleScreenshot = () => {
    if (elementRef.current) {
      setLoader(true)
      html2canvas(elementRef.current).then((canvas) => {
        const screenshot = canvas.toDataURL()
        const blob = dataURLtoBlob(screenshot)
        const clipboardItem = new ClipboardItem({ 'image/png': blob })
        navigator.clipboard
          .write([clipboardItem])
          .then(() => {
            notifySuccess('Снимок скопирован в буфер обмена')
            setLoader(false)
          })
          .catch((error) => {
            console.error('Ошибка копирования в буфер обмена:', error)
            setLoader(false)
          })
      })
    }
  }

  useEffect(() => {
    if (isFullScreen || isHovered) {
      setIsHover(true)
    } else {
      setIsHover(false)
    }
  }, [isFullScreen])

  return (
    <FullScreen
      isFullScreen={isFullScreen}
      onChange={(isFull) => setIsFullScreen(isFull)}
    >
      <Box
        sx={{
          aspectRatio: '16/9',
          height: { xss: '96%', xl3: '100%', xll: '100%' },
          width: '100%',
          filter: '',
          backgroundColor: isFullScreen ? '#000000' : 'unset',
          backgroundImage: isFullScreen ? 'unset' : `url(${bg})`,
        }}
        className={s(isHovered ? '' : 'unit', {
          full_screen: isSlider ? false : isFullScreen,
        })}
        display='flex'
        alignItems='center'
        justifyContent='center'
        position='relative'
        overflow='hidden'
        ref={elementRef}
        onMouseOver={() => {
          if (!isHovered) {
            setIsHover(true)
          }
          if (isFullScreen || isHovered) {
            setIsHover(true)
          }
        }}
        onMouseLeave={() => {
          if (!isHovered) {
            setIsHover(false)
          }
          if (isFullScreen || isHovered) {
            setIsHover(true)
          }
        }}
      >
        <CircularProgress
          sx={{ zIndex: 100, color: '#fff' }}
          className={loader ? s('show-loader') : s('hide-loader')}
        />
        {!isHovered ? (
          <Box
            display='flex'
            alignItems='center'
            gap={1}
            position='absolute'
            zIndex={2}
            top={0}
            right={5}
          >
            <img
              src={
                unit?.connectionStatus === 1
                  ? cloudBlue
                  : unit?.connectionStatus === 2
                    ? cloudGray
                    : unit?.connectionStatus === 3
                      ? cloudRed
                      : cloudRed
              }
              height={25}
              width={25}
              alt='Связь'
            />
            <span style={{ color: '#fff' }}>
              {unit?.connectionStatus === 1
                ? 'В сети'
                : unit?.connectionStatus === 2
                  ? 'Не в сети'
                  : unit?.connectionStatus === 3
                    ? 'Нет соединения'
                    : '-'}
            </span>
          </Box>
        ) : null}
        {!isHovered ? (
          <Box
            display='flex'
            alignItems='center'
            gap={1}
            position='absolute'
            zIndex={2}
            top={0}
            left={5}
          >
            <img
              src={videoIcon}
              height={25}
              width={25}
              alt='Связь'
            />
            <span style={{ color: '#fff' }}>{unit?.name}</span>
          </Box>
        ) : null}
        {/*{*/}
        {/*  !isHovered ?*/}
        {/*    <Box*/}
        {/*      position='absolute'*/}
        {/*      zIndex={2}*/}
        {/*      bottom={0}*/}
        {/*      left={5}*/}
        {/*    >*/}
        {/*    <span style={{ color: '#fff' }}>*/}
        {/*    {dayjs(unit?.lastPhotoDate).format('DD.MM.YYYY HH:mm:ss')}*/}
        {/*  </span>*/}
        {/*    </Box> : null*/}
        {/*}*/}
        <Box
          display='flex'
          position='absolute'
          zIndex={2}
          bottom={isHover ? 0 : -50}
          right={0}
          sx={{ transition: 'all 200ms linear' }}
        >
          {!isHovered ? (
            <IconButton
              onClick={() => {
                navigate(`/devices/${unit?.id}`)
              }}
            >
              <OpenInNewIcon htmlColor='#fff' />
            </IconButton>
          ) : null}
          <IconButton onClick={handleScreenshot}>
            <ScreenshotMonitorIcon htmlColor='#fff' />
          </IconButton>
          <IconButton
            onClick={() => {
              setIsFullScreen((prev) => !prev)
              if (!isFullScreen) {
                toggleFullScreen(true)
              } else {
                toggleFullScreen(false)
              }
            }}
          >
            {isFullScreen ? (
              <FullscreenExitIcon htmlColor='#fff' />
            ) : (
              <CropFreeIcon htmlColor='#fff' />
            )}
          </IconButton>
        </Box>
        <ReactPlayer
          url={video}
          controls={false}
          playing
          muted
          loop
          width='100%'
          height='100%'
          style={{ position: 'relative', zIndex: 1 }}
          className={loader ? s('loading') : ''}
        />
      </Box>
    </FullScreen>
  )
}

export default VideoItem
