import { TextField, TextFieldProps, useMediaQuery } from '@mui/material'
import React, { FC } from 'react'
import { useController, UseControllerProps } from 'react-hook-form'
import cn from '@/utils/cn'
import InputMask from 'react-input-mask'

const s = cn('input-user')

type IInput = {}

type InputProps = TextFieldProps & IInput & UseControllerProps<any>

const PhoneInput: FC<InputProps> = ({ name, control, disabled, ...props }) => {
  const is4K = useMediaQuery('(min-width:2560px)')

  const {
    field,
    formState: { errors },
  } = useController({
    name,
    control,
  })

  return (
    <InputMask
      mask='+9(999)999-99-99'
      {...field}
      disabled={disabled}
    >
      {/* @ts-ignore */}
      {(inputProps) => (
        <TextField
          {...inputProps}
          {...props}
          color='primary'
          className={s()}
          autoComplete='off'
          variant='filled'
          size={is4K ? undefined : 'small'}
          margin='none'
          fullWidth
          error={!!errors[name]}
          helperText={errors[name]?.message as string}
          InputProps={{
            disableUnderline: true,
          }}
        />
      )}
    </InputMask>
  )
}

export default PhoneInput
