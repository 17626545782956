import cil from '@/assets/img/icons8-база-данных-40.png'
import cub from '@/assets/img/icons8-куб-40.png'
import Tooltip from '@mui/material/Tooltip'
import * as React from 'react'

const TankIcon = ({
  type,
  size = 25,
}: {
  type: 'rect' | 'cyl'
  size?: number
}) => {
  return (
    <Tooltip
      title={
        type === 'rect' ? 'Прямоугольный резервуар' : 'Цилиндрический резервуар'
      }
      placement='top'
    >
      <img
        src={type === 'rect' ? cub : cil}
        alt='Ошибка'
        width={size}
        height={size}
      />
    </Tooltip>
  )
}

export default TankIcon
