import { Navigate, Outlet, useLocation } from 'react-router-dom'
import React from 'react'
import { useAppSelector } from '@/hooks'
import { userLoginSelector } from '@/modules/login/store/userLoginStore'

const PrivateRoute = () => {
  const location = useLocation()

  const isLogin = useAppSelector(userLoginSelector)
  const isLoginStorage = JSON.parse(localStorage.getItem('isLogin') ?? 'false')

  return isLoginStorage ? (
    <Outlet />
  ) : (
    <Navigate
      to='/login'
      state={{ from: location }}
      replace
    />
  )
}

export default PrivateRoute
