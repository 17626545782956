import { Box, Paper, Typography, useTheme } from '@mui/material'
import { FC, ReactNode } from 'react'
import { LoaderFullScreen } from '@/ui'

interface IShell {
  children: ReactNode
  isLoading?: boolean
}

const Shell: FC<IShell> = ({ children, isLoading }) => {
  const theme = useTheme()

  return (
    <Paper
      elevation={16}
      sx={{
        background: 'rgba(73, 88, 103, .7)',
      }}
    >
      <LoaderFullScreen
        open={isLoading ?? false}
        color='secondary'
      />
      <Box
        p={{ xss: 1, xs: 1, sm: 3, md: 4, xl: 5, xxl: 6 }}
        display='flex'
        flexDirection='column'
        alignItems='center'
        width={{ xss: 320, xs: 375, sm: 420, md: 450, xxl: 650 }}
      >
        <Typography
          variant='h4'
          color={theme.palette.white.main}
          //fontSize={31}
          fontWeight='bold'
        >
          {import.meta.env.VITE_APP_NAME}
        </Typography>
        <Typography
          color={theme.palette.white.main}
          fontSize={12}
          textAlign='center'
        >
          Сервис мониторинга цифровых технических средств
        </Typography>
        {children}
      </Box>
    </Paper>
  )
}

export default Shell
