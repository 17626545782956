import Shell from '@/modules/login/layout/Shell'
import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import cn from '@/utils/cn'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import Input from '@/modules/login/components/Input/Input'
import Button from '@/modules/login/components/Button/Button'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { IRecPassField, IRecPassRequest } from '@/modules/login/types'
import {
  useGetCaptchaQuery,
  useRecoveryPasswordMutation,
} from '@/modules/login/services/login'

const s = cn('loginModule')

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email не может быть пустым')
    .email('Некорректный E-mail')
    .min(2, 'Длина Email должна быть менее 2-ч символов')
    .max(50, 'Длина Email должна быть не более 50-ти символов'),
  code: Yup.string().required('Поле обязательно'),
})

const RecPassModule = () => {
  const navigate = useNavigate()
  const theme = useTheme()

  const defaultValues: IRecPassField = {
    email: '',
    code: '',
  }

  const { handleSubmit, control, reset, resetField } = useForm<IRecPassField>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const {
    data: captchaData,
    isLoading: captchaLoading,
    isError: captchaIsError,
    refetch: refetchCaptcha,
  } = useGetCaptchaQuery('', {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  })

  const [onRecoveryPassword, { isLoading }] = useRecoveryPasswordMutation()
  const onSubmit = async (values: IRecPassField) => {
    try {
      const data: IRecPassRequest = {
        ...values,
        captchaId: captchaData ? captchaData.captchaId : '',
      }
      const res = await onRecoveryPassword(data).unwrap()
      if (res.success) {
        navigate('/confirm-email-instruction')
      } else if (!res.success && res.messages) {
        //res.messages.forEach(item => notifyError(item))
        refetchCaptcha()
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    reset(defaultValues)
  }, [])

  useEffect(() => {
    resetField('code')
  }, [captchaData])

  return (
    <Shell isLoading={isLoading}>
      <form
        className={s('form')}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          display='flex'
          flexDirection='column'
          width='100%'
          mt={{ xss: 1, xs: 2, md: 4 }}
        >
          <Typography
            color={theme.palette.white.main}
            fontSize={20}
            mb={1}
          >
            Восстановление пароля
          </Typography>
          <Input
            control={control}
            name='email'
            type='email'
            label='Введите почту*'
            //disabled={isLoading}
          />
          <Typography
            color={theme.palette.white.main}
            fontSize={14}
            mt='24px'
          >
            Введите электронный адрес почты указанный при регистрации.
          </Typography>
          <Box mt={3} />
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            flexDirection={{ xss: 'column-reverse', sm: 'row' }}
          >
            <Input
              control={control}
              name='code'
              label='Введите символы'
              //disabled={isLoading}
            />
            <Box
              mr={2}
              mt={{ xss: 2, sm: 0 }}
            />
            <Box
              width='100%'
              height={{ xss: 52, xxl: 60 }}
              sx={{ background: 'white' }}
              borderRadius={1}
              border='1px solid'
              borderColor='rgba(0, 114, 188, .7)'
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              {captchaLoading ? (
                <CircularProgress size={20} />
              ) : captchaIsError ? (
                <Typography
                  variant='body2'
                  color='#d32f2f'
                >
                  Ошибка загрузки
                </Typography>
              ) : captchaData ? (
                <img
                  alt='captcha'
                  src={`data:image/png;base64, ${captchaData.captchaImage}`}
                />
              ) : null}
            </Box>
          </Box>
          <Box
            display='flex'
            justifyContent='space-between'
            mt={3}
            alignItems='center'
          >
            <Button
              onClick={() => navigate('/login')}
              label='Отмена'
            />
            <Button
              type='submit'
              label='Отправить'
              variant='outlined'
              //loading={isLoading}
            />
          </Box>
        </Box>
      </form>
    </Shell>
  )
}

export default withGoogleReCaptcha(RecPassModule)
