import Shell from '@/modules/login/layout/Shell'
import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import cn from '@/utils/cn'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import Input from '@/modules/login/components/Input/Input'
import Button from '@/modules/login/components/Button/Button'
import {
  useGetCaptchaQuery,
  useRegisterMutation,
} from '@/modules/login/services/login'
import { useActions } from '@/hooks'
import { notifyError } from '@/components'
import { IRegisterFields, IRegisterRequest } from '@/modules/login/types'
import { useChangeSet } from '@/utils/useChangeSet'

const s = cn('loginModule')

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email не может быть пустым')
    .email('Некорректный E-mail')
    .min(2, 'Длина Email должна быть менее 2-ч символов')
    .max(50, 'Длина Email должна быть не более 50-ти символов'),
  password: Yup.string()
    .required('Пароль не может быть пустым')
    .min(6, 'Введите пароль от 6-ти до 20-ти символов')
    .max(20, 'Введите пароль от 6-ти до 20-ти символов'),
  //.matches(/^(?=.*[a-zA-Z])(?!.*\W)(?!.* ).{6,20}$/, 'Некорректный пароль'),
  confirmPassword: Yup.string()
    .required('Пароль не может быть пустым')
    .oneOf(
      [Yup.ref('password'), null],
      'Пароли не совпадают. Повторите попытку'
    ),
  firstName: Yup.string().max(30, 'Не более 30 символов'),
  surname: Yup.string().max(30, 'Не более 30 символов'),
  organization: Yup.string().max(30, 'Не более 30 символов'),
  code: Yup.string().required('Поле обязательно'),
})

const RegisterModule = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const { setUserEmail } = useActions()
  const [registerService, { isLoading }] = useRegisterMutation()
  const {
    data: captchaData,
    isLoading: captchaLoading,
    isError: captchaIsError,
    refetch: refetchCaptcha,
  } = useGetCaptchaQuery('', {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  })

  const defaultValues: IRegisterFields = {
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    surname: '',
    organization: '',
    code: '',
  }

  const { handleSubmit, control, reset, resetField, trigger, watch } =
    useForm<IRegisterFields>({
      defaultValues,
      resolver: yupResolver(validationSchema),
      reValidateMode: 'onChange',
    })
  const onSubmit = async (values: IRegisterFields) => {
    try {
      const data: IRegisterRequest = {
        ...values,
        captchaId: captchaData ? captchaData.captchaId : '',
      }
      const res = await registerService(data).unwrap()
      if (res.success) {
        setUserEmail(values.email)
        navigate('/confirm-email')
      } else if (!res.success && res.messages) {
        //res.messages.forEach(item => notifyError(item))
        refetchCaptcha()
      }
    } catch (e) {
      notifyError('Что-то пошло не так. Попробуйте еще раз позже.')
      console.log(e)
    }
  }

  useChangeSet(() => {
    if (watch('confirmPassword').length) {
      trigger('confirmPassword')
    }
  }, [watch('password')])

  useEffect(() => {
    reset(defaultValues)
  }, [])

  useEffect(() => {
    resetField('code')
  }, [captchaData])

  return (
    <Shell isLoading={isLoading}>
      <form
        className={s('form')}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          display='flex'
          flexDirection='column'
          width='100%'
          mt={{ xss: 2, xs: 3, md: 4 }}
        >
          <Typography
            color={theme.palette.white.main}
            fontSize={20}
            mb={{ xxs: 1, xxl: 2 }}
          >
            Регистрация
          </Typography>
          <Box mt={{ xss: 2, xxl: 4 }} />
          <Input
            control={control}
            name='email'
            type='email'
            label='Введите почту*'
            //disabled={isLoading}
          />
          <Box mt={{ xss: 2, xxl: 4 }} />
          <Input
            control={control}
            name='password'
            type='password'
            label='Введите пароль*'
            //disabled={isLoading}
          />
          <Box mt={{ xss: 2, xxl: 4 }} />
          <Input
            control={control}
            name='confirmPassword'
            type='password'
            label='Повторите пароль*'
            //disabled={isLoading}
          />
          <Box mt={{ xss: 2, xxl: 4 }} />
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            flexDirection={{ xss: 'column', sm: 'row' }}
          >
            <Input
              control={control}
              name='firstName'
              label='Имя'
              //disabled={isLoading}
            />
            <Box
              mr={2}
              mt={{ xss: 2, sm: 0 }}
            />
            <Input
              control={control}
              name='surname'
              label='Фамилия'
              //disabled={isLoading}
            />
          </Box>
          <Box mt={{ xss: 2, xxl: 4 }} />
          <Input
            control={control}
            name='organization'
            label='Организация'
            //disabled={isLoading}
          />
          <Box mt={{ xss: 2, xxl: 4 }} />
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            flexDirection={{ xss: 'column-reverse', sm: 'row' }}
          >
            <Input
              control={control}
              name='code'
              label='Введите символы*'
              //disabled={isLoading}
            />
            <Box
              mr={2}
              mt={{ xss: 2, sm: 0 }}
            />
            <Box
              width='100%'
              height={{ xss: 52, xxl: 60 }}
              sx={{ background: 'white' }}
              borderRadius={1}
              border='1px solid'
              borderColor='rgba(0, 114, 188, .7)'
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              {captchaLoading ? (
                <CircularProgress size={20} />
              ) : captchaIsError ? (
                <Typography
                  variant='body2'
                  color='#d32f2f'
                >
                  Ошибка загрузки
                </Typography>
              ) : captchaData ? (
                <img
                  alt='captcha'
                  src={`data:image/png;base64, ${captchaData.captchaImage}`}
                />
              ) : null}
            </Box>
          </Box>
          <Box
            display='flex'
            justifyContent='space-between'
            mt={3}
            alignItems='center'
          >
            <Button
              onClick={() => navigate('/login')}
              label='Отмена'
              //disabled={isLoading}
            />
            <Button
              type='submit'
              variant='outlined'
              label='Готово'
              //loading={isLoading}
            />
          </Box>
        </Box>
      </form>
    </Shell>
  )
}

export default RegisterModule
